import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import BaleMap from 'mui-mobile-components/storages/balestorage/balestorage/BaleMap';
import TextAreaControl from 'components/forms/TextAreaControl';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import TextBoxControl from 'components/forms/TextBoxControl';
import * as baleActions from 'actions/Bale';
import _ from 'lodash';
import TrashcanIcon from '@tine/lib-frontend-components/elements/icons/trashcan-icon/index';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import ConfirmDialogBox from 'components/ConfirmDialogBox';

class BaleStorageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortField: false,
      showErrorCoordinates: false,
      getInitialized: false,
      showDeletePrompt: false
    };
  }

  componentDidMount = () => {    
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getBalesListData } = this.props;
    const { getInitialized } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storages');
    }
    if(!getInitialized && getBalesListData && getBalesListData.storages) {
      this.getInitialize();
    }
  }

  // To show delete confirmation box
  showDialogDeleteStorage = () => {
    this.setState({ showDeletePrompt: true });
  };

  deleteStorageOnConfirm(action) {
    const { deleteBaleMarker, activeFarm, match, history } = this.props;
    if (action) {
      deleteBaleMarker(activeFarm.farm.id, match.params.id);
      this.setState({ showDeletePrompt: false });
      setTimeout(() => {
        history.push('/storages');
      }, 1500);
    }
    this.setState(prevState => ({ showDeletePrompt: !prevState.showDeletePrompt }));
  }

  getInitialize = () => {
    const { history, match, initialize, getBalesListData } = this.props;
    const baleStorageId = match.params.id;

    if (baleStorageId !== 'create') {
      const storageItem = _.filter(getBalesListData.storages, ['id', baleStorageId])[0];
      if (storageItem) {
        const formValues = {
          baleName: storageItem.name,
          baleNote: storageItem.note
        };
        this.setState({
          sortField: storageItem.sortByField
        })
        this.setState({
          getInitialized: true
        });
        initialize(formValues);
      } else {
        history.push('/storages');
      }
    }
  };

  checkBoxChange = e => {
    this.setState(prevState => ({
      sortField: !prevState.sortField
    }));
    this.setState({
      showErrorCoordinates: false
    });
  };

  handleSubmit = values => {
    const { match, activeFarm, updateBaleMarker, getBalesList } = this.props;
    const { sortField, showDeletePrompt } = this.state;
    this.setState({ showErrorCoordinates: false });
    if(!showDeletePrompt) {
      if(getBalesList && getBalesList.savedBaleMarkerCoordinates && getBalesList.savedBaleMarkerCoordinates.latitude) {
        const formData = {
          "name": values.baleName,
          "farmId": activeFarm.farm.id,
          "sortByField": sortField,
          "gpsPoint": {
              "latitude": getBalesList.savedBaleMarkerCoordinates.latitude,
              "longitude": getBalesList.savedBaleMarkerCoordinates.longitude
          },
          "note": values.baleNote,
          "type": "EXTERNAL"
        }
        updateBaleMarker(activeFarm.farm.id, match.params.id, formData);
      } else {
        this.setState({
          showErrorCoordinates: true
        });
      }
    }
  };

  render() {
    const { handleSubmit, getBalesListData, match, getBalesList, intl, activeFarm, baleStorageList, hasSupportAdminRole } = this.props;
    const baleStorageId = match.params.id;
    const storageItem = _.filter(getBalesListData.storages, ['id', baleStorageId])[0];
    const { showErrorCoordinates, showDeletePrompt } = this.state;
    const enableCreateButton = 
      activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    return (
      <div className="manure-storage-form">
        <div className="full-width edit-field-wrap">
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="storage-trash-icon">
              <span className="storage-heading-modal">
                {intl.formatMessage({ id: 'EDIT_BALE_STORAGE_TITLE' })}
              </span>
              <span className={`btn-action btn-action--delete ${!enableCreateButton || baleStorageList[0].quantity > 0 ? 'disabled' : ''}`}>
                <IconButton
                  onClick={() => this.showDialogDeleteStorage()}
                >
                  <TrashcanIcon scale={1} />
                </IconButton>
              </span>
            </div>
            <div className="edit-bale-container no-padding-top">
              <div className="field-area">
                <BaleMap
                  title="Full screen"
                  mapId="off-screen-map-bale-edit"
                  paramId="edit"
                  mapData={storageItem}
                />
              </div>
              <div className="bale-settings">
                <div className="bale-settings-form">
                  <div className="padding-bottom-div font-style-div">
                    <Field
                      name="baleName"
                      label={intl.formatMessage({ id: 'CREATE_BALE_NAME' })}
                      component={TextBoxControl}
                      controlId="baleName"
                      type="text"
                      stlyeClass="font-style-div"
                    />
                  </div>
                  <div className="font-style-div padding-bottom-div">
                    <span className="location-type-checkbox">
                      <CheckboxButton
                        id="baleCheck"
                        label="Lagerplassen"
                        checked={this.state.sortField}
                        labelText={intl.formatMessage({ id: 'CREATE_BALE_SORT_FIELD' })}
                        onChange={e => this.checkBoxChange(e)}
                        stlyeClass="font-style-div"
                      />
                    </span>
                  </div>
                  <div className="fieldNote padding-top-div full-width">
                    <Field
                      name="baleNote"
                      label={intl.formatMessage({ id: 'CREATE_BALE_NOTE' })}
                      component={TextAreaControl}
                      controlId="baleNote"
                      stlyeClass="textarea-placeholder-size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field-form-submit-wrap">
              <div className="bale-error">
                {showErrorCoordinates && <ErrorText>{intl.formatMessage({ id: 'CREATE_BALE_MARKER_ERROR' })}</ErrorText>}
                {getBalesList && getBalesList.markerHasError && <ErrorText>{getBalesList.markerErrorMessage}</ErrorText>}
                {getBalesList && getBalesList.markerDeleteHasError && <ErrorText>{getBalesList.markerDeleteErrorMessage}</ErrorText>}
                {getBalesList && getBalesList.markerSaveSuccess && window.location.reload()}
              </div>
              <div>
                <span>
                  <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                    {intl.formatMessage({ id: 'EDIT_BALE_STORAGE_BUTTON' })}
                  </ActionButton>
                </span>
                {/*<span>
                  <ActionButton multi onClick={onCloseEditStoragePlan}>
                    {intl.formatMessage({ id: 'CANCEL' })}
                  </ActionButton>
                </span>*/}
              </div>
            </div>
          </form>
        </div>
        {showDeletePrompt && (
          <ConfirmDialogBox
            heading={`${intl.formatMessage({ id: 'STORAGE_DELETE_HEADER' })}?`}
            confirmText={intl.formatMessage({ id: 'DELETE' })}
            cancelText={intl.formatMessage({ id: 'CANCEL' })}
            callback={action => this.deleteStorageOnConfirm(action)}
            warning
          />
        )}
      </div>
    );
  }
}
const validate = (values, props) => {
  const { intl } = props;
  const errors = {};
  if (!values.baleName || !values.baleName.trim()) {
    errors.baleName = intl.formatMessage({ id: 'CREATE_BALE_NAME_ERROR' });
  }
  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  getBalesList: state.bales,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  updateBaleMarker: (farmId, baleStorageId, data) => dispatch(baleActions.updateBaleMarker(farmId, baleStorageId, data)),
  deleteBaleMarker: (farmId, baleStorageId) => dispatch(baleActions.deleteBaleMarker(farmId, baleStorageId)),
  saveBaleMarkerCoordinates: obj => dispatch(baleActions.saveBaleMarkerCoordinates(obj))
});

export default injectIntl(
  reduxForm({
    form: 'BaleStorageForm',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BaleStorageForm)))
);
