import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import TextBoxControl from 'components/forms/TextBoxControl';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import SelectControl from 'components/forms/SelectBoxControl';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import * as BaleAnalysisActions from 'actions/BaleAnalysis';
import { getDropDownListValues, getYearsForKPI } from 'utils/index';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import * as FieldActions from 'actions/Fields';
import mixpanel from 'mixpanel-browser';
import { ADD_FEED_ANALYSIS, ADD_FEED_ANALYSIS_COMPLETE } from 'constants/MixPanel';
import _ from 'lodash';

class AnalysisBaleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddAnalysis: false,
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: true,
      displayHarvest2: true,
      displayHarvest3: true,
      displayHarvest4: true,
      harvestFieldValues: [],
      noHarvestError: false,
      noSortFieldError: false,
      baleStorageError: false,
      noQuantityError: false,
      showAddAnalysisNext: false,
      noAnalysisParameter: false,
      analysisPageArray: null,
      selectedFieldCheckbox: [],
      selectedAnalysisYear: null,
      selectAllCheckbox: false,
      initializeSetup: false,
      analysisUpdated: false,
      updatedAnalysisReturn: false
    };
  }

  componentDidMount = () => {
    const year = new Date().getFullYear();
    this.setState({
      selectedAnalysisYear: { "label": year, "value": year}
    });
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, selectedAnalysisList, getAnalysisStorageList, paramId, onUpdateAnalysis } = this.props;
    const { initializeSetup, analysisUpdated, updatedAnalysisReturn } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storages');
    }
    if(!initializeSetup && paramId === 'edit' && selectedAnalysisList && getAnalysisStorageList.getStorageData
    ) {
      if(getAnalysisStorageList.updateAnalysisSuccess) {
        getAnalysisStorageList.updateAnalysisSuccess = false;
      }
      this.initializeValues();
    }
    if(paramId === 'edit' && !analysisUpdated && getAnalysisStorageList && getAnalysisStorageList.updateAnalysisSuccess) {
      this.setState({
        analysisUpdated: true
      });
    }
    if(paramId === 'edit' && analysisUpdated && !updatedAnalysisReturn && getAnalysisStorageList.updateAnalysisSuccess) {
      getAnalysisStorageList.updateAnalysisSuccess = false;
      onUpdateAnalysis(selectedAnalysisList.id)
      this.setState({
        updatedAnalysisReturn: true
      });
    }    
  }

  initializeValues() {
    const { initialize, selectedAnalysisList, getAnalysisStorageList, fieldList } = this.props;
    const year = new Date().getFullYear();
    let formData = {};
    if(selectedAnalysisList && selectedAnalysisList.year) {
      formData["analyseYear"] = { "label": selectedAnalysisList.year, "value": selectedAnalysisList.year };
    } else {
      formData["analyseYear"] = { "label": year, "value": year };
    }
    if(selectedAnalysisList && selectedAnalysisList.field) {
      formData["baleFieldName"] = { "label": selectedAnalysisList.field.name, "value": selectedAnalysisList.field.id };
    }
    if(getAnalysisStorageList && getAnalysisStorageList.getStorageData) {
      if(selectedAnalysisList && selectedAnalysisList.storageId) {
        const [storageName] = _.filter(getAnalysisStorageList.getStorageData, ['id', selectedAnalysisList.storageId]);
        formData["baleName"] = { "label": storageName.name, "value": storageName.id };
      }
    }
    if(selectedAnalysisList.analysisParams){
      formData["energi"] = selectedAnalysisList.analysisParams.energy ? selectedAnalysisList.analysisParams.energy : '';
      formData["torstoff"] = selectedAnalysisList.analysisParams.dryMatter ? selectedAnalysisList.analysisParams.dryMatter : ''
      formData["raprotein"] = selectedAnalysisList.analysisParams.rawProtein ? selectedAnalysisList.analysisParams.rawProtein : ''
      formData["fordoy"] = selectedAnalysisList.analysisParams.digestibility ? selectedAnalysisList.analysisParams.digestibility : ''
      formData["aat"] = selectedAnalysisList.analysisParams.aat ? selectedAnalysisList.analysisParams.aat : ''
      formData["pbv"] = selectedAnalysisList.analysisParams.pbv ? selectedAnalysisList.analysisParams.pbv : ''
      formData["ndf"] = selectedAnalysisList.analysisParams.ndf ? selectedAnalysisList.analysisParams.ndf : ''
      formData["ash"] = selectedAnalysisList.analysisParams.ash ? selectedAnalysisList.analysisParams.ash : ''
      formData["weight"] = selectedAnalysisList.analysisParams.weight ? selectedAnalysisList.analysisParams.weight : ''
    }
    let data = [];
    let keyPair = {};
    fieldList.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.fieldId,
      keyPair['show'] = _.filter(selectedAnalysisList.equalFields, ['id', field.fieldId])[0] ? true : false,
      data.push(keyPair)
    ))
    this.setState({
      showHarvest1: selectedAnalysisList.harvest === 1 ? true : false,
      showHarvest2: selectedAnalysisList.harvest === 2 ? true : false,
      showHarvest3: selectedAnalysisList.harvest === 3 ? true : false,
      showHarvest4: selectedAnalysisList.harvest === 4 ? true : false,
      displayHarvest1: true,
      displayHarvest2: true,
      displayHarvest3: true,
      displayHarvest4: true,
      selectedFieldCheckbox: data
    });
    initialize(formData);
    this.setState({
      initializeSetup: true
    })
  }

  handleSubmit = values => {
    const { showHarvest1, showHarvest2, showHarvest3, showHarvest4, showAddAnalysis, selectedFieldCheckbox } = this.state;
    const { activeFarm, addAnalysis, updateAnalysis, paramId, selectedAnalysisList, userDetails } = this.props;
    this.setState({
      noHarvestError: false,
      noSortFieldError: false,
      baleStorageError: false,
      noQuantityError: false,
      noAnalysisParameter: false
    });
    if(!showHarvest1 && !showHarvest2 && !showHarvest3 && !showHarvest4) {
      this.setState({
        noHarvestError: true
      });
      return;
    }

    if(showAddAnalysis) {
      let valuesEnergi = values.energi+'' ? (values.energi+'').replace(',','.') : '';
      let valuesTorstoff = values.torstoff+'' ? (values.torstoff+'').replace(',','.') : '';
      let valuesRaprotein = values.raprotein+'' ? (values.raprotein+'').replace(',','.') : '';
      let valuesForday = values.fordoy+'' ? (values.fordoy+'').replace(',','.') : '';
      let valuesAat = values.aat+'' ? (values.aat+'').replace(',','.') : '';
      let valuesPbv = values.pbv+'' ? (values.pbv+'').replace(',','.') : '';
      let valuesNdf = values.ndf+'' ? (values.ndf+'').replace(',','.') : '';
      let valuesAsh = values.ash+'' ? (values.ash+'').replace(',','.') : '';
      let valuesWeight = values.weight+'' ? (values.weight+'').replace(',','.') : '';
      valuesWeight = parseInt(valuesWeight);
      if ((valuesEnergi && isNaN(valuesEnergi)) || 
        (valuesTorstoff && isNaN(valuesTorstoff)) || 
        (valuesRaprotein && isNaN(valuesRaprotein)) ||
        (valuesForday && isNaN(valuesForday)) || 
        (valuesAat && isNaN(valuesAat)) || 
        (valuesPbv && isNaN(valuesPbv)) ||
        (valuesNdf && isNaN(valuesNdf)) ||
        (valuesAsh && isNaN(valuesAsh)) ||
        (valuesWeight && isNaN(valuesWeight))) {
        this.setState({
          noAnalysisParameter: true          
        });        
        return;
      }
      this.setState({
        analysisPageArray: values
      });
      const { fieldList } = this.props;
      if(paramId !== 'edit'){
        let data = [];
        let keyPair = {};
        fieldList && fieldList.map((field, index) => (
          //eslint-disable-next-line
          keyPair = {},
          keyPair['id'] = field.fieldId,
          keyPair['show'] = false,
          data.push(keyPair)
        ))
        this.setState({
          selectedFieldCheckbox: data
        });
      }
      this.setState({
        showAddBales: false,
        showDeleteBales: false,
        showAddAnalysis: false,
        showAddAnalysisNext: true
      });
    } else {
      let baleHarvest = 1;
      if(showHarvest1) { baleHarvest = 1; }
      if(showHarvest2) { baleHarvest = 2; }
      if(showHarvest3) { baleHarvest = 3; }
      if(showHarvest4) { baleHarvest = 4; }

      let formData = {};
      let analysisParams = {};
      let fieldArray = [];
      formData["farmId"] = activeFarm.farm.id;
      formData["harvest"] = baleHarvest;
      formData["year"] = values.analyseYear.value;
      if(values.baleName && values.baleName.value) {
        formData["storageId"] = values.baleName.value;
      }
      if(values.baleFieldName && values.baleFieldName.value) {
        formData["fieldId"] = values.baleFieldName.value;
      }
      if(values.energi && values.energi !== null && values.energi !== '') {
        analysisParams["energy"] = values.energi;
      }
      if(values.torstoff && values.torstoff !== null && values.torstoff !== '') {
        analysisParams["dryMatter"] = values.torstoff;
      }
      if(values.raprotein && values.raprotein !== null && values.raprotein !== '') {
        analysisParams["rawProtein"] = values.raprotein;
      }
      if(values.fordoy && values.fordoy !== null && values.fordoy !== '') {
        analysisParams["digestibility"] = values.fordoy;
      }
      if(values.aat && values.aat !== null && values.aat !== '') {
        analysisParams["aat"] = values.aat;
      }
      if(values.pbv && values.pbv !== null && values.pbv !== '') {
        analysisParams["pbv"] = values.pbv;
      }
      if(values.ndf && values.ndf !== null && values.ndf !== '') {
        analysisParams["ndf"] = values.ndf;
      }
      if(values.ash && values.ash !== null && values.ash !== '') {
        analysisParams["ash"] = values.ash;
      }
      if(values.weight && values.weight !== null && values.weight !== '') {
        analysisParams["weight"] = values.weight;
      }
      if(analysisParams) {
        formData["analysisParams"] = analysisParams;
      }      
      selectedFieldCheckbox.map((selectedField, index) => (
        //eslint-disable-next-line
        selectedField.show ? fieldArray.push(selectedField.id) : ''
      ))
      if(fieldArray && fieldArray.length > 0) {
        formData["equalFieldIds"] = fieldArray;
      }
      if(paramId === 'edit') {
        updateAnalysis(activeFarm.farm.id, selectedAnalysisList.id, formData);
        this.setState({
          analysisUpdated: false,
          updatedAnalysisReturn: false
        })
        this.closeAnalysisBalesNext();
      } else {
        mixpanel.identify(userDetails.id);
        mixpanel.track(ADD_FEED_ANALYSIS_COMPLETE);
        addAnalysis(activeFarm.farm.id, formData);
      }      
    }
  };

  openAnalysisBales = () => {    
    const { fieldList, paramId, userDetails, activeFarm, getFeedEventList } = this.props;
    getFeedEventList(activeFarm.farm.id);
    const { selectedFieldCheckbox } = this.state;

    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_FEED_ANALYSIS);

    let data = [];
    let keyPair = {};
    let data1 = [];
    let keyPair1 = {};
    if(paramId === 'edit') {
      this.initializeValues();
    } else {
      this.getAnalysisInitialize();
    }
    fieldList && fieldList.map((field, index) => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair1 = {},
      keyPair['id'] = field.fieldId,
      keyPair['name'] = field.displayName,
      keyPair1['id'] = field.fieldId,
      keyPair1['show'] = false,
      data.push(keyPair),
      data1.push(keyPair1)
    ))
    this.setState({
      harvestFieldValues: data
    });
    if(selectedFieldCheckbox.length <= 0){
      this.setState({
        selectedFieldCheckbox: data1
      });
    }
    this.setState({
      showAddAnalysis:true,
      showAddAnalysisNext: false
    });
  }

  closeAnalysisBales = () => {
    const { paramId } = this.props;
    if(paramId !== 'edit') {
      this.getAnalysisInitialize();
      this.setState({
        initializeSetup: false
      })
    }
    if(paramId === 'edit') {
      this.setState({
        initializeSetup: false
      })
    }
    this.setState({
      showAddBales: false,
      showDeleteBales: false,
      showAddAnalysis: false,
      showAddAnalysisNext: false
    });
  }

  goBack = () => {
    this.setState({
      showAddAnalysis: true,
      showAddAnalysisNext: false
    });
  }

  getAnalysisInitialize = () => {
    const { initialize } = this.props;
    const year = new Date().getFullYear();
    const formValues = {
      analyseYear: { "label": year, "value": year},
      baleName: { label: '', value: '' },
      baleFieldName: { label: '', value: '' },
      energi: '',
      torstoff: '',
      raprotein: '',
      fordoy: '',
      aat: '',
      pbv: '',
      ndf: '',
      ash: '',
      weight: ''
    };
    this.setState({
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: true,
      displayHarvest2: true,
      displayHarvest3: true,
      displayHarvest4: true,
      harvestFieldValues: [],
      quantityPlaceHolder: "",
      noHarvestError: false,
      noSortFieldError: false,
      baleStorageError: false,
      noQuantityError: false,
      noAnalysisParameter: false,
      selectedFieldCheckbox: []
    })
    initialize(formValues);
  };

  onChangeSelectedHarvest = e => {
    this.setState({
      baleStorageError: false
    });
  }

  onSelectHarvest = (harvest) => {
    this.setState({
      noHarvestError: false
    });
    this.setState({
      showHarvest1: harvest === 1 ? true : false,
      showHarvest2: harvest === 2 ? true : false,
      showHarvest3: harvest === 3 ? true : false,
      showHarvest4: harvest === 4 ? true : false
    });
  }

  onChangeSelectedField = e => {
    this.setState({
      noSortFieldError: false
    });
  }

  checkBoxChange = e => {
    const { selectedFieldCheckbox } = this.state;
    let data = [];
    let keyPair = {};
    selectedFieldCheckbox.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.id,
      keyPair['show'] = (e === field.id) ? (field.show === true ? false : true) : field.show,
      data.push(keyPair)
    ))
    this.setState({
      selectedFieldCheckbox: data
    });
  }

  onChangeAnalysisYear = e => {
    this.setState({
      selectedAnalysisYear: { "label": e.label, "value": e.value}
    });
  }

  closeAnalysisBalesNext = () => {
    const { paramId } = this.props;
    if(paramId !== 'edit') {
      this.getAnalysisInitialize();
    }
    if(paramId === 'edit') {
      this.setState({
        initializeSetup: false
      })
    }
    this.setState({
      showAddAnalysis: false,
      showAddAnalysisNext: false
    });
  }

  checkAllFields = () => {
    const { selectedFieldCheckbox, selectAllCheckbox } = this.state;
    let selectBox = true;
    if(selectAllCheckbox) {
      selectBox = false;
    }
    let data = [];
    let keyPair = {};
    selectedFieldCheckbox.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.id,
      keyPair['show'] = selectAllCheckbox ? false : true,
      data.push(keyPair)
    ))
    this.setState({
      selectedFieldCheckbox: data,
      selectAllCheckbox: selectBox
    });
  }
  
  render() {
    const { intl, getFeedEventListData, getAnalysisStorageListData, handleSubmit, getAnalysisStorageList, paramId } = this.props;
    const { showAddAnalysis, showHarvest1, showHarvest2, showHarvest3, showHarvest4, displayHarvest1, displayHarvest2, 
      displayHarvest3, displayHarvest4, harvestFieldValues, noHarvestError, noSortFieldError, baleStorageError, 
      noQuantityError, analysisPageArray, showAddAnalysisNext, selectedFieldCheckbox, noAnalysisParameter, 
      selectedAnalysisYear, selectAllCheckbox } = this.state;
    return (
      <div className={`add-harvest ${getAnalysisStorageListData && getAnalysisStorageListData.length <= 0 ? 'remove-cursor' : ''}`} 
        onClick={() => { getAnalysisStorageListData && getAnalysisStorageListData.length > 0 && this.openAnalysisBales(); }}>
        {paramId === 'add' && <span className="pad-right">{intl.formatMessage({ id: 'BALE_OVERVIEW_ADD_BALES' })}</span>}
        {paramId === 'add' && <div className="add-icon">
          <img src={`./images/bale_analysis.png`} className="bale-icon-size" alt="draw" />
        </div>}
        {paramId === 'edit' && 
        <div className="floatRight">
          <span className="pad-right bale-edit-link">{intl.formatMessage({ id: 'SELECTED_STORAGE_MAP_EDIT' })}</span>
        </div>}
        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '25%'}}
          width={{mobile: '90%', tablet: '55%', web: '25%'}}
          height={{mobile: '90%', tablet: '75%', web: '75%'}}
          show={showAddAnalysis}
          closeIcon=""
          onClose={() => {}}>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <div className='text-align-center'>
                <span className='fertilization-header'>{intl.formatMessage({ id: 'ADD_ANALYSIS_TITLE' })}</span>
              </div>
              <div className='text-align-left padding-top-div-large'>
                <EmphasisLight size="normal">{intl.formatMessage({ id: 'ADD_ANALYSIS_TITLE_1' })}</EmphasisLight>
              </div>
              <div>
                <div className='bale-storage-form-style'>
                  <div>
                    <Field
                      name="analyseYear"
                      label={intl.formatMessage({ id: 'CHOOSE_YEAR' })}
                      controlId="analyseYear"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getYearsForKPI()}
                      defaultValue={selectedAnalysisYear}
                      onChange={e => this.onChangeAnalysisYear(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <div className="lightbox-padding-bottom-div">
                      <EmphasisLight size="small">{intl.formatMessage({ id: 'ADD_BALE_HARVEST' })}</EmphasisLight>
                    </div>
                    <div>
                      <span className={`number-padding ${displayHarvest1 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest1 && this.onSelectHarvest(1); }}>
                        {showHarvest1 ?
                          <img src={`./images/h1_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest1 ?
                          <img src={`./images/h1.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h1_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest2 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest2 && this.onSelectHarvest(2); }}>
                        {showHarvest2 ?
                          <img src={`./images/h2_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest2 ?
                          <img src={`./images/h2.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h2_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest3 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest3 && this.onSelectHarvest(3); }}>
                        {showHarvest3 ?
                          <img src={`./images/h3_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest3 ?
                          <img src={`./images/h3.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h3_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest4 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest4 && this.onSelectHarvest(4); }}>
                        {showHarvest4 ?
                          <img src={`./images/h4_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest4 ?
                          <img src={`./images/h4.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h4_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                    </div>
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="baleFieldName"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_FIELD' })}
                      controlId="baleFieldName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(harvestFieldValues, true)}
                      onChange={e => this.onChangeSelectedField(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    {getAnalysisStorageList && getAnalysisStorageList.getStorageData &&
                    <Field
                      name="baleName"
                      label={intl.formatMessage({ id: 'ADD_BALE_NAME_DROPDOWN' })}
                      controlId="baleName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(getAnalysisStorageList.getStorageData, true)}
                      onChange={e => this.onChangeSelectedHarvest(e)}
                      placeholder=""
                    />}
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="weight"
                      label={intl.formatMessage({ id: 'WEIGHT' })}
                      component={TextBoxControl}
                      controlId="weight"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_KG' })}
                    />
                  </div>
                  <div className='text-align-left padding-top-div-large'>
                    <EmphasisLight size="normal">{intl.formatMessage({ id: 'ADD_ANALYSIS_TITLE_2' })}</EmphasisLight>
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="energi"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_1' })}
                      component={TextBoxControl}
                      controlId="energi"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_1_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="torstoff"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_2' })}
                      component={TextBoxControl}
                      controlId="torstoff"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_2_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="raprotein"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_3' })}
                      component={TextBoxControl}
                      controlId="raprotein"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_3_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="fordoy"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_4' })}
                      component={TextBoxControl}
                      controlId="fordoy"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_4_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="aat"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_5' })}
                      component={TextBoxControl}
                      controlId="aat"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_5_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="pbv"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_6' })}
                      component={TextBoxControl}
                      controlId="pbv"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_6_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="ndf"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_7' })}
                      component={TextBoxControl}
                      controlId="ndf"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_7_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top">
                    <Field
                      name="ash"
                      label={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_8' })}
                      component={TextBoxControl}
                      controlId="ash"
                      type="text"
                      units={intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMS_8_UNIT' })}
                    />
                  </div>
                  <div className="lightbox-field-padding-top-large">
                    <span className="paddingRight">
                    {baleStorageError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_1' })}</ErrorText>}
                    {noHarvestError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_2' })}</ErrorText>}
                    {noSortFieldError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_3' })}</ErrorText>}
                    {noAnalysisParameter && <ErrorText>{intl.formatMessage({ id: 'ADD_ANALYSIS_PARAMETER_ERROR' })}</ErrorText>}
                    {getFeedEventListData.saveSuccess && window.location.reload()}
                    </span>
                  </div>
                  <div className="bale-button-style">
                    <span className="paddingRight">
                      <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                        {intl.formatMessage({ id: 'ADD_ANALYSIS_NEXT_BUTTON' })}
                      </ActionButton>
                    </span>
                    <span className="bale-list-cancel-button" onClick={() => this.closeAnalysisBales()}>
                      {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalDialog>

        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '25%'}}
          width={{mobile: '90%', tablet: '55%', web: '25%'}}
          height={{mobile: '90%', tablet: '75%', web: '75%'}}
          show={showAddAnalysisNext}
          closeIcon=""
          onClose={() => {}}>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <div className='text-align-center'>
                <span className="breadCrumb-span-modal" onClick={() => this.goBack()}>{intl.formatMessage({ id: 'BACK' })}</span>
                <span className='fertilization-header'>{intl.formatMessage({ id: 'ADD_ANALYSIS_TITLE' })}</span>
              </div>
              <div className='text-align-left padding-top-div-large'>
                <EmphasisLight size="normal">{intl.formatMessage({ id: 'ADD_ANALYSIS_PAGE_2_HEADING' })}</EmphasisLight>
              </div>
              <div className='text-align-left padding-top-div-large'>
                <span className="paddingRight-less">
                  <img src={`./images/bale_storage.png`} className="bale-icon-size-small" alt="draw" />
                </span>
                  <EmphasisLight size="large">
                  {`${analysisPageArray && analysisPageArray.baleName && analysisPageArray.baleName.label ? analysisPageArray.baleName.label+', ' : ''}`}
                  {`${analysisPageArray && analysisPageArray.baleFieldName && analysisPageArray.baleFieldName.label ? analysisPageArray.baleFieldName.label+', ' : ''}`}
                  {showHarvest1 && ' 1. '}
                  {showHarvest2 && ' 2. '}
                  {showHarvest3 && ' 3. '}
                  {showHarvest4 && ' 4. '}
                  {intl.formatMessage({ id: 'ADD_ANALYSIS_SLATT' })}
                </EmphasisLight>
              </div>
              <div className='padding-top-div-large'>
                <span className="location-type-checkbox-right location-type-checkbox-width">
                  <CheckboxButton
                    id="checkAll"
                    label="Lagerplassen"
                    checked={selectAllCheckbox}
                    labelText=""
                    stlyeClass="font-style-div"
                    onChange={() => this.checkAllFields()}
                  />
                </span>
                <span className="location-type-checkbox-right padding-row-small">
                  {intl.formatMessage({ id: 'ADD_ANALYSIS_CHECKBOX' })}
                </span>
              </div>
              <div className="storage-class-left">
                <div className='modal-bale-storage-form-style'>
                  <div>
                    {harvestFieldValues && 
                      <div className="modal-storage-list-details">
                        <ul className="storage-list-wrapper">
                          {harvestFieldValues.map((field, index) => (
                            <li className={`modal-storage-list-item full-width`} key={index+1}>
                              <div className="storage-details">
                                <div className="storage-name modal-large-width">
                                  <span title="slatt">
                                    <span className='medium-font-text vertical-align-super number-padding-left'>
                                      {field.name}
                                    </span>
                                  </span>
                                </div>
                                <div className="storage-arrow">
                                  <span className="location-type-checkbox">
                                    <CheckboxButton
                                      id={`${field.id}`}
                                      label="Lagerplassen"
                                      checked={selectedFieldCheckbox.length > 0 ? (selectedFieldCheckbox.length > 0 && _.filter(selectedFieldCheckbox, ['id', field.id])[0]).show : false}
                                      labelText=""
                                      stlyeClass="font-style-div"
                                      onChange={e => this.checkBoxChange(field.id)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    }
                  </div>
                  <div className="lightbox-field-padding-top-large">
                    <span className="paddingRight">
                      {baleStorageError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_1' })}</ErrorText>}
                      {noHarvestError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_2' })}</ErrorText>}
                      {noSortFieldError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_3' })}</ErrorText>}
                      {noQuantityError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_4' })}</ErrorText>}                    
                      {getFeedEventListData.saveSuccess && window.location.reload()}
                      {getAnalysisStorageList.saveAnalysisSuccess && window.location.reload()}
                    </span>
                  </div>
                  <div className="bale-button-style">
                    <span className="paddingRight">
                      <ActionButton onClick={handleSubmit(this.handleSubmit)}>
                        {paramId === 'edit' ? intl.formatMessage({ id: 'update' }) : intl.formatMessage({ id: 'ADD_ANALYSIS_BUTTON' })}
                      </ActionButton>
                    </span>
                    <span className="bale-list-cancel-button" onClick={() => this.closeAnalysisBalesNext()}>
                      {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  locations: state.baleFeedEvent.locationData,
  getFeedEventListData: state.baleFeedEvent,
  fieldList: state.fieldDetails.data,
  getAnalysisStorageList: state.baleAnalysis,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  getAnalysisStorage: (farmId) => dispatch(BaleAnalysisActions.getAllStorages(farmId)),
  addAnalysis: (farmId, data) => dispatch(BaleAnalysisActions.saveAnalysis(farmId, data)),
  getFeedEventList: (farmId) => dispatch(baleFeedEventActions.getFeedEventList(farmId)),
  fetchFieldList: (farmId) => dispatch(FieldActions.fetchFieldList(farmId)),
  updateAnalysis: (farmId, analysisId, data) => dispatch(BaleAnalysisActions.updateAnalysis(farmId, analysisId, data))
});

export default injectIntl(
  reduxForm({
    form: 'AnalysisBaleModalForm'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AnalysisBaleModal)))
);
