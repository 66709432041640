export const ADD_GRASS_ANALYSIS_LOADING = 'ADD_GRASS_ANALYSIS_LOADING';
export const ADD_GRASS_ANALYSIS_SUCCESS = 'ADD_GRASS_ANALYSIS_SUCCESS';
export const ADD_GRASS_ANALYSIS_ERROR = 'ADD_GRASS_ANALYSIS_ERROR';
export const GET_GRASS_ANALYSIS_LOADING = 'GET_GRASS_ANALYSIS_LOADING';
export const GET_GRASS_ANALYSIS_SUCCESS = 'GET_GRASS_ANALYSIS_SUCCESS';
export const GET_GRASS_ANALYSIS_ERROR = 'GET_GRASS_ANALYSIS_ERROR';
export const DELETE_GRASS_ANALYSIS_LOADING = 'DELETE_GRASS_ANALYSIS_LOADING';
export const DELETE_GRASS_ANALYSIS_SUCCESS = 'DELETE_GRASS_ANALYSIS_SUCCESS';
export const DELETE_GRASS_ANALYSIS_ERROR = 'DELETE_GRASS_ANALYSIS_ERROR';
export const GRASS_ANALYSIS_SEARCH_LOADING = 'GRASS_ANALYSIS_SEARCH_LOADING';
export const GRASS_ANALYSIS_SEARCH_SUCCESS = 'GRASS_ANALYSIS_SEARCH_SUCCESS';
export const GRASS_ANALYSIS_SEARCH_ERROR = 'GRASS_ANALYSIS_SEARCH_ERROR';