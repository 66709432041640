import * as React from 'react';

import MineralStorageList from 'mui-mobile-components/storages/mineralstorage/MineralStorageList';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
 
class MineralStorage extends React.Component {
  render() {
    const { addNewMineralFertilizer } = this.props;
    return (
      <MineralStorageList
      addNewMineralFertilizer={addNewMineralFertilizer} />
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(MineralStorage)));