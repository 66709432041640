import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isTablet } from 'react-device-detect';

import Footer from 'components/Footer';
import NavigationBar from 'mui-components/layouts/NavigationBar';
import Loader from 'components/Loader';
import LoaderPage from 'components/LoaderPage';
import Header from 'containers/header';
import CommonError from 'components/CommonErrorMessage';
import { Grid, Box, CssBaseline } from '@mui/material';

import { fetchRolePermistionList } from 'actions/RolesDetails';
import fetchMasterData from 'actions/MasterData';
import { fetchFarmList } from 'actions/Farms';
import { dataValidationPermission } from 'actions/GpsData';
import { fetchSupportAdminPermission } from 'actions/SupportAdminRole';
import { ENTER_TASKS, ENTER_FIELD } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndCondition: false,
      fetchFarmListState: false,
      showMobileMenu1: false,
      showMobileMenu2: false,
      showMobileMenu3: false,
      showMobileMenu4: false,
    };
    this.openTermsAndCondition = this.openTermsAndCondition.bind(this);
    this.closeTermsAndCondition = this.closeTermsAndCondition.bind(this);
  }
  componentDidMount = () => {
    const { getRolesDetails, userDetails, dataPermission, getMasterData, supportAdminPermission } = this.props;
    const { idToken } = userDetails.data;
    getRolesDetails(idToken);
    dataPermission(idToken);
    supportAdminPermission();
    getMasterData();
  };

  componentDidUpdate = () => {
    const { getFarmList, userDetails, hasSupportAdminRole, farmList } = this.props;
    const { lastaccessedFarm } = userDetails.data;
    const { fetchFarmListState } = this.state;
    let version = "v1";
    if(!fetchFarmListState && hasSupportAdminRole.data !== null) {
      if(hasSupportAdminRole.data) {
        version = "v2";
      }
      if(!farmList.data) {
        if(localStorage.getItem("selectedFarmId") === null &&  localStorage.getItem("selectedFarmName") === null) {
          getFarmList(lastaccessedFarm,version);
        } else {
          getFarmList(localStorage.getItem("selectedFarmId"),version);
        }
        this.setState({
          fetchFarmListState: true
        });
      }
    }
  }

  openTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: true
    });
  }

  closeTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: false
    });
  }

  handleClickEvent = (eventValue) => {
    const { userDetails } = this.props;
    if(eventValue === '1') {
      mixpanel.identify(userDetails.data.id);
      mixpanel.track(ENTER_FIELD);
      this.setState({
        showMobileMenu1: true,
        showMobileMenu2: false,
        showMobileMenu3: false,
        showMobileMenu4: false
      });
    } else if(eventValue === '2') {
      mixpanel.identify(userDetails.data.id);
      mixpanel.track(ENTER_TASKS);
      this.setState({
        showMobileMenu1: false,
        showMobileMenu2: true,
        showMobileMenu3: false,
        showMobileMenu4: false
      });
    } else if(eventValue === '3') {
      this.setState({
        showMobileMenu1: false,
        showMobileMenu2: false,
        showMobileMenu3: true,
        showMobileMenu4: false
      });
    } else {
      this.setState({
        showMobileMenu1: false,
        showMobileMenu2: false,
        showMobileMenu3: false,
        showMobileMenu4: true
      });
    }
  }

  render() {
    const { showTermsAndCondition } = this.state;
    const {
      props: { children, farmList, rolesDetails, userDetails, location, masterData, intl }
    } = this;
    const error = farmList.hasError || rolesDetails.hasError || masterData.hasError;
    const profileDisplayCheck = location && location.pathname.includes('profilesettings');
    return (
      <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f0f0' }}>
        <Box sx={{ width: isTablet ? 0 : 319, flexShrink: 0, '& .MuiDrawer-paper': { width: isTablet ? 0 : 319 }}}><NavigationBar /></Box>
        {userDetails.isLogoutLoading ? <LoaderPage /> : null}
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid 
            item xs={12} sm={12} md={12} sx={{ 
              padding: 2, 
              backgroundColor: '#F2F4F4',
              height: '100vh',
              overflowY: 'auto'
            }}
          >
            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '16px', minHeight: '98vh' }}>
              <div className="full-width app-content-wrapper">
                {(farmList.data && rolesDetails.data && masterData.data) || profileDisplayCheck
                  ? children
                  : null}
                {!farmList.data && !rolesDetails.data && !masterData.data && !error && (
                  <Loader styleName="layout-loader" />
                )}
                {error && !profileDisplayCheck && (
                  <div className={`full-width ${'main-content'}`}>
                    <Header
                      bcLink={{ to: '/tasks', text: intl.formatMessage({ id: 'TASKS' }) }}
                      bcTitle={intl.formatMessage({ id: 'HEADER_TASKS' })}
                    />
                    <CommonError />
                  </div>
                )}
                <Footer
                  showTermsAndCondition={showTermsAndCondition}
                  openTermsAndCondition={this.openTermsAndCondition}
                  closeTermsAndCondition={this.closeTermsAndCondition}
                  userDetails={userDetails}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
        <CssBaseline />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  rolesDetails: state.RolesDetails,
  farmList: state.farmList,
  masterData: state.masterData,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getFarmList: (lastaccessedFarm, version) => dispatch(fetchFarmList(lastaccessedFarm, version)),
  dataPermission: token => dispatch(dataValidationPermission(token)),
  getRolesDetails: token => dispatch(fetchRolePermistionList(token)),
  getMasterData: () => dispatch(fetchMasterData()),
  supportAdminPermission: () => dispatch(fetchSupportAdminPermission())
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout)));
