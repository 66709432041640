import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import Text from '@tine/lib-frontend-components/elements/typography/text';

import SampleUpload from 'mui-components/fields/soilsamplesimport/SampleUpload';
import SampleList from 'mui-components/fields/soilsamplesimport/SoilSamplesList';

class SoilSamples extends React.Component {
  componentDidMount = () => {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
  };

  render() {
    const {
      pageContent: { labels, lblCommon }
    } = this.props;
    return (
      <SectionContainer
        styling={{
          paddingTop: 'low'
        }}
      >
        <p className="soil-info-text">
          <Text>{labels.uploadAndMap}</Text>
        </p>
        <SampleUpload labels={labels} lblCommon={lblCommon} />
        <SampleList labels={labels} lblCommon={lblCommon} />
      </SectionContainer>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'soilSamplesPage'])[0]
});

const mapDispatchToProps = dispatch => ({});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SoilSamples)));
