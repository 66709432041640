import * as React from 'react';
import { injectIntl } from 'react-intl';
import { buttonRoundStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';
import { Stack, Box, Chip } from '@mui/material';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import { ADD_EQUIPMENT } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

const AddAsset = ({ disabled, intl, userInfo, equipmentProps }) => {
  const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
  const titleFont = {
    color: '#191C1C',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '32px'
  }
  const subTitleFont = {
    color: '#3F4949',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px'
  }
  return (
    <div className="manageFarmsLayout">
      <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
        <div className="manageFarmsLayoutDiv">
          <div><img src="images/NoEquipment.png" alt="No Equipment" width="120" /></div>
          <Box style={inputStyle}>
            <Stack className="text-with-padding">
              <div style={titleFont}>
                {intl.formatMessage({ id: 'EQUIPMENT' })}
              </div>
            </Stack>
            <Stack className="text-with-padding">
              <div style={subTitleFont}>
                {intl.formatMessage({ id: 'NO_EQUIPMENT_CONTENT' })}
              </div>
            </Stack>
            <div style={{ padding: '20px 0px', paddingRight: '0px !important' }} className={`${disabled ? 'disabled' : ''}`}>
              <Stack direction="row" justifyContent="center">
                <Chip
                  icon={<PlusIcon />}
                  label={intl.formatMessage({ id: 'ADD_EQUIPMENT' })}
                  color="primary"
                  variant="outlined"
                  clickable
                  onClick={() => {mixpanel.identify(userInfo.data.id); mixpanel.track(ADD_EQUIPMENT); equipmentProps.onOpenEquipmentDialog('new');}}
                  style={buttonRoundStyle}
                />
              </Stack>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AddAsset);
