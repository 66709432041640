import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Footer from 'components/Footer';
import NavigationBar from 'mui-mobile-components/layouts/NavigationBar';
import Loader from 'components/Loader';
import LoaderPage from 'components/LoaderPage';
import Header from 'containers/header';
import CommonError from 'components/CommonErrorMessage';
import { Grid, Box, CssBaseline } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import * as appLayoutStyle from 'mui-mobile-components/layouts/AppLayoutStyle';

import { fetchRolePermistionList } from 'actions/RolesDetails';
import fetchMasterData from 'actions/MasterData';
import { fetchFarmList } from 'actions/Farms';
import { dataValidationPermission } from 'actions/GpsData';
import { fetchSupportAdminPermission } from 'actions/SupportAdminRole';
import { createSvgIcon } from '@mui/material/utils';
import { ENTER_TASKS, ENTER_FIELD } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const DotIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 16C14.7091 16 16.5 14.2091 16.5 12C16.5 9.79086 14.7091 8 12.5 8C10.2909 8 8.5 9.79086 8.5 12C8.5 14.2091 10.2909 16 12.5 16ZM18.5 12C18.5 15.3137 15.8137 18 12.5 18C9.18629 18 6.5 15.3137 6.5 12C6.5 8.68629 9.18629 6 12.5 6C15.8137 6 18.5 8.68629 18.5 12Z" fill="#3F4949"/>
  </svg>
);

const DotFullIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 18C15.8137 18 18.5 15.3137 18.5 12C18.5 8.68629 15.8137 6 12.5 6C9.18629 6 6.5 8.68629 6.5 12C6.5 15.3137 9.18629 18 12.5 18Z" fill="#041F21"/>
  </svg>
);

const Icon2 = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11 21V13.025C9.93333 13.025 8.90833 12.8208 7.925 12.4125C6.94167 12.0042 6.075 11.425 5.325 10.675C4.575 9.925 4 9.05833 3.6 8.075C3.2 7.09167 3 6.06667 3 5V3H5C6.05 3 7.06667 3.20417 8.05 3.6125C9.03333 4.02083 9.9 4.6 10.65 5.35C11.1667 5.86667 11.5958 6.43333 11.9375 7.05C12.2792 7.66667 12.5417 8.325 12.725 9.025C12.8083 8.90833 12.9 8.79583 13 8.6875C13.1 8.57917 13.2083 8.46667 13.325 8.35C14.075 7.6 14.9417 7.02083 15.925 6.6125C16.9083 6.20417 17.9333 6 19 6H21V8C21 9.06667 20.7958 10.0917 20.3875 11.075C19.9792 12.0583 19.4 12.925 18.65 13.675C17.9 14.425 17.0375 15 16.0625 15.4C15.0875 15.8 14.0667 16 13 16V21H11ZM11 11C11 10.2 10.8458 9.4375 10.5375 8.7125C10.2292 7.9875 9.79167 7.34167 9.225 6.775C8.65833 6.20833 8.0125 5.77083 7.2875 5.4625C6.5625 5.15417 5.8 5 5 5C5 5.8 5.15 6.56667 5.45 7.3C5.75 8.03333 6.18333 8.68333 6.75 9.25C7.31667 9.81667 7.96667 10.25 8.7 10.55C9.43333 10.85 10.2 11 11 11ZM13 14C13.8 14 14.5625 13.85 15.2875 13.55C16.0125 13.25 16.6583 12.8167 17.225 12.25C17.7917 11.6833 18.2292 11.0333 18.5375 10.3C18.8458 9.56667 19 8.8 19 8C18.2 8 17.4333 8.15417 16.7 8.4625C15.9667 8.77083 15.3167 9.20833 14.75 9.775C14.1833 10.3417 13.75 10.9875 13.45 11.7125C13.15 12.4375 13 13.2 13 14Z" fill="#3F4949"/>
  </svg>,
  'icon2'
);

const Icon2Filled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 21V13.025C9.93333 13.025 8.90833 12.8208 7.925 12.4125C6.94167 12.0042 6.075 11.425 5.325 10.675C4.575 9.925 4 9.05833 3.6 8.075C3.2 7.09167 3 6.06667 3 5V3H5C6.05 3 7.06667 3.20417 8.05 3.6125C9.03333 4.02083 9.9 4.6 10.65 5.35C11.1667 5.86667 11.5958 6.43333 11.9375 7.05C12.2792 7.66667 12.5417 8.325 12.725 9.025C12.8083 8.90833 12.9 8.79583 13 8.6875C13.1 8.57917 13.2083 8.46667 13.325 8.35C14.075 7.6 14.9417 7.02083 15.925 6.6125C16.9083 6.20417 17.9333 6 19 6H21V8C21 9.06667 20.7958 10.0917 20.3875 11.075C19.9792 12.0583 19.4 12.925 18.65 13.675C17.9 14.425 17.0375 15 16.0625 15.4C15.0875 15.8 14.0667 16 13 16V21H11Z" fill="#3F4949"/>
</svg>,
  'icon2filled'
);

const Icon3 = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#3F4949"/>
  </svg>,
  'icon3'
);

const Icon3Filled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#3F4949"/>
  </svg>,
  'icon3filled'
);

const Icon5 = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3 21C2.45 21 1.97917 20.8042 1.5875 20.4125C1.19583 20.0208 1 19.55 1 19V14C1 13.45 1.19583 12.9792 1.5875 12.5875C1.97917 12.1958 2.45 12 3 12V7.725C2.7 7.54167 2.45833 7.30417 2.275 7.0125C2.09167 6.72083 2 6.38333 2 6V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H9C9.55 3 10.0208 3.19583 10.4125 3.5875C10.8042 3.97917 11 4.45 11 5V6C11 6.38333 10.9083 6.72083 10.725 7.0125C10.5417 7.30417 10.3 7.54167 10 7.725V12H14V7.725C13.7 7.54167 13.4583 7.30417 13.275 7.0125C13.0917 6.72083 13 6.38333 13 6V5C13 4.45 13.1958 3.97917 13.5875 3.5875C13.9792 3.19583 14.45 3 15 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V6C22 6.38333 21.9083 6.72083 21.725 7.0125C21.5417 7.30417 21.3 7.54167 21 7.725V12C21.55 12 22.0208 12.1958 22.4125 12.5875C22.8042 12.9792 23 13.45 23 14V19C23 19.55 22.8042 20.0208 22.4125 20.4125C22.0208 20.8042 21.55 21 21 21H3ZM15 6H20V5H15V6ZM4 6H9V5H4V6ZM16 12H19V8H16V12ZM5 12H8V8H5V12ZM3 19H21V14H3V19Z" fill="#3F4949"/>
  </svg>,
  'icon5'
);

const Icon5Filled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 21C2.45 21 1.97917 20.8042 1.5875 20.4125C1.19583 20.0208 1 19.55 1 19V14C1 13.45 1.19583 12.9792 1.5875 12.5875C1.97917 12.1958 2.45 12 3 12V7.725C2.7 7.54167 2.45833 7.30417 2.275 7.0125C2.09167 6.72083 2 6.38333 2 6V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H9C9.55 3 10.0208 3.19583 10.4125 3.5875C10.8042 3.97917 11 4.45 11 5V6C11 6.38333 10.9083 6.72083 10.725 7.0125C10.5417 7.30417 10.3 7.54167 10 7.725V12H14V7.725C13.7 7.54167 13.4583 7.30417 13.275 7.0125C13.0917 6.72083 13 6.38333 13 6V5C13 4.45 13.1958 3.97917 13.5875 3.5875C13.9792 3.19583 14.45 3 15 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V6C22 6.38333 21.9083 6.72083 21.725 7.0125C21.5417 7.30417 21.3 7.54167 21 7.725V12C21.55 12 22.0208 12.1958 22.4125 12.5875C22.8042 12.9792 23 13.45 23 14V19C23 19.55 22.8042 20.0208 22.4125 20.4125C22.0208 20.8042 21.55 21 21 21H3ZM16 12H19V8H16V12ZM5 12H8V8H5V12Z" fill="#3F4949"/>
  </svg>,
  'icon5filled'
);

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndCondition: false,
      fetchFarmListState: false,
      showMobileMenu1: false,
      showMobileMenu2: false,
      showMobileMenu3: false,
      showMobileMenu4: false,
    };
    this.openTermsAndCondition = this.openTermsAndCondition.bind(this);
    this.closeTermsAndCondition = this.closeTermsAndCondition.bind(this);
  }
  componentDidMount = () => {
    const { getRolesDetails, userDetails, dataPermission, getMasterData, supportAdminPermission } = this.props;
    const { idToken } = userDetails.data;
    getRolesDetails(idToken);
    dataPermission(idToken);
    supportAdminPermission();
    getMasterData();
  };

  componentDidUpdate = () => {
    const { getFarmList, userDetails, hasSupportAdminRole, farmList } = this.props;
    const { lastaccessedFarm } = userDetails.data;
    const { fetchFarmListState } = this.state;
    let version = "v1";
    if(!fetchFarmListState && hasSupportAdminRole.data !== null) {
      if(hasSupportAdminRole.data) {
        version = "v2";
      }
      if(!farmList.data) {
        if(localStorage.getItem("selectedFarmId") === null &&  localStorage.getItem("selectedFarmName") === null) {
          getFarmList(lastaccessedFarm,version);
        } else {
          getFarmList(localStorage.getItem("selectedFarmId"),version);
        }
        this.setState({
          fetchFarmListState: true
        });
      }
    }
  }

  openTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: true
    });
  }

  closeTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: false
    });
  }

  handleClickEvent = (eventValue) => {
    const { userDetails } = this.props;
    if(eventValue === '1') {
      mixpanel.identify(userDetails.data.id);
      mixpanel.track(ENTER_FIELD);
      this.setState({
        showMobileMenu1: true,
        showMobileMenu2: false,
        showMobileMenu3: false,
        showMobileMenu4: false
      });
    } else if(eventValue === '2') {
      mixpanel.identify(userDetails.data.id);
      mixpanel.track(ENTER_TASKS);
      this.setState({
        showMobileMenu1: false,
        showMobileMenu2: true,
        showMobileMenu3: false,
        showMobileMenu4: false
      });
    } else if(eventValue === '3') {
      this.setState({
        showMobileMenu1: false,
        showMobileMenu2: false,
        showMobileMenu3: true,
        showMobileMenu4: false
      });
    } else {
      this.setState({
        showMobileMenu1: false,
        showMobileMenu2: false,
        showMobileMenu3: false,
        showMobileMenu4: true
      });
    }
  }

  render() {
    const { showTermsAndCondition, showMobileMenu1, showMobileMenu2, showMobileMenu3, showMobileMenu4 } = this.state;
    const {
      props: { children, farmList, rolesDetails, userDetails, location, masterData, intl }
    } = this;
    const error = farmList.hasError || rolesDetails.hasError || masterData.hasError;
    const profileDisplayCheck = location && location.pathname.includes('profilesettings');
    return (
      <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f0f0' }}>
        <Box><NavigationBar /></Box>
        {userDetails.isLogoutLoading ? <LoaderPage /> : null}
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid 
            item xs={12} sm={12} md={12} sx={{ 
              backgroundColor: '#F2F4F4',
              height: '100vh',
              overflowY: 'auto'
            }}
          >
            <Box sx={{ backgroundColor: '#FFFFFF', minHeight: '98vh' }}>
              <div className="full-width app-content-wrapper">
                {(farmList.data && rolesDetails.data && masterData.data) || profileDisplayCheck
                  ? children
                  : null}
                {!farmList.data && !rolesDetails.data && !masterData.data && !error && (
                  <Loader styleName="layout-loader" />
                )}
                {error && !profileDisplayCheck && (
                  <div className={`full-width ${'main-content-mobile'}`}>
                    <Header
                      bcLink={{ to: '/tasks', text: intl.formatMessage({ id: 'TASKS' }) }}
                      bcTitle={intl.formatMessage({ id: 'HEADER_TASKS' })}
                    />
                    <CommonError />
                  </div>
                )}
                <Footer
                  showTermsAndCondition={showTermsAndCondition}
                  openTermsAndCondition={this.openTermsAndCondition}
                  closeTermsAndCondition={this.closeTermsAndCondition}
                  userDetails={userDetails}
                />
              </div>
              <Box sx={{ pb: 7 }}>
                <Paper
                  sx={{
                    position: 'fixed', 
                    bottom: 0, 
                    left: 0, 
                    right: 0, 
                    backgroundColor: '#ffffff', 
                    zIndex: 5,
                    boxShadow: 3
                  }}
                >
                  <BottomNavigation showLabels
                    sx={{
                      display: 'flex',
                      padding: '0px 8px 20px 8px',
                      alignItems: 'center',
                      /*gap: '8px',*/
                      height: '95px'
                    }}
                  >
                    <Link to="/managefields" onClick={() => this.handleClickEvent('1')} style={{ textDecoration: 'none' }}>
                      <BottomNavigationAction
                        showLabel
                        style={showMobileMenu1 ? appLayoutStyle.mobileHeadingBold : appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_1' })}
                        icon={showMobileMenu1 ? (<span style={appLayoutStyle.iconBackground}><Icon2Filled /></span>) : <span style={appLayoutStyle.iconPlainBackground}><Icon2 /></span>}
                      />
                    </Link>
                    <Link to="/tasks" onClick={() => this.handleClickEvent('2')} style={{ textDecoration: 'none' }}>
                      <BottomNavigationAction
                        showLabel
                        style={showMobileMenu2 ? appLayoutStyle.mobileHeadingBold : appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_2' })}
                        icon={showMobileMenu2 ? (<span style={appLayoutStyle.iconBackground}><Icon3Filled /></span>) : <span style={appLayoutStyle.iconPlainBackground}><Icon3 /></span>}
                      />
                    </Link>
                    <Link to="/grassanalysis" onClick={() => this.handleClickEvent('3')}>
                      <BottomNavigationAction
                        showLabel
                        style={showMobileMenu3 ? appLayoutStyle.mobileHeadingBold : appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_3' })}
                        icon={showMobileMenu3 ? (<span style={appLayoutStyle.iconBackground}><Icon5Filled /></span>) : <span style={appLayoutStyle.iconPlainBackground}><Icon5 /></span>}
                      />
                    </Link>
                    <Link to="#" onClick={() => this.handleClickEvent('4')} sx={{'&:hover': {backgroundColor: 'transparent'}}}>
                      <BottomNavigationAction
                        showLabel
                        style={showMobileMenu4 ? appLayoutStyle.mobileHeadingBold : appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_4' })}
                        icon={showMobileMenu4 ? (<span style={appLayoutStyle.iconBackground}><ReadMoreIcon /></span>) : <span style={appLayoutStyle.iconPlainBackground}><ReadMoreIcon /></span>}
                      />
                    </Link>
                  </BottomNavigation>
                </Paper>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CssBaseline />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  rolesDetails: state.RolesDetails,
  farmList: state.farmList,
  masterData: state.masterData,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getFarmList: (lastaccessedFarm, version) => dispatch(fetchFarmList(lastaccessedFarm, version)),
  dataPermission: token => dispatch(dataValidationPermission(token)),
  getRolesDetails: token => dispatch(fetchRolePermistionList(token)),
  getMasterData: () => dispatch(fetchMasterData()),
  supportAdminPermission: () => dispatch(fetchSupportAdminPermission())
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout)));
