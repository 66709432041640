import * as React from 'react';

import SoilSamplesList from 'mui-mobile-components/fields/fieldtab/soilsamples/SoilSamplesList';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
 
class SoilSamples extends React.Component {
  render() {
    const { addNewSoilSample }= this.props;
    return (
      <SoilSamplesList addNewSoilSample={addNewSoilSample} />
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(SoilSamples)));