import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as Bale from 'actions/Bale';
import _ from 'lodash';

import AutoplacementGridSection from '@tine/lib-frontend-components/components/alignment/autoplacement-grid-section';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import SelectedStorageColumn from 'mui-components/storages/balestorage/storagecard/SelectedStorageColumn';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import AddBaleLink from 'mui-components/storages/balestorage/storagecard/AddBaleLink';
import BaleMap from 'mui-components/storages/balestorage/balestorage/BaleMap';
import EditBaleStorageMap from 'mui-components/storages/balestorage/balestorage/EditBaleStorageMap';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import { CrossIcon } from '@tine/lib-frontend-components/elements/icons';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import * as baleActions from 'actions/Bale';
import * as BaleAnalysisActions from 'actions/BaleAnalysis';
import mixpanel from 'mixpanel-browser';
import { ENTER_FIELD_VIEW, VIEW_ANALYSIS } from 'constants/MixPanel';
import Loader from 'components/Loader';

class SelectedBaleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      editStoragePlan: false,
      isShowFullScreen: false,
      selectedBale: false,
      selectedBaleList : null,
      setSelectedBaleList: false,
      selectedAnalysis: false,
      selectedAnalysisList : null,
      setSelectedAnalysisList: false,
      highlightHarvest: null,
      highlightStorage: null,
      highlightAnalysis: null,
      changedDrymatter: null,
      changedProtein: null,
      setSaveCoordinates: false
    };
    this.closeEditStoragePlan = this.closeEditStoragePlan.bind(this);
    this.onSelectedBale = this.onSelectedBale.bind(this);
    this.onSelectedAnalysis = this.onSelectedAnalysis.bind(this);
    this.onUpdateAnalysis = this.onUpdateAnalysis.bind(this);
  }

  componentDidMount = () => {    
    const { history, activeFarm, baleList, getBaleMarker, getAnalysisStorage, getFeedEventList } = this.props;
    const { paramId } = this.state;
    const currentYear = new Date().getFullYear();
    if (!activeFarm) {
      history.push('/settings');
    }
    getBaleMarker(activeFarm.farm.id, paramId);
    baleList(activeFarm.farm.id, currentYear);
    getAnalysisStorage(activeFarm.farm.id);
    getFeedEventList(activeFarm.farm.id);
    
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, baleList, getSelectedBaleList, getSelectedAnalysisList, getBaleMarker, getAnalysisStorage, getFeedEventList } = this.props;
    const { setSelectedBaleList, setSelectedAnalysisList, paramId } = this.state;
    const currentYear = new Date().getFullYear();
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storages');
      getBaleMarker(activeFarm.farm.id, paramId);
      baleList(activeFarm.farm.id, currentYear);
      getAnalysisStorage(activeFarm.farm.id);
      getFeedEventList(activeFarm.farm.id);
    }
    if(!setSelectedBaleList && getSelectedBaleList && getSelectedBaleList.getSelectedBaleData) {
      this.setState({
        selectedBaleList : getSelectedBaleList.getSelectedBaleData,
        setSelectedBaleList: true
      });
    }
    if(!setSelectedAnalysisList && getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      this.setState({
        selectedAnalysisList : getSelectedAnalysisList.getSelectedAnalysisData,
        setSelectedAnalysisList: true
      });
    }
  }

  setSavedCoordinates = () => {
    const { match, getBalesList } = this.props;
    const baleStorageId = match.params.id;

    if (baleStorageId !== 'create') {
      const { saveBaleMarkerCoordinates } = this.props;
      const storageItem = _.filter(getBalesList.data.storages, ['id', baleStorageId])[0];
      if (storageItem) {
        saveBaleMarkerCoordinates({
          latitude: storageItem.gpsPoint.latitude,
          longitude: storageItem.gpsPoint.longitude
        });
        this.setState({
          setSaveCoordinates: true
        });
      }
    }
  };

  onUpdateAnalysis = (selectedAnalysisId) => {
    const { activeFarm, getSelectedAnalysis, getSelectedAnalysisList } = this.props;
    if(getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      getSelectedAnalysisList.getSelectedAnalysisData = null;
    }
    this.setState({
      selectedAnalysisList: null,
      setSelectedAnalysisList: false
    });
    getSelectedAnalysis(activeFarm.farm.id, selectedAnalysisId);
  }

  onSelectedBale = (storageId, harvestId) => {
    const { activeFarm, getSelectedBale, getSelectedBaleList, getSelectedAnalysisList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ENTER_FIELD_VIEW);
    if(getSelectedBaleList && getSelectedBaleList.getSelectedBaleData) {
      getSelectedBaleList.getSelectedBaleData = null;
    }
    if(getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      getSelectedAnalysisList.getSelectedAnalysisData = null;
    }
    this.setState({
      selectedBaleList: null,
      setSelectedBaleList: false,
      selectedAnalysisList: null,
      setSelectedAnalysisList: false,
      highlightHarvest: null,
      highlightStorage: null,
      highlightAnalysis: null
    });
    getSelectedBale(activeFarm.farm.id, storageId, harvestId);
    this.setState({
      selectedBale: true,
      selectedAnalysis: false,
      highlightHarvest: harvestId,
      highlightStorage: storageId
    });
  }

  onSelectedAnalysis = (analysisId, index) => {
    const { activeFarm, getSelectedAnalysis, getSelectedAnalysisList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(VIEW_ANALYSIS);
    if(getSelectedAnalysisList && getSelectedAnalysisList.getSelectedAnalysisData) {
      getSelectedAnalysisList.getSelectedAnalysisData = null;
    }
    this.setState({
      selectedAnalysisList: null,
      setSelectedAnalysisList: false,
      highlightAnalysis: null
    });
    getSelectedAnalysis(activeFarm.farm.id, analysisId);
    this.setState({
      selectedAnalysis: true,
      highlightAnalysis: index
    });
  }

  openEditStoragePlan() {
    this.setSavedCoordinates();
    this.setState({
      editStoragePlan: true
    });
  }

  closeEditStoragePlan() {
    this.setState({
      editStoragePlan: false
    });
  }

   // To toggle full screen boundary drawing
   toggleScreen = () => {
    this.setState(prevState => ({
      isShowFullScreen: !prevState.isShowFullScreen
    }));
  };

  render() {
    const { bales, intl, getBalesList, getAnalysisStorageList, activeFarm, getFeedEventListData, hasSupportAdminRole } = this.props;
    const { paramId, editStoragePlan, isShowFullScreen, selectedBale, selectedBaleList, selectedAnalysis, 
      selectedAnalysisList, highlightStorage, highlightHarvest, highlightAnalysis } = this.state;
    const { getStorageLoading } = getAnalysisStorageList;
    let baleStorageList = null;
    let storageItem = null; 
    if(getBalesList && getBalesList.data) {
      storageItem = _.filter(getBalesList.data.storages, ['id', paramId])[0];
    }
    if(bales.data && bales.data.storages && (bales.data.storages).length > 0) {
      baleStorageList = _.filter(bales.data.storages, ['id', paramId]);
    }
    const enableCreateButton = 
      activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    return (
      <SectionContainer
        styling={{
          paddingTop: 'low'
        }}
      >
        {getStorageLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        <div className="stats-activity balesColumnFlexiGrid">
          <div className='text-header'>
            <span className='text-header-left'>
              <span className='breadCrumb-span'>
                <a href="#/storages">{intl.formatMessage({ id: 'BALE_STORAGE_HEADER' })}</a>
              </span>
              {baleStorageList && 
                <span className='breadCrumb-text'>
                  {baleStorageList && baleStorageList[0].name} ({baleStorageList && baleStorageList[0].quantity > 0 ? baleStorageList[0].quantity : 0} {intl.formatMessage({ id: 'BALE_OVERVIEW_TOTAL' })})
                </span>
              }
            </span>
            <span className='text-header-right'>
              <AddBaleLink 
                getAnalysisStorageListData = {getAnalysisStorageList.getStorageData}
              />
            </span>
          </div>
          <AutoplacementGridSection className="stats-activity__grid">
            {baleStorageList && 
            <div>
              <SelectedStorageColumn
                baleStorageList = {baleStorageList}
                column="bale"
                intl={intl}
                selectedBale = {selectedBale}
                onSelectedBale = {this.onSelectedBale}
                paramId = {paramId}
                selectedBaleList = {selectedBaleList}
                selectedAnalysis = {selectedAnalysis}
                onSelectedAnalysis = {this.onSelectedAnalysis}
                selectedAnalysisList = {selectedAnalysisList}
                highlightStorage = {highlightStorage}
                highlightHarvest = {highlightHarvest}
                highlightAnalysis = {highlightAnalysis}
              />
              {storageItem.id !== "all-fields" && <FlexGridItem>
                <div className={`storage-box storage-box--users`}>
                  <div className="storage-box__title">
                    <div className="floatLeft">
                      <Emphasis size="normal">{intl.formatMessage({ id: 'SELECTED_STORAGE_MAP_HEADING' })}</Emphasis>
                    </div>
                    {enableCreateButton && <div className="floatRight" onClick={() => { this.openEditStoragePlan(); }}>
                      <span className="pad-right bale-edit-link">{intl.formatMessage({ id: 'SELECTED_STORAGE_MAP_EDIT' })}</span>
                    </div>}
                  </div>
                  <div className="vehicle-list-flex-container full-width">
                    <div className="storage-list-details">
                      <ul className="storage-list-wrapper">
                        <li className={`storage-list-item full-width`}>
                          <div className={`storage-list-item-div`}>
                            <div className="bale-area">
                              {!isShowFullScreen && storageItem &&
                              <BaleMap
                                title="Full screen"
                                mapId="off-screen-map-bale-view"
                                paramId="edit"
                                mapData={storageItem}
                                toggleScreen={() => this.toggleScreen()}
                              />}
                            </div>
                          </div>
                        </li>
                        <li className={`storage-list-item full-width`}>
                          <div className={`storage-list-item-div border-bottom-style`}>
                            {storageItem.note}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </FlexGridItem>}
            </div>}
            {baleStorageList && 
            <div>
              <SelectedStorageColumn
                baleStorageList = {baleStorageList}
                column="selectedbale"
                intl={intl}
                selectedBale = {selectedBale}
                onSelectedBale = {this.onSelectedBale}
                paramId = {paramId}
                selectedBaleList = {selectedBaleList}
                selectedAnalysis = {selectedAnalysis}
                onSelectedAnalysis = {this.onSelectedAnalysis}
                selectedAnalysisList = {selectedAnalysisList}
                highlightStorage = {highlightStorage}
                highlightHarvest = {highlightHarvest}
                highlightAnalysis = {highlightAnalysis}
              />
            </div>}
            {baleStorageList && getFeedEventListData &&
            <div>
              <SelectedStorageColumn
                baleStorageList = {baleStorageList}
                column="selectedanalysis"
                intl={intl}
                selectedBale = {selectedBale}
                onSelectedBale = {this.onSelectedBale}
                paramId = {paramId}
                selectedBaleList = {selectedBaleList}
                selectedAnalysis = {selectedAnalysis}
                onSelectedAnalysis = {this.onSelectedAnalysis}
                selectedAnalysisList = {selectedAnalysisList}
                getFeedEventListData = {getFeedEventListData}
                getAnalysisStorageListData = {getAnalysisStorageList.getStorageData}
                onUpdateAnalysis = {this.onUpdateAnalysis}
              />
            </div>}            
          </AutoplacementGridSection>          
          {getBalesList.data && storageItem.id !== "all-fields" && <ModalDialog
            minWidth={{mobile: '90%', tablet: '70%', web: '70%'}}
            width={{mobile: '90%', tablet: '70%', web: '70%'}}
            height={{mobile: '90%', tablet: '65%', web: '80%'}}
            show={editStoragePlan}
            closeIcon={
              <span className="close-modal">
                <CrossIcon scale={0.5} />
              </span>
            }
            onClose={() => this.closeEditStoragePlan()}
          >
          <EditBaleStorageMap
            baleStorageList={baleStorageList}
            getBalesListData={getBalesList.data}
            editStoragePlan={this.state.editStoragePlan}
          />
        </ModalDialog>}
        {isShowFullScreen && (
          <ModalDialog
            show={isShowFullScreen}
            onClose={() => this.toggleScreen()}
            width={{mobile: '95%', tablet: '95%', web: '95%'}}
            height={{mobile: '95%', tablet: '95%', web: '95%'}}
            closeIcon={
              <span className="close-modal">
                <CrossIcon scale={0.5} />
              </span>
            }
          >
            <BaleMap
              title="close full screen"
              mapId="off-screen-map-bale-view"
              paramId="edit"
              mapData={storageItem}
              fullScreen
              toggleScreen={this.toggleScreen}
            />
          </ModalDialog>
        )}
        </div>
      </SectionContainer>      
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  bales: state.bales,
  getBalesList: state.bales,
  getAnalysisStorageList: state.baleAnalysis,
  getSelectedBaleList: state.baleAnalysis,
  getSelectedAnalysisList: state.baleAnalysis,
  getFeedEventListData: state.baleFeedEvent,
  userDetails: state.userDetails.data,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  baleList: (farmId, year) => dispatch(Bale.getBaleList(farmId, year)),
  getBaleMarker: (farmId, baleStorageId) => dispatch(baleActions.getBaleMarker(farmId, baleStorageId)),
  getAnalysisStorage: (farmId) => dispatch(BaleAnalysisActions.getAllStorages(farmId)),
  getSelectedBale: (farmId, storageId, harvest) => dispatch(BaleAnalysisActions.getSelectedBale(farmId, storageId, harvest)),
  getSelectedAnalysis: (farmId, analysisId) => dispatch(BaleAnalysisActions.getSelectedAnalysis(farmId, analysisId)),
  getFeedEventList: (farmId) => dispatch(baleFeedEventActions.getFeedEventList(farmId)),
  saveBaleMarkerCoordinates: obj => dispatch(baleActions.saveBaleMarkerCoordinates(obj))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectedBaleList)));
