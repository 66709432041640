import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as farmActions from 'actions/Farms';
import { DELETE_FARM, OWNER } from 'constants/index';
import { checkPermissions } from 'utils/index';
import FarmDetails from 'mui-mobile-components/settings/farms/FarmDetails';
import { Dialog, DialogTitle, Chip, Box } from '@mui/material';
import { buttonStyle, buttonRedStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';
import mixpanel from 'mixpanel-browser';
import { CANCEL_FARM_SUBSCRIPTION } from 'constants/MixPanel';

const dialogTitleDialogStyle = { color: '#191C1C', fontFamily: 'Inter', fontSize: '24px', fontStyle: 'normal', fontWeight: '400', lineHeight: '32px' }
const dialogSubTitleStyle = { color: '#3F4949', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '20px', letterSpacing: '0.25px', padding: '10px 24px' }

class FarmSettings extends React.Component {
  state = {
    show: false,
    coOrds: [],
    mapError: false
  };

  componentDidMount() {
    this.clearFarmMsg();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location !== location) {
      this.clearFarmMsg();
    }
  }

  clearFarmMsg = () => {
    const { clearMessages } = this.props;
    clearMessages();
  };

  // callback for getting marker co-ordinates
  getMarkerCoords = (lat, lng) => {
    // if farm wasn't chosen or deleted
    if (lat === null && lng === null) {
      this.setState({ coOrds: null, mapError: true });
    } else {
      this.setState({ coOrds: [{ lattitude: lat, longitude: lng }], mapError: false });
    }
  };

  cancelDialog = () => {
    this.setState({ show: false });
  };

  deleteDialog = () => {
    const { deleteFarm, activeFarm, userInfo } = this.props;
    mixpanel.identify(userInfo.id);
    mixpanel.track(CANCEL_FARM_SUBSCRIPTION);
    deleteFarm(activeFarm.farm.id);
    this.setState({ show: false });
  };

  // to delete farm
  deletingFarm = () => {
    const { activeFarm, hasSupportAdminRole } = this.props;
    if (activeFarm.role ? activeFarm.role?.id === OWNER : hasSupportAdminRole.data) {
      this.setState({ show: true });
    }
    return false;
  };

  render() {
    const { intl, rolePermissions, activeFarm, showAddFarmDialog, onOpenFarmDialog, onCloseFarmDialog, addNewFarm, addNewFarmDialog } = this.props;
    const { show, coOrds, mapError } = this.state;
    let disableDelete = false;
    let isAddFarm = addNewFarm;
    if (activeFarm) {
      disableDelete = checkPermissions(DELETE_FARM, activeFarm, rolePermissions);
    }
    return (
      <div className="add-farm-map-wrapper">
        <div className="add-farm-details-map-holder full-width">
          <FarmDetails
            coOrds={coOrds}
            mapError={mapError}
            getCoordinates={this.getMarkerCoords}
            isAddFarmPage={isAddFarm}
            disableDelete={disableDelete}
            deleteFarm={this.deletingFarm}
            showAddFarmDialog={showAddFarmDialog}
            onOpenFarmDialog={onOpenFarmDialog}
            onCloseFarmDialog={onCloseFarmDialog}
            addNewFarmDialog={addNewFarmDialog}
          />
        </div>
        {show && (
          <Dialog open={show} onClose={() => {}} PaperProps={{
            style: {
              backgroundColor: '#E6E9E8',
              borderRadius: "28px",
              width: "320px",
              minWidth: "200px",
              maxWidth: "320px",
            }}}>
            <DialogTitle style={dialogTitleDialogStyle}>
              {intl.formatMessage({ id: 'AVSLUTT_ABONNEMENT' })}
            </DialogTitle>
            <Box component="div" style={dialogSubTitleStyle}>
              {intl.formatMessage({ id: 'AVSLUTT_ABONNEMENT_CONTENT' })}
            </Box>
            <div style={{padding: "15px", textAlign: "right"}}>
              <span>
                <Chip
                  label={intl.formatMessage({ id: 'CANCEL' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  disableRipple
                  onClick={() => this.cancelDialog()}
                />
                <Chip
                  label={intl.formatMessage({ id: 'AVSLUTT_ABONNEMENT' })}
                  clickable
                  style={buttonRedStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  disableRipple
                  onClick={() => this.deleteDialog()}
                />
              </span>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fieldDetails: state.fieldDetails,
  userInfo: state.userDetails,
  geoLocDetails: state.geoLocDetails.data,
  activeFarm: state.farmList.activeFarm,
  rolePermissions: state.RolesDetails,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDisptachToActions(dispatch) {
  return {
    deleteFarm: id => dispatch(farmActions.deleteFarm(id)),
    clearMessages: () => dispatch(farmActions.clearMessages())
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDisptachToActions)(FarmSettings)));
