import * as React from 'react';
import { Dialog, DialogTitle, Chip, Box, Stack } from '@mui/material';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { buttonStyle, buttonFilledStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';
import { getDropDownValuesMineralFertilizers } from 'utils/index';

const dialogTitleStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "24px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px" };

const ModalWindow = ({ intl, openDialogWindow, closeDialogWindow, disableCreateButton, mineralFertilizer, selectedNameMethod,
  selectedWeightMethod, selectedStorageMethod, handleSubmit, hasError, errorMsg, mineralList, openDialogWindowEdit, closeDialogWindowEdit,
  disableCreateButtonEdit, selectedNameMethodEdit, selectedWeightMethodEdit, selectedStorageMethodEdit, handleSubmitEdit }) => (
  <div>
    <Dialog onClose={() => {}} open={openDialogWindow} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'create' })}</DialogTitle>
      <Box style={inputStyle}>
        <Stack className='text-with-padding'>
          <MuiSelectBox 
            name="type"
            id="type"
            label={intl.formatMessage({ id: 'TYPE' })}
            variant="outlined"
            required={true}
            options={mineralFertilizer && mineralFertilizer.mineralFertilizerList && getDropDownValuesMineralFertilizers(mineralFertilizer.mineralFertilizerList)}
            onChange={selectedNameMethod}
          />
        </Stack>
        <Stack className='text-with-padding'>
          <MuiTextBox 
            name="storage"
            id="storage"
            label={intl.formatMessage({ id: 'STORAGE_BAGS_NAME' })}
            variant="outlined"
            required={true}
            showFullWidth="true"
            onChange={selectedStorageMethod}
          />
        </Stack>
        <Stack className='text-with-padding'>
          <MuiTextBox 
            name="weight"
            id="weight"
            label={intl.formatMessage({ id: 'STORAGE_IN_KG_NAME' })}
            variant="outlined"
            showFullWidth="true"
            onChange={selectedWeightMethod}
          />
        </Stack>
      </Box>
      {hasError && 
      <div style={{ fontFamily: 'Inter', color: '#CC0000', paddingLeft: '30px', paddingRight: '30px' }}>
        {errorMsg}
      </div>}
      <div style={{padding: "30px 24px", textAlign: "right"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={closeDialogWindow}
          />
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={buttonFilledStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={disableCreateButton}
            onClick={handleSubmit}
          />
        </span>
      </div>
    </Dialog>
    <Dialog onClose={() => {}} open={openDialogWindowEdit} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }}}>
      <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'update' })}</DialogTitle>
      <Box style={inputStyle}>
        <Stack className='text-with-padding'>
          <MuiSelectBox 
            name="type"
            id="type"
            label={intl.formatMessage({ id: 'TYPE' })}
            variant="outlined"
            required={true}
            options={mineralFertilizer && mineralFertilizer.mineralFertilizerList && getDropDownValuesMineralFertilizers(mineralFertilizer.mineralFertilizerList)}
            onChange={selectedNameMethodEdit}
            defaultValue={mineralList && mineralList.name}
          />
        </Stack>
        <Stack className='text-with-padding'>
          <MuiTextBox 
            name="storage"
            id="storage"
            label={intl.formatMessage({ id: 'STORAGE_BAGS_NAME' })}
            variant="outlined"
            required={true}
            showFullWidth="true"
            onChange={selectedStorageMethodEdit}
            defaultValue={mineralList && mineralList.storageBags}
          />
        </Stack>
        <Stack className='text-with-padding'>
          <MuiTextBox 
            name="weight"
            id="weight"
            label={intl.formatMessage({ id: 'STORAGE_IN_KG_NAME' })}
            variant="outlined"
            showFullWidth="true"
            onChange={selectedWeightMethodEdit}
            defaultValue={mineralList && mineralList.weight}
          />
        </Stack>
      </Box>
      {hasError && 
      <div style={{ fontFamily: 'Inter', color: '#CC0000', paddingLeft: '30px', paddingRight: '30px' }}>
        {errorMsg}
      </div>}
      <div style={{padding: "30px 24px", textAlign: "right"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={closeDialogWindowEdit}
          />
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={buttonFilledStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={disableCreateButtonEdit}
            onClick={() => handleSubmitEdit(mineralList)}
          />
        </span>
      </div>
    </Dialog>
  </div>
);

export default ModalWindow;