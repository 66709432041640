import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import FertilizerCreateModal from 'mui-mobile-components/fields/fertilization/FertilizerCreateModal';
import { OWNER, ADMIN, ADVISOR } from 'constants/index';
import * as FertilizerAction from 'actions/FertilizationPlan';
import mixpanel from 'mixpanel-browser';
import { START_NEW_PLAN, CREATE_NEW_PLAN } from 'constants/MixPanel';
import { Stack, Chip, Grid, Box } from '@mui/material'
import { PlusIcon } from 'components/mui-forms/MuiIcons';

const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
const paddingRight = { paddingRight: '10px' }

class CreateFertilizationPlan extends React.Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      createFertilizer: false,
      createNewFertilizer: false,
      createValue: false,
      formData: null,
      confirmDialog: false,
      confirmErrorMessage: null,
      keepExisting: false,
      mineralCalculationOn: false,
      disablePlanCreateButton: true,
      planNameCreate: 2,
      planYearCreate: null,
      showErrorMessageDialog: false,
      selectedOptions: []
    };
    this.createFertilizerPlan = this.createFertilizerPlan.bind(this);
    this.closeFertilizerPlan = this.closeFertilizerPlan.bind(this);
    this.onChangeTextBox = this.onChangeTextBox.bind(this);
    this.handleSubmitModel = this.handleSubmitModel.bind(this);
    this.keepExistingPlanModel = this.keepExistingPlanModel.bind(this);
    this.discardExistingPlanModel = this.discardExistingPlanModel.bind(this);
    this.toggleMineralCalculationOn=this.toggleMineralCalculationOn.bind(this);
    this.onChangePlanName=this.onChangePlanName.bind(this);
    this.onChangePlanYear=this.onChangePlanYear.bind(this);
    this.handleOnChangeMf=this.handleOnChangeMf.bind(this);
  }

  getInitialize = () => {
    const { initialize } = this.props;
    const formValues = {
      planName: '',
      year: []
    };
    initialize(formValues);
  };

  componentDidMount = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.saveSuccess = false;
    this.setState({
      keepExisting: false
    });
    this.getInitialize();
  };

  componentDidUpdate(prevProps) {
    const { confirmDialog, mineralCalculationOn, selectedOptions, planNameCreate, planYearCreate } = this.state;
    const {
      activeFarm,
      fertilizationPlan,
      createFertilizationPlan
    } = this.props;
    const {
      fertilizerPlanAvailable,
      createData,
      hasError,
      errorMessage
    } = fertilizationPlan;
    if (!hasError && fertilizerPlanAvailable && createData === 1) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: true,
        confirmDialog: false,
        showErrorMessageDialog: false
      });
    }
    if (!hasError && fertilizerPlanAvailable && createData === 0) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        mineralCalculationOn: false,
        showErrorMessageDialog: false
      });
      let formDataObject = {};
      if(selectedOptions.length > 0) {
        formDataObject = {
          planName: planNameCreate,
          year: planYearCreate,
          mineralization: mineralCalculationOn,
          mfs: selectedOptions
        };
      } else {
        formDataObject = {
          planName: planNameCreate,
          year: planYearCreate,
          mineralization: mineralCalculationOn
        };
      }
      createFertilizationPlan(activeFarm.farm.id, '', formDataObject);
    }
    if (!hasError && fertilizerPlanAvailable && createData === 2) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        showErrorMessageDialog: true
      });
      this.getInitialize();
    }
    if (!confirmDialog && hasError && errorMessage?.status === 409) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      fertilizationPlan.hasError = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: true,
        confirmErrorMessage: errorMessage?.data,
        showErrorMessageDialog: false
      });
    }
  }

  createFertilizerPlan() {
    const { userDetails } = this.props;
    this.setState({
      createFertilizer: true,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false,
      selectedOptions: []
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(START_NEW_PLAN);
  }

  closeFertilizerPlan() {
    this.setState({
      createFertilizer: false,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false,
      showErrorMessageDialog: false
    });
    this.getInitialize();
  }

  handleSubmit = () => {
    const { activeFarm, intl, checkFertilizationPlan, hasSupportAdminRole } = this.props;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    if (hasPermission) {
      checkFertilizationPlan(activeFarm.farm.id, this.state.planYearCreate);
    } else {
      intl.formatMessage({ id: 'MASTER_ASSET_PERMISSION' });
    }
  };

  handleSubmitModel() {
    const { createFertilizationPlan, activeFarm, userDetails } = this.props;
    const { planNameCreate, planYearCreate, mineralCalculationOn, selectedOptions } = this.state;
    let formDataObject = {};
    formDataObject["planName"] = planNameCreate;
    formDataObject["year"] = planYearCreate;
    formDataObject["mineralization"] = mineralCalculationOn;
    if(selectedOptions.length > 0) {
      formDataObject["mfs"] = selectedOptions;
    }
    mixpanel.identify(userDetails.id);
    mixpanel.track(CREATE_NEW_PLAN);
    createFertilizationPlan(activeFarm.farm.id, '', formDataObject);
    this.setState({
      createFertilizer: false,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false,
      showErrorMessageDialog: false
    });
  }

  keepExistingPlanModel() {
    const { createFertilizationPlan, activeFarm, userDetails } = this.props;
    const { planNameCreate, planYearCreate, mineralCalculationOn, selectedOptions } = this.state;
    let formDataObject = {};
    if(selectedOptions.length > 0) {
      formDataObject = {
        planName: planNameCreate,
        year: planYearCreate,
        mineralization: mineralCalculationOn,
        mfs: selectedOptions
      };
    } else {
      formDataObject = {
        planName: planNameCreate,
        year: planYearCreate,
        mineralization: mineralCalculationOn
      };
    }
    this.setState({
      keepExisting: true
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(CREATE_NEW_PLAN);
    createFertilizationPlan(activeFarm.farm.id, 'keep', formDataObject);
    this.setState({
      createFertilizer: false,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false,
      showErrorMessageDialog: false
    });
  }

  toggleMineralCalculationOn() {
    const { mineralCalculationOn } = this.state;
    this.setState({
      mineralCalculationOn: !mineralCalculationOn
    });
  }

  discardExistingPlanModel() {
    const { createFertilizationPlan, activeFarm } = this.props;
    const { planNameCreate, planYearCreate, mineralCalculationOn, selectedOptions } = this.state;
    let formDataObject = {};
    if(selectedOptions.length > 0) {
      formDataObject = {
        planName: planNameCreate,
        year: planYearCreate,
        mineralization: mineralCalculationOn,
        mfs: selectedOptions
      };
    } else {
      formDataObject = {
        planName: planNameCreate,
        year: planYearCreate,
        mineralization: mineralCalculationOn
      };
    }
    createFertilizationPlan(activeFarm.farm.id, 'delete', formDataObject);
    this.setState({
      createFertilizer: false,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false,
      showErrorMessageDialog: false
    });
  }

  onChangeTextBox = event => {
    if (event.target.value.toLowerCase() === 'create' || event.target.value.toLowerCase() === 'opprett') {
      this.setState({
        createValue: true
      });
    } else {
      this.setState({
        createValue: false
      });
    }
  };

  onChangePlanName = (event) => {
    const planName = event.target.value;
    this.setState({
      planNameCreate: planName
    })
    if(this.state.planYearCreate !== "" && this.state.planYearCreate !== null && event.target.value !== "" && event.target.value !== null) {
      this.setState({
        disablePlanCreateButton: false
      });
    } else {
      this.setState({
        disablePlanCreateButton: true
      });
    }
  }

  onChangePlanYear = (event) => {
    this.setState({
      planYearCreate: event.target.value
    })
    if(this.state.planNameCreate !== "" && this.state.planNameCreate !== null && event.target.value !== "" && event.target.value !== null) {
      this.setState({
        disablePlanCreateButton: false
      })
    } else {
      this.setState({
        disablePlanCreateButton: true
      });
    }
  }

  handleOnChangeMf = (event, newValue) => {
    this.setState({ selectedOptions: newValue });
  };

  render() {
    const { activeFarm, intl, hasSupportAdminRole, mineralFertilizer, handleSubmit } = this.props;
    const { createFertilizer, createNewFertilizer, confirmDialog, createValue, confirmErrorMessage, mineralCalculationOn } = this.state;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    return hasPermission && (
        <Grid item md={2} className='grid-padding-top' sx={{float:'right'}}>
          <Stack direction="row" justifyContent="end" style={paddingRight}>
            <Box Component='span'>
              <Chip
                icon={<PlusIcon />}
                label={intl.formatMessage({ id: 'FIELD_FERTILIZATION_CREATE' })}
                color="primary"
                variant="outlined"
                clickable
                style={buttonStyle}
                onClick={this.createFertilizerPlan}
              />
            </Box>
          </Stack>
          <FertilizerCreateModal
            onCloseFertilizerPlan={this.closeFertilizerPlan}
            onConfirmDialog={confirmDialog}
            intl={intl}
            onChangePlanName={this.onChangePlanName}
            onChangePlanYear={this.onChangePlanYear}
            disablePlanCreateButton={this.state.disablePlanCreateButton}
            mineralFertilizer={mineralFertilizer}
            handleSubmitModel={this.handleSubmitModel}
            handleOnChangeMf={this.handleOnChangeMf}
            selectedOptions={this.state.selectedOptions}
            handleSubmit={handleSubmit(this.handleSubmit)}            
            onCreateNewFertilizer={createNewFertilizer}
            onCreateFertilizer={createFertilizer}
            mineralCalculationOn={mineralCalculationOn}
            toggleMineralCalculationOn={this.toggleMineralCalculationOn}
            onChangeTextBox={this.onChangeTextBox}           
            keepExistingPlanModel={this.keepExistingPlanModel}
            discardExistingPlanModel={this.discardExistingPlanModel}
            onCreateValue={createValue}
            confirmErrorMessage={confirmErrorMessage}
            showErrorMessageDialog={this.state.showErrorMessageDialog}
          />
        </Grid>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fertilizationPlan: state.fertilizationPlan,
  userDetails: state.userDetails.data,
  hasSupportAdminRole: state.SupportAdminRole,
  mineralFertilizer: state.mineralFertilizer,
});

function mapDispatchToProps(dispatch) {
  return {
    checkFertilizationPlan: (farmId, year) => dispatch(FertilizerAction.checkFertilizationPlanAvailability(farmId, year)),
    createFertilizationPlan: (farmId, deleteDraftPlan, data) => dispatch(FertilizerAction.createFertilizationPlan(farmId, deleteDraftPlan, data)),
  };
}

export default injectIntl(
  reduxForm({
    form: 'CreateFertilizationPlan'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateFertilizationPlan)))
);
