import axios from 'axios';
import { API_URI, REFRESH_TOKEN } from 'constants/index';
import { GetSessionData, SetSessionData } from 'utils';
import { getNewLoginToken } from 'actions/Login';

const apiRequest = options => {
  const onSuccess = response => {
    return response;
  };
  
  const onError = error => {
    return Promise.reject(error.response || error.message);
  };
  
  return async (dispatch, getState) => {
    try {
      let userDetailsData = getState().userDetails?.data;
      if (userDetailsData) {        
        // Check if session is expired
        if (userDetailsData.sessionTimeOut) {          
          if (
            (new Date().toISOString() > userDetailsData.sessionTimeOut &&
              (GetSessionData(REFRESH_TOKEN) === false || GetSessionData(REFRESH_TOKEN) === null))
          ) {
            SetSessionData(REFRESH_TOKEN, true);            
            // Await for the token refresh to complete
            await dispatch(getNewLoginToken(getState().userDetails?.data));
          }
        }
      }
      
      const { idToken } = getState().userDetails?.data;

      // Proceed with the API request once the token is refreshed
      return axios({
        baseURL: API_URI,
        ...options,
        headers: {
          Authorization: idToken,
          ...options.headers
        }
      })
        .then(onSuccess)
        .catch(onError);
    } catch (error) {
      console.error('Error in API request: ', error);
      return error.message;
    }
  };
};

export default apiRequest;
