import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import FertilizerCreateNewModal from 'mui-mobile-components/fields/fertilization/FertilizerCreateNewModal';
import { buttonRoundStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';
import { OWNER, ADMIN, ADVISOR } from 'constants/index';
import * as FertilizerAction from 'actions/FertilizationPlan';
import mixpanel from 'mixpanel-browser';
import { START_NEW_PLAN, CREATE_NEW_PLAN } from 'constants/MixPanel';
import { Stack, Grid, Chip } from '@mui/material'
import { PlusIcon } from 'components/mui-forms/MuiIcons';

class CreateFertilizationPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createFertilizer1: false,
      createNewFertilizer1: false,
      createValue1: false,
      formData1: null,
      confirmDialog1: false,
      confirmErrorMessage1: null,
      keepExisting1: false,
      mineralCalculationOn1: false,
      disablePlanCreateButton1: true,
      planNameCreate1: null,
      planYearCreate1: null,
      showErrorMessageDialog1: false,
      selectedOptions1: []
    };
    this.createFertilizerPlan = this.createFertilizerPlan.bind(this);
    this.closeFertilizerPlan = this.closeFertilizerPlan.bind(this);
    this.onChangeTextBox = this.onChangeTextBox.bind(this);
    this.handleSubmitModel = this.handleSubmitModel.bind(this);
    this.keepExistingPlanModel = this.keepExistingPlanModel.bind(this);
    this.discardExistingPlanModel = this.discardExistingPlanModel.bind(this);
    this.toggleMineralCalculationOn = this.toggleMineralCalculationOn.bind(this);
    this.onChangePlanName = this.onChangePlanName.bind(this);
    this.onChangePlanYear = this.onChangePlanYear.bind(this);
    this.handleOnChangeMf = this.handleOnChangeMf.bind(this);
  }

  getInitialize = () => {
    const { initialize } = this.props;
    const formValues = {
      planName: '',
      year: []
    };
    initialize(formValues);
  };

  componentDidMount = () => {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.saveSuccess = false;
    this.setState({
      keepExisting1: false
    });
    this.getInitialize();
  };

  componentDidUpdate(prevProps) {
    const { confirmDialog1, mineralCalculationOn1, selectedOptions1, planNameCreate1, planYearCreate1 } = this.state;
    const {
      activeFarm,
      fertilizationPlan,
      createFertilizationPlan
    } = this.props;
    const {
      fertilizerPlanAvailable,
      createData,
      hasError,
      errorMessage
    } = fertilizationPlan;
    if (!hasError && fertilizerPlanAvailable && createData === 1) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer1: false,
        createNewFertilizer1: true,
        confirmDialog1: false,
        showErrorMessageDialog1: false
      });
    }
    if (!hasError && fertilizerPlanAvailable && createData === 0) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer1: false,
        createNewFertilizer1: false,
        confirmDialog1: false,
        mineralCalculationOn1: false,
        showErrorMessageDialog1: false
      });
      let formDataObject = {};
      if(selectedOptions1.length > 0) {
        formDataObject = {
          planName: planNameCreate1,
          year: planYearCreate1,
          mineralization: mineralCalculationOn1,
          mfs: selectedOptions1
        };
      } else {
        formDataObject = {
          planName: planNameCreate1,
          year: planYearCreate1,
          mineralization: mineralCalculationOn1
        };
      }
      createFertilizationPlan(activeFarm.farm.id, '', formDataObject);
    }
    if (!hasError && fertilizerPlanAvailable && createData === 2) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer1: false,
        createNewFertilizer1: false,
        confirmDialog1: false,
        showErrorMessageDialog1: true
      });
      this.getInitialize();
    }
    if (!confirmDialog1 && hasError && errorMessage?.status === 409) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer1: false,
        createNewFertilizer1: false,
        confirmDialog1: true,
        confirmErrorMessage1: errorMessage?.data,
        showErrorMessageDialog1: false
      });
    }
  }

  createFertilizerPlan() {
    const { userDetails } = this.props;
    this.setState({
      createFertilizer1: true,
      createNewFertilizer1: false,
      confirmDialog1: false,
      mineralCalculationOn1: false,
      selectedOptions1: []
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(START_NEW_PLAN);
  }

  closeFertilizerPlan() {
    this.setState({
      createFertilizer1: false,
      createNewFertilizer1: false,
      confirmDialog1: false,
      mineralCalculationOn1: false,
      showErrorMessageDialog1: false
    });
    this.getInitialize();
  }

  handleSubmit = () => {
    const { activeFarm, intl, checkFertilizationPlan, hasSupportAdminRole } = this.props;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    if (hasPermission) {
      checkFertilizationPlan(activeFarm.farm.id, this.state.planYearCreate1);
    } else {
      intl.formatMessage({ id: 'MASTER_ASSET_PERMISSION' });
    }
  };

  handleSubmitModel() {
    const { createFertilizationPlan, activeFarm, userDetails } = this.props;
    const { planNameCreate1, planYearCreate1, mineralCalculationOn1, selectedOptions1 } = this.state;
    let formDataObject = {};
    formDataObject["planName"] = planNameCreate1;
    formDataObject["year"] = planYearCreate1;
    formDataObject["mineralization"] = mineralCalculationOn1;
    if(selectedOptions1.length > 0) {
      formDataObject["mfs"] = selectedOptions1;
    }
    mixpanel.identify(userDetails.id);
    mixpanel.track(CREATE_NEW_PLAN);
    createFertilizationPlan(activeFarm.farm.id, '', formDataObject);
    this.setState({
      createFertilizer1: false,
      createNewFertilizer1: false,
      confirmDialog1: false,
      mineralCalculationOn1: false,
      showErrorMessageDialog1: false
    });
  }

  keepExistingPlanModel() {
    const { createFertilizationPlan, activeFarm, userDetails } = this.props;
    const { planNameCreate1, planYearCreate1, mineralCalculationOn1, selectedOptions1 } = this.state;
    let formDataObject = {};
    if(selectedOptions1.length > 0) {
      formDataObject = {
        planName: planNameCreate1,
        year: planYearCreate1,
        mineralization: mineralCalculationOn1,
        mfs: selectedOptions1
      };
    } else {
      formDataObject = {
        planName: planNameCreate1,
        year: planYearCreate1,
        mineralization: mineralCalculationOn1
      };
    }
    this.setState({
      keepExisting1: true
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(CREATE_NEW_PLAN);
    createFertilizationPlan(activeFarm.farm.id, 'keep', formDataObject);
    this.setState({
      createFertilizer1: false,
      createNewFertilizer1: false,
      confirmDialog1: false,
      mineralCalculationOn1: false,
      showErrorMessageDialog1: false
    });
  }

  toggleMineralCalculationOn() {
    const { mineralCalculationOn1 } = this.state;
    this.setState({
      mineralCalculationOn1: !mineralCalculationOn1
    });
  }

  discardExistingPlanModel() {
    const { createFertilizationPlan, activeFarm } = this.props;
    const { planNameCreate1, planYearCreate1, mineralCalculationOn1, selectedOptions1 } = this.state;
    let formDataObject = {};
    if(selectedOptions1.length > 0) {
      formDataObject = {
        planName: planNameCreate1,
        year: planYearCreate1,
        mineralization: mineralCalculationOn1,
        mfs: selectedOptions1
      };
    } else {
      formDataObject = {
        planName: planNameCreate1,
        year: planYearCreate1,
        mineralization: mineralCalculationOn1
      };
    }
    createFertilizationPlan(activeFarm.farm.id, 'delete', formDataObject);
    this.setState({
      createFertilizer1: false,
      createNewFertilizer1: false,
      confirmDialog1: false,
      mineralCalculationOn1: false,
      showErrorMessageDialog1: false
    });
  }

  onChangeTextBox = event => {
    if (event.target.value.toLowerCase() === 'create' || event.target.value.toLowerCase() === 'opprett') {
      this.setState({
        createValue1: true
      });
    } else {
      this.setState({
        createValue1: false
      });
    }
  };

  onChangePlanName = (event) => {
    const planName = event.target.value;
    this.setState({
      planNameCreate1: planName
    })
    if(this.state.planYearCreate1 !== "" && this.state.planYearCreate1 !== null && event.target.value !== "" && event.target.value !== null) {
      this.setState({
        disablePlanCreateButton1: false
      });
    } else {
      this.setState({
        disablePlanCreateButton1: true
      });
    }
  }

  onChangePlanYear = (event) => {
    this.setState({
      planYearCreate1: event.target.value
    })
    if(this.state.planNameCreate1 !== "" && this.state.planNameCreate1 !== null && event.target.value !== "" && event.target.value !== null) {
      this.setState({
        disablePlanCreateButton1: false
      })
    } else {
      this.setState({
        disablePlanCreateButton1: true
      });
    }
  }

  handleOnChangeMf = (event, newValue) => {
    this.setState({ selectedOptions1: newValue });
  };

  render() {
    const { activeFarm, intl, hasSupportAdminRole, mineralFertilizer, handleSubmit } = this.props;
    const { createFertilizer1, createNewFertilizer1, confirmDialog1, createValue1, confirmErrorMessage1, mineralCalculationOn1 } = this.state;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    /*const customButtonStyle = {
      backgroundColor: '#00696D', color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00696D'
      }, borderRadius: '100px', paddingLeft: 20, paddingRight: 20, textTransform: 'none', fontSize: '14px', fontFamily: 'Inter'
    };*/
    return hasPermission && (
        <Grid item className='grid-padding-top' sx={{ textAlign: 'center', display: '-webkit-inline-box' }}>
          <Stack direction="row" justifyContent="center">
            <Chip
              icon={<PlusIcon />}
              label={intl.formatMessage({ id: 'FIELD_FERTILIZATION_CREATE' })}
              color="primary"
              variant="outlined"
              clickable
              onClick={this.createFertilizerPlan}
              style={buttonRoundStyle}
            />
          </Stack>
          <FertilizerCreateNewModal
            onCloseFertilizerPlan={this.closeFertilizerPlan}
            onConfirmDialog={confirmDialog1}
            intl={intl}
            onChangePlanName={this.onChangePlanName}
            onChangePlanYear={this.onChangePlanYear}
            disablePlanCreateButton={this.state.disablePlanCreateButton1}
            mineralFertilizer={mineralFertilizer}
            handleSubmitModel={this.handleSubmitModel}
            handleOnChangeMf={this.handleOnChangeMf}
            selectedOptions={this.state.selectedOptions1}
            handleSubmit={handleSubmit(this.handleSubmit)}            
            onCreateNewFertilizer={createNewFertilizer1}
            onCreateFertilizer={createFertilizer1}
            mineralCalculationOn={mineralCalculationOn1}
            toggleMineralCalculationOn={this.toggleMineralCalculationOn}
            onChangeTextBox={this.onChangeTextBox}           
            keepExistingPlanModel={this.keepExistingPlanModel}
            discardExistingPlanModel={this.discardExistingPlanModel}
            onCreateValue={createValue1}
            confirmErrorMessage={confirmErrorMessage1}
            showErrorMessageDialog={this.state.showErrorMessageDialog1}
          />
        </Grid>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fertilizationPlan: state.fertilizationPlan,
  userDetails: state.userDetails.data,
  hasSupportAdminRole: state.SupportAdminRole,
  mineralFertilizer: state.mineralFertilizer,
});

function mapDispatchToProps(dispatch) {
  return {
    checkFertilizationPlan: (farmId, year) => dispatch(FertilizerAction.checkFertilizationPlanAvailability(farmId, year)),
    createFertilizationPlan: (farmId, deleteDraftPlan, data) => dispatch(FertilizerAction.createFertilizationPlan(farmId, deleteDraftPlan, data)),
  };
}

export default injectIntl(
  reduxForm({
    form: 'CreateFertilizationPlan'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateFertilizationPlan)))
);
