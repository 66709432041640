import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import AddBaleModal from 'mui-mobile-components/storages/balestorage/balemodals/AddBaleModal';
import SubBaleModal from 'mui-mobile-components/storages/balestorage/balemodals/SubBaleModal';
import AnalysisBaleModal from 'mui-mobile-components/storages/balestorage/balemodals/AnalysisBaleModal';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import * as FieldActions from 'actions/Fields';
import * as BaleAnalysisActions from 'actions/BaleAnalysis';
import AddIcon from '@tine/lib-frontend-components/elements/icons/add-icon/index';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';

class AddBaleLink extends React.Component {
  componentDidMount = () => {
    const { activeFarm, getLocations, fetchFieldList, history} = this.props;
    if (!activeFarm || activeFarm === null) {
      history.push('/settings');
    }
    getLocations(activeFarm && activeFarm.farm.id);
    fetchFieldList(activeFarm && activeFarm.farm.id);
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getLocations, fetchFieldList, getAnalysisStorage } = this.props;
    if (!activeFarm || activeFarm === null) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      getLocations(activeFarm.farm.id);
      fetchFieldList(activeFarm.farm.id);
      getAnalysisStorage(activeFarm.farm.id);
      history.push('/storages');
    }
  }

  render() {
    const { intl, getAnalysisStorageListData, activeFarm, hasSupportAdminRole } = this.props;
    const enableCreateButton = 
    (activeFarm && activeFarm.role) ? activeFarm && (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    return (
      enableCreateButton ? <div>
        <AnalysisBaleModal
          getAnalysisStorageListData={getAnalysisStorageListData}
          paramId="add"
        />
        <SubBaleModal
          getAnalysisStorageListData={getAnalysisStorageListData}
        />
        <AddBaleModal
          getAnalysisStorageListData={getAnalysisStorageListData}
        />
        <div className="add-harvest margin-top-add-storages">
          <Link to="/storage/bale-storage/create">
            <span className="pad-right">{intl.formatMessage({ id: 'CREATE_BALE_STORAGE' })}</span>
            <div className="add-icon">
              <AddIcon />
            </div>
          </Link>
        </div>
      </div> : ''
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  locations: state.baleFeedEvent.locationData,
  fieldList: state.fieldDetails.data,
  getAnalysisStorageList: state.baleAnalysis,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  fetchFieldList: (farmId) => dispatch(FieldActions.fetchFieldList(farmId)),
  addFeedEvent: (farmId, data) => dispatch(baleFeedEventActions.addFeedEvent(farmId, data)),
  deleteFeedEvent: (farmId, data) => dispatch(baleFeedEventActions.deleteFeedEvent(farmId, data)),
  getAnalysisStorage: (farmId) => dispatch(BaleAnalysisActions.getAllStorages(farmId)),
  addAnalysis: (farmId, data) => dispatch(BaleAnalysisActions.saveAnalysis(farmId, data))
});

export default injectIntl(
  reduxForm({
    form: 'AddBaleLink',
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBaleLink)))
);
