import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import BaleStorage from 'mui-components/storages/balestorage';
import ManureStorage from 'mui-components/storages/manurestorage';
import MineralStorage from 'mui-components/storages/mineralstorage';
import { MANURE_STORAGE, BALE_STORAGE, MINERAL_STORAGE } from 'constants/index';
import { Box, Grid, Typography, Stack, Chip } from '@mui/material'
import { createSvgIcon } from '@mui/material/utils';
import ModalWindow from 'mui-components/storages/mineralstorage/ModalWindow';
import * as mineralFertilizerActions from 'actions/MineralFertilizer';
import * as mineralFertilizerStorageActions from 'actions/MineralFertilizerStorage';
import Loader from 'components/Loader';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index === 0 ? 'customFieldTabDiv' : 'customFertilizerTabDiv'}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);

class Storages extends React.Component {
  constructor(props) {
    super(props);
    this.closeDialogWindow = this.closeDialogWindow.bind(this);
    this.selectedNameMethod = this.selectedNameMethod.bind(this);
    this.selectedWeightMethod = this.selectedWeightMethod.bind(this);
    this.selectedStorageMethod = this.selectedStorageMethod.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addNewMineralFertilizer = this.addNewMineralFertilizer.bind(this);
    this.state = {
      selected: null,
      value: 0,
      openDialogWindow: false,
      disableCreateButton: true,
      selectedName: '',
      selectedWeight: '',
      selectedStorage: ''
    };
  }

  componentDidMount = () => {
    const { location, activeFarm, history, mineralFertilizerList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    mineralFertilizerList();
    if (location.pathname.includes(MINERAL_STORAGE)) {
      this.setState({
        selected: MINERAL_STORAGE,
        value: 2
      });
    } else if (location.pathname.includes(MANURE_STORAGE)) {
      this.setState({
        selected: MANURE_STORAGE,
        value: 1
      });
    } else {
      this.setState({
        selected: BALE_STORAGE,
        value: 0
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { openDialogWindow } = this.state;
    const { activeFarm, mineralFertilizerStorage, getMineralStorage , location} = this.props;
    const { isSuccess } = mineralFertilizerStorage;
    if(isSuccess && openDialogWindow) {
      getMineralStorage(activeFarm.farm.id);
      this.setState({
        openDialogWindow: false
      });
      mineralFertilizerStorage.isSuccess = false;
    }
    if (location.pathname !== prevProps.location.pathname) {
      let newSelected;
      let newValue;
  
      // Set the selected tab and value based on the current pathname
      if (location.pathname.includes(MINERAL_STORAGE)) {
        newSelected = MINERAL_STORAGE;
        newValue = 2;
      } else if (location.pathname.includes(MANURE_STORAGE)) {
        newSelected = MANURE_STORAGE;
        newValue = 1;
      } else {
        newSelected = BALE_STORAGE;
        newValue = 0;
      }
  
      // Update the state only if the selected value or the value state is different
      if (newSelected !== this.state.selected || newValue !== this.state.value) {
        this.setState({
          selected: newSelected,
          value: newValue
        });
      }
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history } = this.props;    
    if(newValue === 2) {
      this.setState({ selected: MANURE_STORAGE, value: newValue });
      history.push("/storage/mineral-storage");
    } else if(newValue === 1) {
      this.setState({ selected: BALE_STORAGE, value: newValue });
      history.push("/storage/manure-storage");
    } else {
      this.setState({ selected: BALE_STORAGE, value: newValue });
      history.push("/storages");
    }
  };

  addNewMineralFertilizer = () => {
    this.setState({
      openDialogWindow: true
    });
  }

  closeDialogWindow = () => {
    this.setState({
      openDialogWindow: false
    });
  }

  selectedNameMethod = (event) => {
    const { selectedWeight, selectedStorage } = this.state;
    let errorCode = 0;
    this.setState({
      selectedName: event.target.value
    });
    if(event.target.value === '') {
      errorCode = 1;
    } else {
      errorCode = 0;
    }
    if(errorCode === 0 && selectedStorage === '') {
      errorCode = 1;
    }
    if(errorCode === 0 && selectedWeight !== '' && isNaN(selectedWeight)) {
      errorCode = 1;
    }
    if(errorCode === 0 && selectedStorage !== '' && isNaN(selectedStorage)) {
      errorCode = 1;
    }
    if(errorCode === 0) {
      this.setState({
        disableCreateButton: false
      });
    } else {
      this.setState({
        disableCreateButton: true
      });
    }
  }

  selectedStorageMethod = (event) => {
    const { selectedName, selectedWeight } = this.state;
    let errorCode = 0;
    this.setState({
      selectedStorage: event.target.value
    });
    if(selectedName === '') {
      errorCode = 1;
    }
    if(errorCode === 0) {
      if(event.target.value === '') {
        errorCode = 1;
      } else if(event.target.value !== '' && isNaN(event.target.value)) {
        errorCode = 1;
      } else {
        errorCode = 0;
      }
    }
    if(errorCode === 0 && selectedWeight !== '' && isNaN(selectedWeight)) {
      errorCode = 1;
    }
    if(errorCode === 0) {
      this.setState({
        disableCreateButton: false
      });
    } else {
      this.setState({
        disableCreateButton: true
      });
    }
  }

  selectedWeightMethod = (event) => {
    const { selectedName, selectedStorage } = this.state;
    let errorCode = 0;
    this.setState({
      selectedWeight: event.target.value
    });
    if(selectedName === '') {
      errorCode = 1;
    }
    if(selectedStorage === '') {
      errorCode = 1;
    }
    if(errorCode === 0) {
      if(event.target.value !== '' && isNaN(event.target.value)) {
        errorCode = 1;
      } else {
        errorCode = 0;
      }
    }
    if(errorCode === 0 && selectedStorage !== '' && isNaN(selectedStorage)) {
      errorCode = 1;
    }
    if(errorCode === 0) {
      this.setState({
        disableCreateButton: false
      });
    } else {
      this.setState({
        disableCreateButton: true
      });
    }
  }

  handleSubmit = () => {
    const { activeFarm, createMineralStorage } = this.props;
    const { selectedName, selectedWeight, selectedStorage } = this.state;
    let formData = {};
    formData["farmId"] = activeFarm.farm.id;
    formData["name"] = selectedName;
    formData["storageBags"] = parseInt(selectedStorage);
    if(selectedWeight !== '') {
      formData["weight"] = parseInt(selectedWeight);
    }
    createMineralStorage(activeFarm.farm.id, formData);
    this.setState({
      disableCreateButton: true,
      selectedName: '',
      selectedWeight: '',
      selectedStorage: ''
    });
  }

  render() {
    const { intl, mineralFertilizer, mineralFertilizerStorage, location } = this.props;
    const { isLoading, hasError, errorMsg } = mineralFertilizerStorage;
    const { value } = this.state;
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    return (
      <div className={`${'main-content'}`}>
        {/*isSuccess && window.location.reload()*/}
        {(location.pathname).includes('storages') && <Header bcTitle={intl.formatMessage({ id: 'STORAGE' })} />}
        {(location.pathname).includes('manure-storage') && <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_9' })} />}
        {(location.pathname).includes('mineral-storage') && <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_10' })} />}
        <div className="settings-tabs">
          {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%' }}><Loader styleName="fixed-loader" /></div>}
          <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1 }}>
            {/*<Grid item md={value === 2 ? 8 : 10}>
              <Tabs variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={value} onChange={this.handleChange} className='mui-tabs'>
                <Tab style={tabStyle} label={intl.formatMessage({ id: 'BALE_STORAGE' })} {...a11yProps(0)} />
                <Tab style={tabStyle} label={intl.formatMessage({ id: 'MANURE_STORAGE' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                <Tab style={tabStyle} label={intl.formatMessage({ id: 'MINERAL_FERTILIZER' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
              </Tabs>
            </Grid>*/}
          </Box>
          <Grid container justifyContent="flex-end">
            {value === 2 &&
            (<Grid item md={12} className='grid-padding-top' sx={{ paddingRight: "20px" }}>
              <Stack direction="row" justifyContent="end">
                <Chip
                  icon={<PlusIcon />}
                  label={intl.formatMessage({ id: 'ADD_MINERAL_FERTILIZER' })}
                  color="primary"
                  variant="outlined"
                  clickable
                  onClick={() => this.addNewMineralFertilizer()}
                  style={buttonStyle}
                />
              </Stack>
            </Grid>)}
          </Grid>
          <CustomTabPanel value={value} index={0}>
            <BaleStorage />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ManureStorage />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <MineralStorage
              addNewMineralFertilizer={this.addNewMineralFertilizer}
            />
          </CustomTabPanel>
          <ModalWindow
            intl={intl}
            openDialogWindow={this.state.openDialogWindow}
            closeDialogWindow={this.closeDialogWindow}
            disableCreateButton={this.state.disableCreateButton}
            mineralFertilizer={mineralFertilizer}
            selectedNameMethod={this.selectedNameMethod}
            selectedWeightMethod={this.selectedWeightMethod}
            selectedStorageMethod={this.selectedStorageMethod}
            handleSubmit={this.handleSubmit}
            hasError={hasError}
            errorMsg={errorMsg && errorMsg.data}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  mineralFertilizer: state.mineralFertilizer,
  mineralFertilizerStorage: state.mineralFertilizerStorage,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'manureStoragePage'])[0]
});

const mapDispatchToProps = dispatch => ({
  mineralFertilizerList: () => dispatch(mineralFertilizerActions.mineralFertilizerList()),
  createMineralStorage: (farmId, data) => dispatch(mineralFertilizerStorageActions.createMineralStorage(farmId, data)),
  getMineralStorage: (farmId) => dispatch(mineralFertilizerStorageActions.fetchMineralStorageList(farmId))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Storages)));