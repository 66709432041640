import React from 'react';
import Assets from 'mui-mobile-components/settings/vehicles/Assets';
import { VEHICLE } from 'constants/index';

const ManageVehicles = (vehicleProps) => (
  <Assets
    assetType={VEHICLE}
    vehicleProps={vehicleProps}
  />
);

export default ManageVehicles;
