import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GrassAnalysis from 'mui-mobile-components/grassanalysis/grass/GrassAnalysisList';
import GrassAnalysisSearch from 'mui-mobile-components/grassanalysis/search/SearchGrassAnalysis';
import PageNotFound from 'components/PageNotFound';

const SoilSamples = () => (
  <Switch>
    <Route path="/grassanalysis/search" component={GrassAnalysisSearch} />
    <Route path="/grassanalysis" component={GrassAnalysis} />
    <Route component={PageNotFound} />
  </Switch>
);

export default SoilSamples;
