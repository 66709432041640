import React from 'react';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import { Box, Stack, Grid, Chip } from '@mui/material'
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import { createSvgIcon } from '@mui/material/utils';
import * as farmActions from 'actions/Farms';
import { buttonRedStyle, buttonRoundStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';
import {
  FarmFieldValidation,
  PostalCodeValidation,
  OrganizationalNumberValidation,
  checkPermissions
} from 'utils/index';
import Loader from 'components/Loader';
import { SEARCH_API_KEY, CREATE_FARM, CREATE, UPDATE, OWNER, ADMIN, ADVISOR } from 'constants/index';
import AddFarmModal from 'mui-mobile-components/settings/farms/AddFarmModal';
import mixpanel from 'mixpanel-browser';
import { ADD_FARM_COMPLETE } from 'constants/MixPanel';

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);

const EditIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.295 3.19241L15.3075 4.20491C15.9 4.78991 15.9 5.74241 15.3075 6.32741L5.385 16.2499H2.25V13.1149L10.05 5.30741L12.1725 3.19241C12.7575 2.60741 13.71 2.60741 14.295 3.19241ZM3.75 14.7499L4.8075 14.7949L12.1725 7.42241L11.115 6.36491L3.75 13.7299V14.7499Z" fill="#00696D"/>
  </svg>,
  'Edit',
);

const GreyEditIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.295 3.19241L15.3075 4.20491C15.9 4.78991 15.9 5.74241 15.3075 6.32741L5.385 16.2499H2.25V13.1149L10.05 5.30741L12.1725 3.19241C12.7575 2.60741 13.71 2.60741 14.295 3.19241ZM3.75 14.7499L4.8075 14.7949L12.1725 7.42241L11.115 6.36491L3.75 13.7299V14.7499Z" fill="#C6C8C8"/>
  </svg>,
  'Edit',
);

class FarmDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      farmTypeSelected: [],
      farmName: '',
      organizationalNumber: '',
      billingEmail: '',
      address: '',
      postalCode: '',
      region: '',
      farmNameHelperText: '',
      organizationalNumberHelperText: <FormattedMessage id="MANAGE_FARMS_HELPER_TEXT" />,
      billingEmailHelperText: '',
      addressHelperText: '',
      postalCodeHelperText: '',
      regionHelperText: '',
      disableButton: true

    };
    this.closeNewFarm = this.closeNewFarm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { activeFarm } = this.props;
    if (activeFarm) {
      this.initializeValues();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, activeFarm, isAddFarmPage } = this.props;
    if (prevProps.location !== location || prevProps.activeFarm !== activeFarm || prevProps.isAddFarmPage !== isAddFarmPage) {
      this.initializeValues();
    }
  }

  // On submit to add a New Farm
  handleSubmit = async () => {
    const { farmName, organizationalNumber, billingEmail, address, postalCode, region } = this.state;
    const { addNewFarm, activeFarm, isAddFarmPage, hasSupportAdminRole, intl, onCloseFarmDialog, userDetails } = this.props;
    let helperChange = false;
    if (farmName) {
      const result = FarmFieldValidation(farmName, null, 'name');
      if (!result.valid) {
        this.setState({
          farmNameHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: result.message })}</div>
        });
        helperChange = true;
      } else {
        this.setState({ farmNameHelperText: '' });
      }
    }
    if (organizationalNumber) {
      const result = OrganizationalNumberValidation(organizationalNumber);
      if (!result.valid) {
        this.setState({
          organizationalNumberHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: result.message })}</div>
        });
        helperChange = true;
      } else {
        this.setState({ organizationalNumberHelperText: <FormattedMessage id="MANAGE_FARMS_HELPER_TEXT" /> });
      }
    }
    if (address) {
      const result = FarmFieldValidation(address, 199);
      if (!result.valid) {
        this.setState({
          addressHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: 'AF_VALIDATION_6' })}</div>
        });
        helperChange = true;
      } else {
        this.setState({ addressHelperText: '' });
      }
    }
    if (postalCode) {
      const result = PostalCodeValidation(postalCode);
      if (!result.valid) {
        this.setState({
          postalCodeHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: result.message })}</div>
        });
        helperChange = true;
      } else {
        this.setState({ postalCodeHelperText: '' });
      }
    }
    if (region) {
      const result = FarmFieldValidation(region);
      if (!result.valid) {
        this.setState({
          regionHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: result.message })}</div>
        });
        helperChange = true;
      } else {
        this.setState({ regionHelperText: '' });
      }
    }
    if(helperChange === false) {
      const farmId = activeFarm ? activeFarm.farm.id : '';
      const farmObj = {};
      farmObj["name"] = farmName;
      farmObj["organizationalNumber"] = organizationalNumber;
      farmObj["billingEmail"] = billingEmail;
      farmObj["address"] = address;
      farmObj["postalCode"] = postalCode;
      farmObj["region"] = region;
      let fullAddress  = address + " " + postalCode + " " + region;
      let gpsCord = await this.getCoordinates(fullAddress);
      let geoDetails = {};
      if(gpsCord !== null) {
        geoDetails['lattitude'] = gpsCord[0];
        geoDetails['longitude'] = gpsCord[1];
        farmObj["location"] = geoDetails;
      } else {
        farmObj["location"] = null;
      }
      farmObj["id"] = isAddFarmPage ? '' : farmId;
      if (isAddFarmPage) {
        mixpanel.identify(userDetails.id);
        mixpanel.track(ADD_FARM_COMPLETE);
      }
      addNewFarm(farmObj, isAddFarmPage ? CREATE : UPDATE, hasSupportAdminRole.data);
      onCloseFarmDialog();
    }
  };

  getCoordinates = async (address) => {
    const apiKey = SEARCH_API_KEY;
    const encodedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;
  
    try {
      const response = await axios.get(url);
      const { results } = response.data;

      if (results.length > 0) {
        let arrayCoord = [];
        arrayCoord.push(results[0].geometry.location.lat);
        arrayCoord.push(results[0].geometry.location.lng);
        return arrayCoord;
      } else {
        // Return null if no results are found
        return null;
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return null;
    }
  };

  // To fetch and populate details of active farm
  initializeValues = () => {
    const {
      props: { activeFarm, isAddFarmPage, userDetails }
    } = this;
    if (!isAddFarmPage && activeFarm) {
      this.setState({
        farmName: activeFarm.farm.name !== null ? activeFarm.farm.name : '',
        organizationalNumber: activeFarm.farm.organizationalNumber !== null ? activeFarm.farm.organizationalNumber : '',
        billingEmail: activeFarm.farm.billingEmail !== null ? activeFarm.farm.billingEmail : '',
        address: activeFarm.farm.address !== null ? activeFarm.farm.address : '',
        postalCode: activeFarm.farm.postalCode !== null ? activeFarm.farm.postalCode : '',
        region: activeFarm.farm.region !== null ? activeFarm.farm.region : '',
        farmNameHelperText: '',
        organizationalNumberHelperText: <FormattedMessage id="MANAGE_FARMS_HELPER_TEXT" />,
        billingEmailHelperText: '',
        addressHelperText: '',
        postalCodeHelperText: '',
        regionHelperText: ''
      });
      this.setState({
        farmTypeSelected: !activeFarm.farm.type ? [] : activeFarm.farm.type.split(','),
        disableButton: false
      });
    } else {
      this.setState({ farmTypeSelected: [] });
      this.setState({
        farmName: '',
        organizationalNumber: '',
        billingEmail: userDetails.data.emailId,
        address: '',
        postalCode: '',
        region: '',
        farmNameHelperText: '',
        organizationalNumberHelperText: <FormattedMessage id="MANAGE_FARMS_HELPER_TEXT" />,
        billingEmailHelperText: '',
        addressHelperText: '',
        postalCodeHelperText: '',
        regionHelperText: '',
        disableButton: true
      });
    }
  };

  checkFarmPermission = () => {
    const {
      props: { activeFarm, rolePermissions, isAddFarmPage, hasSupportAdminRole }
    } = this;
    if (isAddFarmPage) {
      return checkPermissions(CREATE_FARM, activeFarm, rolePermissions);
    }
    return activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
  };

  openNewFarm = () => {
    const { onOpenFarmDialog } = this.props;
    onOpenFarmDialog();
    this.initializeValues();
  };

  closeNewFarm = () => {
    const { onCloseFarmDialog } = this.props;
    onCloseFarmDialog();
    this.initializeValues();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value ? event.target.value : ''
    });
    if(event.target.name === 'farmName') {
      if(event.target.value.trim() === '' || this.state.organizationalNumber.trim() === '' || this.state.billingEmail.trim() === '' || this.state.address.trim() === '' || 
        this.state.postalCode.trim() === '' || this.state.region.trim() === '') {
          this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
    } else if(event.target.name === 'organizationalNumber'){
      if(event.target.value.trim() === '' || this.state.farmName.trim() === '' || this.state.billingEmail.trim() === '' || this.state.address.trim() === '' || 
        this.state.postalCode.trim() === '' || this.state.region.trim() === '') {
          this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
    } else if(event.target.name === 'billingEmail'){
      if(event.target.value.trim() === '' || this.state.farmName.trim() === '' || this.state.organizationalNumber.trim() === '' || this.state.address.trim() === '' || 
        this.state.postalCode.trim() === '' || this.state.region.trim() === '') {
          this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
    } else if(event.target.name === 'address'){
      if(event.target.value.trim() === '' || this.state.farmName.trim() === '' || this.state.organizationalNumber.trim() === '' || this.state.billingEmail.trim() === '' || 
        this.state.postalCode.trim() === '' || this.state.region.trim() === '') {
          this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
    } else if(event.target.name === 'postalCode'){
      if(event.target.value.trim() === '' || this.state.farmName.trim() === '' || this.state.organizationalNumber.trim() === '' || this.state.billingEmail.trim() === '' || 
        this.state.address.trim() === '' || this.state.region.trim() === '') {
          this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
    } else {
      if(event.target.value.trim() === '' || this.state.farmName.trim() === '' || this.state.organizationalNumber.trim() === '' || this.state.billingEmail.trim() === '' || 
        this.state.address.trim() === '' || this.state.postalCode.trim() === '') {
          this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
    }
  };

  render() {
    const { farmDetails, intl, activeFarm, isAddFarmPage, disableDelete, deleteFarm, showAddFarmDialog, addNewFarmDialog} = this.props;
    const { errorMessage, isLoading, isSuccess, isUpdateSuccess, hasUpdateError, hasDeleteError } = farmDetails;
    const updateFarmPermission = activeFarm?.role ? this.checkFarmPermission() : true;
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    return (
      <div className="manageFarmsLayout">
        {activeFarm ?
        <div className="manageFarmsLayoutDivLayout">
          <div className="manageFarmsLayoutDiv">
            {isLoading && <Loader styleName="farm-loader" />}
            <div><img src="images/FarmLightGrey.png" alt="Farm" /></div>
            <Box style={inputStyle}>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="name"
                  id="farmName"
                  label={intl.formatMessage({ id: 'AF_FN' })}
                  variant="outlined"
                  value={activeFarm && activeFarm.farm && activeFarm.farm.name ? activeFarm.farm.name : ''}
                  showFullWidth="true"
                  disabled={true}
                />
              </Stack>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="organizationalNumber"
                  id="organizationalNumber"
                  label={intl.formatMessage({ id: 'AF_ORGANIZATIONALNUMBER' })}
                  variant="outlined"
                  value={activeFarm && activeFarm.farm && activeFarm.farm.organizationalNumber ? activeFarm.farm.organizationalNumber : ''}
                  showFullWidth="true"
                  disabled={true}
                  helperText={intl.formatMessage({ id: 'MANAGE_FARMS_HELPER_TEXT' })}
                />
              </Stack>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="billingEmail"
                  id="billingEmail"
                  label={intl.formatMessage({ id: 'BILLING_EMAIL' })}
                  variant="outlined"
                  value={activeFarm && activeFarm.farm && activeFarm.farm.billingEmail ? activeFarm.farm.billingEmail : ''}
                  showFullWidth="true"
                  disabled={true}
                />
              </Stack>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="address"
                  id="address"
                  label={intl.formatMessage({ id: 'AF_ADD' })}
                  variant="outlined"
                  value={activeFarm && activeFarm.farm && activeFarm.farm.address ? activeFarm.farm.address : ''}
                  showFullWidth="true"
                  disabled={true}
                />
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Stack className='text-with-padding'>
                    <MuiTextBox 
                      name="postalCode"
                      id="postalCode"
                      label={intl.formatMessage({ id: 'AF_ZIP' })}
                      variant="outlined"
                      value={activeFarm && activeFarm.farm && activeFarm.farm.postalCode ? activeFarm.farm.postalCode : ''}
                      showFullWidth="true"
                      disabled={true}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Stack className='text-with-padding'>
                    <MuiTextBox 
                      name="region"
                      id="region"
                      label={intl.formatMessage({ id: 'AF_REG' })}
                      variant="outlined"
                      value={activeFarm && activeFarm.farm && activeFarm.farm.region ? activeFarm.farm.region : ''}
                      showFullWidth="true"
                      disabled={true}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <div className={`${!updateFarmPermission ? 'disabled' : ''}`} style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                {!updateFarmPermission ?
                <Stack direction="row" justifyContent="center">
                  <Chip
                    icon={<GreyEditIcon />}
                    label={intl.formatMessage({ id: 'MANAGE_FARMS_EDIT' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => this.openNewFarm()}
                    className={`${!updateFarmPermission ? 'disabled' : ''}`}
                    style={buttonRoundStyle}
                  />
                </Stack> :
                <Stack direction="row" justifyContent="center">
                  <Chip
                    icon={<EditIcon />}
                    label={intl.formatMessage({ id: 'MANAGE_FARMS_EDIT' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => this.openNewFarm()}
                    className={`${!updateFarmPermission ? 'disabled' : ''}`}
                    style={buttonRoundStyle}
                  />
                </Stack>
                }
              </div>
              <div className={`${!disableDelete ? 'disabled' : ''}`} style={{ paddingRight: '0px !important' }}>
                <Stack direction="row" justifyContent="center">
                  <Chip
                    label={intl.formatMessage({ id: 'AVSLUTT_ABONNEMENT' })}
                    clickable
                    className={`${!disableDelete ? 'disabled' : ''}`}
                    style={buttonRedStyle}
                    sx={{ backgroundColor: 'transparent',
                      '&:hover, &:focus': {
                        backgroundColor: 'transparent',
                      }
                    }}
                    disableRipple
                    onClick={() => deleteFarm()}
                  />
                </Stack>
              </div>
            </Box>
            <div>
              {(hasUpdateError || hasDeleteError) && (
                <ErrorText>
                  {intl.formatMessage({
                    id: errorMessage?.status === 409 ? 'FAAE' : 'COMMON_ERR_MSG'
                  })}
                </ErrorText>
              )}
              {isUpdateSuccess ? (
                <div className="updated-div-mui">
                  <FormattedMessage id="updated" />
                </div>
              ) : null}
              {isSuccess && isAddFarmPage ? <Redirect to="/managefields" /> : null}
            </div>
          </div>
        </div> :
        <div className="manageFarmsLayoutDivLayout">
          <div className="manageFarmsLayoutDiv">
            <div><img src="images/FarmLightGreenLarge.png" alt="Farm" /></div>
            <Box style={inputStyle}>
              <Stack className='text-with-padding'>
                <div style={titleFont}>
                  {intl.formatMessage({ id: 'MANAGE_FARMS_DIALOG_TITLE' })}
                </div>
              </Stack>
              <Stack className='text-with-padding'>
                <div style={subTitleFont}>
                  {intl.formatMessage({ id: 'MANAGE_FARMS_DIALOG_CONTENT' })}
                </div>
              </Stack>
              <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                <Stack direction="row" justifyContent="center">
                  <Chip
                    icon={<PlusIcon />}
                    label={intl.formatMessage({ id: 'MANAGE_EMPTY_FARM' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => addNewFarmDialog()}
                    style={buttonStyle}
                  />
                </Stack>
              </div>
            </Box>
            <div>
              {(hasUpdateError || hasDeleteError) && (
                <ErrorText>
                  {intl.formatMessage({
                    id: errorMessage?.status === 409 ? 'FAAE' : 'COMMON_ERR_MSG'
                  })}
                </ErrorText>
              )}
              {isUpdateSuccess ? (
                <div className="updated-div-mui">
                  <FormattedMessage id="updated" />
                </div>
              ) : null}
              {isSuccess && isAddFarmPage ? <Redirect to="/managefields" /> : null}
            </div>
          </div>
        </div>}
        <AddFarmModal
          intl={intl}
          onCreateFarm={showAddFarmDialog}
          closeNewFarm={this.closeNewFarm}
          activeFarm={activeFarm}
          farmNameValue={this.state.farmName}
          organizationalNumberValue={this.state.organizationalNumber}
          billingEmailValue={this.state.billingEmail}
          addressValue={this.state.address}
          postalCodeValue={this.state.postalCode}
          regionValue={this.state.region}
          farmNameHelperText={this.state.farmNameHelperText}
          organizationalNumberHelperText={this.state.organizationalNumberHelperText}
          billingEmailText={this.state.billingEmailHelperText}
          addressHelperText={this.state.addressHelperText}
          postalCodeHelperText={this.state.postalCodeHelperText}
          regionHelperText={this.state.regionHelperText}
          handleChange={this.handleChange}
          disableButton={this.state.disableButton}
          handleSubmit={this.handleSubmit}
          isAddFarmPage={isAddFarmPage}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  farmDetails: state.farmList,
  userDetails: state.userDetails,
  rolePermissions: state.RolesDetails,
  activeFarm: state.farmList.activeFarm,
  geoLocDetails: state.geoLocDetails.data,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDisptachToActions(dispatch) {
  return {
    addNewFarm: (data, method, hasSupportAdminRole) => dispatch(farmActions.addNewFarm(data, method, hasSupportAdminRole))
  };
}

export default injectIntl(
  reduxForm({
    form: 'AddFarmsForm'
  })(withRouter(connect(mapStateToProps, mapDisptachToActions)(FarmDetails)))
);
