import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BatchTasks from 'containers/tasks/batch/BatchTasks';
import PageNotFound from 'components/PageNotFound';

const TaskList = () => (
  <Switch>
    <Route exact path="/task-batch" component={BatchTasks} />
    <Route exact path="/tasks" component={BatchTasks} />
    <Route component={PageNotFound} />
  </Switch>
);

export default TaskList;
