import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { CssGridLayout } from '@tine/lib-frontend-components/components/alignment/css-grid-layout/css-grid-layout';
import { buttonStyle, buttonRedStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';

import * as assetAction from 'actions/Assets';
import AssetCard from 'mui-mobile-components/settings/vehicles/AssetCard';
import { Dialog, DialogTitle, Chip, Box } from '@mui/material';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';

const dialogTitleDialogStyle = { color: '#191C1C', fontFamily: 'Inter', fontSize: '24px', fontStyle: 'normal', fontWeight: '400', lineHeight: '32px' }
const dialogSubTitleStyle = { color: '#3F4949', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '20px', letterSpacing: '0.25px', padding: '10px 24px' }

class AssetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmShow: false,
      selectedAssetId: ''
    };
    this.onOpenDeleteDialog = this.onOpenDeleteDialog.bind(this);
    this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);
  }

  onOpenDeleteDialog = (assetId) => {
    this.setState({ deleteConfirmShow: true });
    this.setState({ selectedAssetId: assetId });
  }

  onCloseDeleteDialog = () => {
    this.setState({ deleteConfirmShow: false });
    this.setState({ selectedAssetId: '' });
  }

  deleteAction = () => {
    const { activeFarm, deleteAsset } = this.props;
    deleteAsset(activeFarm.farm.id, this.state.selectedAssetId);
    this.setState({ deleteConfirmShow: false });
    this.setState({ selectedAssetId: '' });
  };

  getSortByValue = () => {
    const { assetType, assetsDetails } = this.props;
    const assetsData = assetsDetails.assets[assetType];
    return _.orderBy(assetsData, 'asset.brand');
  };

  renderList = () => {
    const {
      assetType,
      disabled,
      assetsDetails: { assets },
      vehicleProps,
      activeFarm,
      hasSupportAdminRole
    } = this.props;
    const enableAssetButton = (activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data);
    if (assets[assetType]?.length > 0) {
      const sortedData = this.getSortByValue();
      return (
        sortedData.map((asset) => {
          return (
            <ul className="field-list-wrapper full-width" key={asset.assetAssignmentId} style={{ paddingLeft: '18px', paddingRight: '16px' }}>
              <AssetCard
                machine={asset}
                assetType={assetType}
                addOrUpdateAssetPermissionCheck={disabled}
                enableAssetButton={enableAssetButton}
                onOpenDeleteDialog={this.onOpenDeleteDialog}
                vehicleProps={vehicleProps}
              />
            </ul>
          );
        })
      );
    }
    return null;
  };

  render() {
    const { intl } = this.props;
    const { deleteConfirmShow } = this.state;
    return (
      <div className="full-width asset-list-container">
        <div className="full-width asset-list-content">
          <div className="vehicle-list-flex-container full-width">
            <CssGridLayout>
              <div className="vehicle-list-details">
                <div style={{
                  padding: '24px 26px 0px 26px',
                  color: '#191C1C',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '0.1px'
                }}>
                  {intl.formatMessage({ id: 'TRACTOR' })}
                </div>
                {this.renderList()}
              </div>
            </CssGridLayout>
            <Dialog open={deleteConfirmShow} onClose={() => {}} PaperProps={{
              style: {
                backgroundColor: '#E6E9E8',
                borderRadius: "28px",
                width: "320px",
                minWidth: "200px",
                maxWidth: "320px",
              }}}>
              <DialogTitle style={dialogTitleDialogStyle}>
                {intl.formatMessage({ id: 'DELETE_VEHICLE_TITLE' })}
              </DialogTitle>
              <Box component="div" style={dialogSubTitleStyle}>
                {intl.formatMessage({ id: 'DELETE_VEHICLE_CONTENT' })}
              </Box>
              <div style={{padding: "15px", textAlign: "right"}}>
                <span>
                  <Chip
                    label={intl.formatMessage({ id: 'CANCEL' })}
                    clickable
                    style={buttonStyle}
                    sx={{ backgroundColor: 'transparent',
                      '&:hover, &:focus': {
                        backgroundColor: 'transparent',
                      }
                    }}
                    disableRipple
                    onClick={() => this.onCloseDeleteDialog()}
                  />
                  <Chip
                    label={intl.formatMessage({ id: 'DELETE' })}
                    clickable
                    style={buttonRedStyle}
                    sx={{ backgroundColor: 'transparent',
                      '&:hover, &:focus': {
                        backgroundColor: 'transparent',
                      }
                    }}
                    disableRipple
                    onClick={() => this.deleteAction()}
                  />
                </span>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sortyByOption: (category, option) => dispatch(assetAction.assertSortingOption(category, option)),
  deleteAsset: (farmId, assetId) => dispatch(assetAction.deleteAsset(farmId, assetId))
});

const mapStateToProps = state => ({
  assetsDetails: state.assetsDetails,
  activeFarm: state.farmList.activeFarm,
  hasSupportAdminRole: state.SupportAdminRole
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetsList));
