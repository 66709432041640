import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, Box, Stack, Grid, Checkbox, Chip } from '@mui/material';
import { buttonStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';

const PesticideAgreement = ({ agreed, onChange, intl }) => {
  const agreementText = {
    color: "#191C1C",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    cursor: "pointer"
  }
  const scrollStyle = {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'thin',
    scrollbarColor: '#6F7979 #E6E9E8'
  }
  const stackLayoutTitle = {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    padding: "10px 0px 20px 0px"
  }
  const stackLayoutContent = {
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    padding: "0px 0px 20px 0px"
  }
  const [show, showAgreement] = React.useState(false);
  const onAgree = e => {
    showAgreement(false);
    onChange(e);
  };
  return (
    <Grid container spacing={2} style={{ paddingTop: "24px" }}>
      <Grid item xs={1} style={{ paddingLeft: '5px' }}>
        <Checkbox
          onChange={() => showAgreement(true)}
          checked={agreed}
          sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }, }}
        />
      </Grid>
      <Grid item xs={11} style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
        <Stack style={agreementText} onClick={() => showAgreement(true)}>
          {intl.formatMessage({ id: 'CT_CBT' })} *
        </Stack>
      </Grid>
      <Dialog open={show} onClose={() => {}} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "70%",
            minWidth: "70%",
            maxWidth: "80%",
      }}}>
        <Box component="div" style={scrollStyle}>
          <Box style={{ padding: '25px'}}>
            <Stack style={stackLayoutTitle}>{intl.formatMessage({ id: 'TITLE_1' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_1' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_2' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_3' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_4' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_5' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_6' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_7' })}</Stack>
            <Stack style={stackLayoutTitle}>{intl.formatMessage({ id: 'TITLE_1' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_8' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_9' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_10' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_11' })}</Stack>
            <Stack style={stackLayoutContent}>{intl.formatMessage({ id: 'PARA_12' })}</Stack>
            <Grid container spacing={2} style={{ display: 'ruby-text' }}>
              <Grid>
                <Stack>
                  <Chip
                    label={intl.formatMessage({ id: 'NO_THANK' })}
                    clickable
                    style={buttonStyle}
                    sx={{ backgroundColor: 'transparent',
                      '&:hover, &:focus': {
                        backgroundColor: 'transparent',
                      }
                    }}
                    onClick={() => onAgree(false)}
                    disableRipple
                  />
                </Stack>
              </Grid>
              <Grid>
                <Chip
                  label={intl.formatMessage({ id: 'YES_AGREE' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  onClick={() => onAgree(true)}
                  disableRipple
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default injectIntl(PesticideAgreement);
