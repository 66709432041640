import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddUser from 'mui-mobile-components/settings/users/AddUser';
import UserList from 'mui-mobile-components/settings/users/UserList';
import PageNotFound from 'components/PageNotFound';

const ManageUsers = () => (
  <Switch>
    <Route exact path="/settings/users/adduser" component={AddUser} />
    <Route exact path="/settings/users" component={UserList} />
    <Route exact path="/settings" component={UserList} />
    <Route component={PageNotFound} />
  </Switch>
);

export default ManageUsers;
