import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import pkceChallenge from 'pkce-challenge'
import { connect } from 'react-redux';
import * as loginActions from 'actions/Login';
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI, OAUTH_AUTH_URL, OAUTH_AUDIENCE_URL, OAUTH_RETURNTO_URL } from 'constants/OauthProperties';
import { WEB, REGISTER_USER } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

const searchParams = new URLSearchParams(document.location.search);
class LoginRedirect extends Component {

  componentDidMount = () => {
    const { doLoginOauth, userDetails } = this.props;
    if (userDetails && !userDetails.data) {
      if(searchParams.get('code') !== null) {
        doLoginOauth(null);
      }
    }
  };

  goToLoginPage = () => {
    const uuid = uuidv4();
    const pkce = pkceChallenge(128);
    const code_challenge = pkce.code_challenge;
    const code_verifier = pkce.code_verifier;
    if(searchParams.get('code') === null) {
      sessionStorage.setItem("codeVerifier", code_verifier);
      window.location.href = `${OAUTH_AUTH_URL}/oauth2/auth?response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${OAUTH_REDIRECT_URI}/auth&scope=openid+offline&code_challenge_method=S256&audience=${OAUTH_AUDIENCE_URL}&state=${uuid}&code_challenge=${code_challenge}`;
    }
  };

  goToPasswordPage = () => {
    if(searchParams.get('code') === null) {
      window.location.href = `${OAUTH_AUTH_URL}/ory/recovery?return_to=${OAUTH_RETURNTO_URL}/stats`;
    }
  };

  render() {
    const {
      userDetails,
      location: { state }
    } = this.props;
    const { data, logoutSuccess } = userDetails;
    const language = navigator.language || navigator.userLanguage;
    return (
      searchParams.get('code') === null ?
      <div className="eanaLeftSideInsideLayout">
          {!data &&
            <div style={{ alignSelf: 'stretch' }}>
              <div className="leftParagraphText1">
                {language === 'en-US' ? <FormattedMessage id="LOGIN_PAGE_TEXT_20" /> : <FormattedMessage id="LOGIN_PAGE_TEXT_20_NB" />}
              </div>
              <div style={{ paddingTop: '8%' }}>&nbsp;</div>
              <div className="eanaLoginButton">
                <div className="btn-wapper-new">
                  <span onClick={() => this.goToLoginPage()} className="go-to-login-button">
                    {language === 'en-US' ? <FormattedMessage id="LOGIN_PAGE_TEXT_21" /> : <FormattedMessage id="LOGIN_PAGE_TEXT_21_NB" />}
                  </span>
                </div>
              </div>
              <div className="leftParagraphText2Div">
                <div className="leftParagraphText2" onClick={() => {mixpanel.track(REGISTER_USER, {'Platform':WEB}); window.location.replace(`${OAUTH_AUTH_URL}/flow/register?return_to=${OAUTH_RETURNTO_URL}`);}}>
                  {language === 'en-US' ? <FormattedMessage id="LOGIN_PAGE_TEXT_22" /> : <FormattedMessage id="LOGIN_PAGE_TEXT_22_NB" />}
                </div>
              </div>
            </div>
          }
          {data && !logoutSuccess && data.emailId ? (
            this.props.history.push(state && state.from ? state.from.pathname : '/stats') 
          ) : null}
      </div> : null
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails
});

const mapDispatchToProps = dispatch => ({
  doLoginOauth: (data) => dispatch(loginActions.doLogin(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginRedirect);
