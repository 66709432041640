import React from 'react';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import FertilizerPlanPdfContent from 'mui-mobile-components/fields/fertilization/FertilizerPlanPdfContent';

const generatePdfDocument = async (documentData,intl) => {
    const blob = await pdf(
        <FertilizerPlanPdfContent
            documentData={documentData}
            intl={intl}
        />
    ).toBlob();
    saveAs(blob, documentData.versionName + ".pdf");
};

export default generatePdfDocument;