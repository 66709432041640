import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Stack, Button } from '@mui/material'
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import * as grassAnalysisActions from 'actions/GrassAnalysis';
import SearchGrassAnalysisCard from 'mui-mobile-components/grassanalysis/search/SearchGrassAnalysisCard';

class SearchGrassAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null
    }
  }

  componentDidMount = () => {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    } else {
    }
  };

  componentDidUpdate = (prevProps) => {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
    }
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/grassanalysis');
    }
  }

  handleSubmit = () => {
    const { searchGrassAnalysis } = this.props;
    const inputString = this.state.searchValue;
    const splitArray = inputString && inputString.split(/[\s,]+/).filter(Boolean);
    searchGrassAnalysis(splitArray);
  }

  handleChange = (event) => {
    this.setState({
      searchValue: event.target.value
    });
  }

  getSearchGrassCard = (grassAnalysisSearchList) => {
    return Object.keys(grassAnalysisSearchList).map(key => {
      const item = grassAnalysisSearchList[key];
      return (
        <div>
          <SearchGrassAnalysisCard item={item} qrCodeKey={key}/>
          &nbsp;
        </div>
      )
    })
  }

  render() {
    const { intl, grassAnalysis } = this.props;
    const customButtonStyle = {
      backgroundColor: '#00696D', color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00696D'
      }, borderRadius: '100px', paddingLeft: 20, paddingRight: 20, textTransform: 'none', fontSize: '14px', fontFamily: 'Inter'
    };
    return (
      <Box sx={{ width: '100%', flexGrow: 1 }}>
        {/*<form onSubmit={this.handleSubmit}>*/}
          <Grid container justifyContent="flex-end">
            <Grid item md={6} className='grid-with-padding' sx={{paddingRight: '20px'}}>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="searchGrass"
                  id="searchGrass"
                  label={intl.formatMessage({ id: 'GRASS_ANALYSIS_SEARCH_TEXTBOX' })}
                  variant="outlined"
                  value={this.state.searchValue}
                  onChange={e => this.handleChange(e)}
                  showFullWidth="true"
                />
              </Stack>
            </Grid>
            <Grid item md={1} className='grid-with-padding' sx={{paddingTop: '8px'}}>
              <Stack className='text-with-padding'>
                <Button style={customButtonStyle} type="submit" 
                  /*onClick={() => {this.handleSubmit(this.handleSubmit)}}*/
                  onClick={() => this.handleSubmit()}
                >
                  {intl.formatMessage({ id: 'GRASS_ANALYSIS_SEARCH_BUTTON' })}
                </Button>
              </Stack>
            </Grid>
            <Grid item md={5}>&nbsp;</Grid>
          </Grid>
        {/*</form>*/}
        {grassAnalysis && grassAnalysis.grassAnalysisSearchList && this.getSearchGrassCard(grassAnalysis.grassAnalysisSearchList)}
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  grassAnalysis: state.grassAnalysis
});

const mapDispatchToProps = dispatch => ({
  searchGrassAnalysis: (splitArray) => dispatch(grassAnalysisActions.searchGrassAnalysis(splitArray))
});

export default injectIntl(
  reduxForm({
    form: 'SearchGrassAnalysis'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchGrassAnalysis)))
);
