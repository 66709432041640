import * as React from 'react';
import { Box, Typography, Stack, Grid, Chip, MenuItem, FormControl, Select, Checkbox, ListItemText } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { getYearsForKPI } from 'utils/index';
import _ from 'lodash';
import {
  dialogTitleBox,
  gridDialogStyle,
  gridDialogTitle,
  gridButton,
  chipStyleSelected,
  gridContainerStyle,
  tableHeading,
  tableContent,
  textStyle,
  textSubStyle,
  toolTipText1,
  toolTipText2,
  chipStyleNotSelected
} from 'mui-mobile-components/stats/NitrogenKpiCardCss';

const dropdownCss = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px'
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    minWidth: '464px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '12px',
    background: '#ECEEEE',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)'
  },
}));

const yearComponent = (onSelectChange) => {
  return (
    getYearsForKPI().map((item) => (
      <MenuItem value={item.value} onClick={e => onSelectChange({label: item.value, value: item.value})} sx={{ height: '50px'}}>
        {item.value}
      </MenuItem>
    ))
  );
};

export default ({
  nitrogenListModal, selectedValue, intl, onSelectChange, fertilizationEfficiency, data, selectYear, onFieldChange, 
  selectedNitrogen, handleSelectedNitrogen, selectedFieldCheckbox
}) => (
  <Box style={dialogTitleBox} sx={{ backgroundColor: '#ffffff'}}>
    <Grid container spacing={1}>
      <Grid item>
        <Typography style={gridDialogStyle} onClick={e => nitrogenListModal()} sx={{ padding: '20px 0px 14px 14px', cursor: 'pointer' }}>
          <img src="images/closeIcon.png" height="24" width="24" alt="close" />
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={gridDialogTitle} sx={{ padding: '25px 0px 14px 14px' }}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT10' })}</Typography>
      </Grid>
      <Grid item xs>
        <Box display="flex" justifyContent="flex-end">
          <Typography sx={{ textAlign: 'end', paddingRight: '15px', paddingTop: '10px', lineHeight: 0 }}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Box sx={{ padding: '12px 16px' }}>
                    <Typography style={toolTipText1}>Nitrogenregnskap</Typography>
                    <Typography style={toolTipText2} sx={{ paddingTop: '16px' }}>
                      Nitrogenbalanse kg/daa = Nitrogen påført - Nitrogen i avling
                    </Typography>
                    <Typography style={toolTipText2} sx={{ paddingTop: '16px' }}>
                      Nitrogenutnyttelse = (Nitrogen i avling / Nitrogen påført) x 100
                    </Typography>
                  </Box>
                </React.Fragment>
              }
            >
              <InfoIcon />
            </HtmlTooltip>
          </Typography>
        </Box>
      </Grid>
    </Grid>

    <Box style={gridButton}>
      {/*<ToggleButtonGroup
        exclusive
        aria-label="text alignment"
        value={selectedValue}
        //onChange={handleAlignment}
      >
        {selectedValue === 'first' ?
          <ToggleButton value="first" style={buttonFilledLeftStyle1} disabled>
            <Typography style={titleTextButton}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT11' })}</Typography>
          </ToggleButton> :
          <ToggleButton value="first" style={buttonFilledLeftStyle} disabled>
            <Typography style={titleTextButton}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT11' })}</Typography>
          </ToggleButton>
        }
        {selectedValue === 'second' ?
          <ToggleButton value="second" style={buttonFilledRightStyle1}>
            <Typography style={titleTextButton}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT12' })}</Typography>
          </ToggleButton> :
          <ToggleButton value="second" style={buttonFilledRightStyle}>
            <Typography style={titleTextButton}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT12' })}</Typography>
          </ToggleButton>
        }
      </ToggleButtonGroup>*/}
      {selectedValue === 'second' &&
      <Box sx={{ flexGrow: 1, width: '100%', paddingLeft: '16px', paddingRight: '16px'/*, paddingTop: '25px'*/ }}>
        <Box sx={{ flexGrow: 1, paddingTop: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack direction="row" spacing={1} sx={{ paddingTop: '10px' }}>
                {selectedNitrogen === 'over' ?
                  <Chip
                    label={intl.formatMessage({ id: 'NITROGEN_KPI_TEXT6' })}
                    style={chipStyleSelected}
                    onClick={e => handleSelectedNitrogen('')}
                  /> :
                  <Chip
                    label={intl.formatMessage({ id: 'NITROGEN_KPI_TEXT6' })}
                    style={chipStyleNotSelected}
                    onClick={e => handleSelectedNitrogen('over')}
                  />
                }
                {selectedNitrogen === 'under' ?
                  <Chip
                    label={intl.formatMessage({ id: 'NITROGEN_KPI_TEXT9' })}
                    style={chipStyleSelected}
                    onClick={e => handleSelectedNitrogen('')}
                  /> :
                  <Chip
                    label={intl.formatMessage({ id: 'NITROGEN_KPI_TEXT9' })}
                    style={chipStyleNotSelected}
                    onClick={e => handleSelectedNitrogen('under')}
                  />
                }
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right', width: '100%' }}>
              <Grid container justifyContent="flex-end" sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start'}}>
                <Grid>
                  <Stack direction="row" spacing={2}>
                    <Select
                      multiple
                      displayEmpty
                      value={selectedFieldCheckbox}
                      onChange={e => onFieldChange(e)}
                      renderValue={(selected) => {
                        if ((data && data.harvestData && data.harvestData.length-1) ===  (selectedFieldCheckbox && selectedFieldCheckbox.length)) {
                          return intl.formatMessage({ id: 'ALL_FIELDS' })
                        }
                        if (selected.length === 0) {
                          return 'Velg'
                        }
                        // Map the selected ids to their corresponding labels for display
                        return selected
                          .map((id) => {
                            const option = data && data.harvestData && data.harvestData.find((opt) => opt.id === id);
                            return option ? option.name : '';
                          })
                          .filter(name => name)
                          .join(', ');
                      }}
                      sx={{
                        border: 'none', // Remove border
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        maxWidth: '150px'
                      }}
                    >
                      <MenuItem key={'all'} value={'all'}>
                        <Checkbox checked={(data && data.harvestData && data.harvestData.length-1) ===  (selectedFieldCheckbox && selectedFieldCheckbox.length)}
                          sx={{
                            '&.Mui-checked': {
                              color: '#00696D'
                            },
                          }}
                        />
                        <ListItemText primary={intl.formatMessage({ id: 'ALL_FIELDS' })} style={dropdownCss} sx={{ paddingRight: '8px' }} />
                      </MenuItem>
                      {data && data.harvestData.map((option, index) => (
                        index > 0 &&
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox checked={selectedFieldCheckbox.indexOf(option.id) > -1}
                            sx={{
                              '&.Mui-checked': {
                                color: '#00696D'
                              },
                            }}
                          />
                          <ListItemText primary={option.name} style={dropdownCss} sx={{ paddingRight: '8px' }} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack>
                    <FormControl fullWidth>
                      <Select
                        value={selectYear}
                        displayEmpty
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        }}
                      >
                        {yearComponent(onSelectChange)}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, paddingTop: '4%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box>
                <Stack>
                  <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
                    <Grid item style={tableHeading}>
                      <Typography style={textStyle}>&nbsp;</Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT13' })}
                      </Typography>
                      <Typography style={textSubStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT13_UNIT' })}
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT14' })}
                      </Typography>
                      <Typography style={textSubStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT14_UNIT' })}
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT15' })}
                      </Typography>
                      <Typography style={textSubStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT15_UNIT' })}
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT16' })}
                      </Typography>
                      <Typography style={textSubStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT16_UNIT' })}
                      </Typography>
                    </Grid>
                    {/*<Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT17' })}
                      </Typography>
                      <Typography style={textSubStyle}>
                        {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT17_UNIT' })}
                      </Typography>
                    </Grid>*/}
                  </Grid>
                  {fertilizationEfficiency && fertilizationEfficiency.allFieldsWithFertilization.length > 0 && selectedFieldCheckbox.length > 0 && 
                  _.filter((selectedNitrogen === 'over' ? fertilizationEfficiency.fieldsWithOverFertilization : (selectedNitrogen === 'under' ? fertilizationEfficiency.fieldsWithUnderFertilization : fertilizationEfficiency.allFieldsWithFertilization)), (field) => _.includes(selectedFieldCheckbox, field.id)).map((fields) => (
                  <Grid container style={gridContainerStyle} xs={12} sm={12} sx={{ borderBottom: '1px solid #BEC8C9' }}>
                    <Grid item style={tableHeading}>
                      <Typography style={textStyle}>
                        {fields.name}
                      </Typography>
                      <Typography style={textSubStyle}>
                        {fields.area} daa
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {fields.nbalance}
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {fields.nutilizationPercent}
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {fields.napplied}
                      </Typography>
                    </Grid>
                    <Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {fields.ncrop}
                      </Typography>
                    </Grid>
                    {/*<Grid item style={tableContent}>
                      <Typography style={textStyle}>
                        {fields.protein}
                      </Typography>
                    </Grid>*/}
                  </Grid>))}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>}
    </Box>
  </Box>
);
