import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Header from 'containers/header';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';
import _ from 'lodash';

import FertilizerSaveModal from 'containers/fertilization/planner/FertilizerSaveModal';
import * as FertilizerAction from 'actions/FertilizationPlan';
import * as MineralFertilizer from 'actions/MineralFertilizer';
import FertilizerManure from 'containers/fertilization/planner/FertilizerManure';
import MineralFertilizerHeader from 'containers/fertilization/planner/MineralFertilizerHeader';
import ExpandingContainer from '@tine/lib-frontend-components/components/accordion/expanding-container';
import FertilizerPlanListHeader from 'containers/fertilization/planner/FertilizerPlanListHeader';
import FertilizerPlanForField from 'containers/fertilization/planner/FertilizerPlanForField';
import FertilizerPlanForSeason from 'containers/fertilization/planner/FertilizerPlanForSeason';
import Loader from 'components/Loader';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import MineralFertilizerUpdateModal from 'containers/fertilization/planner/MineralFertilizerUpdateModal';
import mixpanel from 'mixpanel-browser';
import { SAVE_PLAN, CHANGE_MANURE_AMOUNT, CHANGE_MINERAL_AMOUNT, CHANGE_MINERAL_TYPE_START, CHANGE_MINERAL_TYPE_COMPLETE } from 'constants/MixPanel';
import {
  FILTER_TASK_STATUS,
  FILTER_TASK_ACTIVITY,
  FILTER_TASK_FIELD
} from 'constants/index';
import { SetSessionData } from 'utils';
import {
  taskListSortByOption
} from 'actions/Tasks';
import { TASK_PERIOD_VALUE } from 'constants/store/Tasks';

class FertilizerPlannerView extends React.Component {
  constructor(props) {
    super(props);
    this.updateSeasonManure = this.updateSeasonManure.bind(this);
    this.updateSeasonFertilizer = this.updateSeasonFertilizer.bind(this);
    this.updateMineralFertilizer = this.updateMineralFertilizer.bind(this);
    this.addMineralFertilizer = this.addMineralFertilizer.bind(this);
    this.deleteMineralFertilizer = this.deleteMineralFertilizer.bind(this);
    this.closeEditMineralFertilizer = this.closeEditMineralFertilizer.bind(this);
    this.setMineralFertilizerState = this.setMineralFertilizerState.bind(this);
    this.saveMineralFertilizer = this.saveMineralFertilizer.bind(this);
    this.addNewMineralFertilizer = this.addNewMineralFertilizer.bind(this);
    this.closeCreateTaskModel=this.closeCreateTaskModel.bind(this);
    this.closeCreateTaskSaveModel=this.closeCreateTaskSaveModel.bind(this);
    this.createTaskModel=this.createTaskModel.bind(this);
    this.updateCompleted=this.updateCompleted.bind(this);
    this.goToTaskPage=this.goToTaskPage.bind(this);
    this.state = {
      editMineralFertilizer: false,
      viewFertilizerPlan: false,
      mineralFertilizerValues: null,
      selectedMineralFertilizer: null,
      editMineralFertilizerValue: 'add',
      mineralFertilizerList: [],
      addingFertilizer: [],
      addingFertilizerLeft: [],
      addMineral: false,
      fertilizerCount: 0,
      totalFertilizerCount: 0,
      createTaskSave: false,
      createTaskSaveConfirm: false,
      createTaskPopup: false,
      taskPage: false
    };
    this.seasonTimer = null;
    this.manureTimer = null;
  }

  componentDidMount = () => {
    const { fertilizationPlan, mineralFertilizerList, match } = this.props;
    fertilizationPlan.isSuccess = false;
    if (match.params.id === 'view') {
      this.setState({ viewFertilizerPlan: true });
    }
    mineralFertilizerList();
    if(fertilizationPlan.data) {
      let keyPair = {};
      let keyPair1 = {};
      let totalCount = 0;
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.consolidatedMineralFertilizersForSeason ? mineralFertilizerItem.consolidatedMineralFertilizersForSeason.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            totalCount = totalCount + 1,
            keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.plannedQuantity === null ? 0 : seasonFertilizer.plannedQuantity.toFixed(1)
          )) :  mineralFertilizerItem.mineralFertilizersForSeason.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            totalCount = totalCount + 1,
            keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
          ))
        ))
      ));
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.mineralFertilizersForSeasonLeft.map((seasonFertilizer, index) => (
            //eslint-disable-next-line
            keyPair1[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
            seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
          ))
        ))
      ));
      this.setState({
        addingFertilizer: keyPair,
        addingFertilizerLeft: keyPair1,
        fertilizerCount: totalCount
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, fertilizationPlan } = this.props;
    const { createTaskSaveConfirm, createTaskPopup } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/fertilization');
    }
    if(fertilizationPlan.saveSuccess && !createTaskSaveConfirm && createTaskPopup && !fertilizationPlan.hasSaveError) {
      this.setState({
        createTaskSave: false,
        createTaskSaveConfirm: true
      });
    }
    if(fertilizationPlan.data) {
      let totalCount = 0;
      _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
        fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
          mineralFertilizerItem.mineralFertilizersForSeason.map(() => (
            totalCount = totalCount + 1
          ))
        ))
      ));
      if(this.state.totalFertilizerCount !== totalCount) {
        this.setState({
          totalFertilizerCount: totalCount
        });
      }
      if(this.state.addMineral && totalCount !== 0 && this.state.fertilizerCount === totalCount) {
        this.setState({addingFertilizer: [], addingFertilizerLeft: []});
        let keyPair = {};
        _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
          fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
            mineralFertilizerItem.consolidatedMineralFertilizersForSeason ? mineralFertilizerItem.consolidatedMineralFertilizersForSeason.map((seasonFertilizer, index) => (
              keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
              seasonFertilizer.plannedQuantity === null ? 0 : seasonFertilizer.plannedQuantity.toFixed(1)
            )) : mineralFertilizerItem.mineralFertilizersForSeason.map((seasonFertilizer, index) => (
              keyPair[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
              seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
            ))
          ))
        ));
        let keyPair1 = {};
        _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(fieldFertilizationItem => (
          fieldFertilizationItem.seasonFertilizerPlans.map(mineralFertilizerItem => (
            mineralFertilizerItem.mineralFertilizersForSeasonLeft.map((seasonFertilizer, index) => (
              keyPair1[mineralFertilizerItem.seasonId + "~~" + fieldFertilizationItem.fieldId + "~~" + index] = 
              seasonFertilizer.originalQuantity === null ? 0 : seasonFertilizer.originalQuantity.toFixed(1)
            ))
          ))
        ));
        this.setState({
          addingFertilizer: keyPair,
          addingFertilizerLeft: keyPair1,
          addMineral: false
        });
      }
    }
  }

  updateSeasonManure = event => {
    const { updateFertilizationPlan, fertilizationPlan, activeFarm, userDetails } = this.props;
    const targetValue = event.target.name.split('_');
    let floatValue = event.target.value.replace(',', '.');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      manureQuantity: parseFloat(floatValue),
      seasonId: parseFloat(targetValue[0]),
      manurePlanId: targetValue[2],
      fieldId: targetValue[1]
    };
    this.setState({
      addMineral: false
    }); 
    if (floatValue && parseFloat(floatValue) > 0) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(CHANGE_MANURE_AMOUNT);
      clearTimeout(this.manureTimer);
      this.manureTimer = setTimeout(() => updateFertilizationPlan('manure', activeFarm.farm.id, formDataObject), 1500);
      //return () => clearTimeout(timeOut);
    }
  };

  updateMineralFertilizer = event => {
    const { initialize, userDetails } = this.props;
    let dataValue = event.target.attributes.name.nodeValue.split('~~');
    this.setState({
      editMineralFertilizer: true,
      mineralFertilizerValues: dataValue,
      editMineralFertilizerValue: 'update',
      addMineral: false
    });
    const formValues = {
      updateMineralFertilizer: { label: dataValue[2], value: dataValue[2] }
    };
    mixpanel.identify(userDetails.id);
    mixpanel.track(CHANGE_MINERAL_TYPE_START);
    initialize(formValues);
  };

  updateCompleted = event => {
    const { fertilizationPlan, clickOnCompleted, activeFarm } = this.props;
    let dataValue = event.target.attributes.id.nodeValue.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      fieldId: dataValue[1],
      completed: dataValue[2] === "true" ? true : false,
    };
    clickOnCompleted(activeFarm.farm.id, formDataObject);
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  updateCompletedAll = event => {
    const { fertilizationPlan, clickOnCompleted, activeFarm } = this.props;
    let dataValue = event.target.attributes.id.nodeValue.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      completed: dataValue[1] === "true" ? true : false,
    };
    clickOnCompleted(activeFarm.farm.id, formDataObject);
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  addMineralFertilizer(event) {
    const { initialize } = this.props;
    const formValues = {
      updateMineralFertilizer: { label: '', value: '' }
    };
    initialize(formValues);
    let dataValue = event.split('~~');
    this.setState({
      editMineralFertilizer: true,
      editMineralFertilizerValue: 'add',
      mineralFertilizerValues: dataValue,
      addMineral: false,
      fertilizerCount: this.state.totalFertilizerCount
    })
  }

  setMineralFertilizerState = event => {
    this.setState({
      selectedMineralFertilizer: event.value,
      addMineral: false
    });
  };

  deleteMineralFertilizer = event => {
    const { fertilizationPlan, deleteMineralFertilizer, activeFarm } = this.props;
    let dataValue = event.target.attributes.name.nodeValue.split('~~');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(dataValue[0]),
      fieldId: dataValue[1],
      mf: dataValue[2]
    };
    deleteMineralFertilizer(activeFarm.farm.id, formDataObject);
    if(this.state.fertilizerCount !== this.state.totalFertilizerCount){
      this.setState({
        addMineral: true,
        fertilizerCount: this.state.totalFertilizerCount !== 0 ? this.state.totalFertilizerCount - 1 : 0
      }); 
    } else {
      this.setState({
        addMineral: true,
        fertilizerCount: this.state.fertilizerCount !== 0 ? this.state.fertilizerCount - 1 : 0
      }); 
    }
      
    /*let trackValue = dataValue[2] + " is deleted ";
    mixpanel.identify(userDetails.id);
    mixpanel.track(DeleteMineralTypeComplete,{"MineralFertilizerTypeDelete": trackValue});*/
  };

  addNewMineralFertilizer = () => {
    const { fertilizationPlan, addMineralFertilizer, activeFarm } = this.props;
    const { selectedMineralFertilizer, mineralFertilizerValues } = this.state;
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(mineralFertilizerValues[0]),
      fieldId: mineralFertilizerValues[1],
      mf: selectedMineralFertilizer,
      quantity: 0.0
    };
    addMineralFertilizer(activeFarm.farm.id, formDataObject);
    this.setState({
      editMineralFertilizer: false,
      addMineral: true,
      fertilizerCount: this.state.fertilizerCount + 1
    });
  };

  saveMineralFertilizer = () => {
    const { fertilizationPlan, updateMineralFertilizer, activeFarm, userDetails } = this.props;
    const { selectedMineralFertilizer, mineralFertilizerValues } = this.state;
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseInt(mineralFertilizerValues[0]),
      fieldId: mineralFertilizerValues[1],
      previousMF: mineralFertilizerValues[2],
      customMF: selectedMineralFertilizer
    };
    updateMineralFertilizer(activeFarm.farm.id, formDataObject);
    let trackValue = mineralFertilizerValues[2] + " is changed to " + selectedMineralFertilizer;
    mixpanel.identify(userDetails.id);
    mixpanel.track(CHANGE_MINERAL_TYPE_COMPLETE,{"MineralFertilizerTypeChange": trackValue});
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  closeEditMineralFertilizer = () => {
    this.setState({
      editMineralFertilizer: false,
      addMineral: false
    });
  };

  updateSeasonFertilizer = event => {
    const { updateFertilizationPlan, fertilizationPlan, activeFarm, userDetails } = this.props;
    let { id, value }= event.target;
    this.setState(prevState => ({
      addingFertilizer: {
        ...prevState.addingFertilizer,
        [id]: value
      },
      addMineral: false
    }));
    const targetValue = event.target.name.split('~~');
    let floatValue = event.target.value.replace(',', '.');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseFloat(targetValue[0]),
      fieldId: targetValue[1],
      mf: targetValue[2],
      quantity: parseFloat(floatValue)
    };
    if (floatValue && parseFloat(floatValue) > 0) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(CHANGE_MINERAL_AMOUNT);
      clearTimeout(this.seasonTimer);
      this.seasonTimer = setTimeout(() => updateFertilizationPlan('season', activeFarm.farm.id, formDataObject), 1500);
    }
  };

  updateSeasonFertilizerLeft = event => {
    const { updateFertilizationPlan, fertilizationPlan, activeFarm, userDetails } = this.props;
    let { id, value }= event.target;
    this.setState(prevState => ({
      addingFertilizerLeft: {
        ...prevState.addingFertilizerLeft,
        [id]: value
      },
      addMineral: false
    }));
    const targetValue = event.target.name.split('~~');
    let floatValue = event.target.value.replace(',', '.');
    const formDataObject = {
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version,
      seasonId: parseFloat(targetValue[0]),
      fieldId: targetValue[1],
      mf: targetValue[2],
      quantity: parseFloat(floatValue)
    };
    if (floatValue && parseFloat(floatValue) > 0) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(CHANGE_MINERAL_AMOUNT);
      clearTimeout(this.seasonTimer);
      this.seasonTimer = setTimeout(() => updateFertilizationPlan('season', activeFarm.farm.id, formDataObject), 1500);
    }
  };

  handleSubmit = values => {};

  getMineralFertilizer = () => {
    const { fertilizationPlan } = this.props;
    const { viewFertilizerPlan } = this.state;
    if (fertilizationPlan.data.mineralFertilizers?.length > 0) {
      return fertilizationPlan.data.mineralFertilizers.map((mineralFertilizerItem, index) => (
        <div className="fertilization-box" key={index}>
          <MineralFertilizerHeader
            mineralFertilizerItem={mineralFertilizerItem}
            viewFertilizerPlan={viewFertilizerPlan}
          />
        </div>
      ));
    }
  };

  getFieldFertilization = () => {
    const { fertilizationPlan, intl } = this.props;
    const { isLoading } = fertilizationPlan;
    const { viewFertilizerPlan, addingFertilizer, addingFertilizerLeft } = this.state;
    if (fertilizationPlan.data.fieldFertilizations?.length > 0) {
      return _.orderBy(fertilizationPlan.data.fieldFertilizations, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem,index) => (
        <div
          key={fertilizationPlan.data.fieldFertilizations.fieldId + '_' + index}
          className="padding-bottom-alignment"
        >
          <ExpandingContainer
            collapsedContent={
              <FertilizerPlanListHeader 
                fieldFertilizationItem={fieldFertilizationItem}
                intl={intl}
              />
            }
            expanded={index === 0}
            expandedContent={
              <div>
                <FertilizerPlanForField 
                  fieldFertilizationItem={fieldFertilizationItem}
                  intl={intl}
                />
                <FertilizerPlanForSeason
                  fieldFertilizationItem={fieldFertilizationItem}
                  updateSeasonManure={this.updateSeasonManure}
                  updateMineralFertilizer={this.updateMineralFertilizer}
                  addMineralFertilizer={this.addMineralFertilizer}
                  deleteMineralFertilizer={this.deleteMineralFertilizer}
                  updateSeasonFertilizer={this.updateSeasonFertilizer}
                  viewFertilizerPlan={viewFertilizerPlan}
                  isLoading={isLoading}
                  intl={intl}
                  addingFertilizer={addingFertilizer}
                  addingFertilizerLeft={addingFertilizerLeft}
                  updateCompleted={this.updateCompleted}
                />
              </div>
            }
            withTimeline={false}
          />
        </div>
      ));
    }
  };

  getEmptyFieldFertilization = () => {
    const { fertilizationPlan, intl } = this.props;
    if (fertilizationPlan.data.fieldsWithInsufficientData?.length > 0) {
      return _.orderBy(fertilizationPlan.data.fieldsWithInsufficientData, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem,index) => (
          <div
            key={fertilizationPlan.data.fieldsWithInsufficientData.fieldId + '_' + index}
            className="padding-bottom-alignment"
          >
            <div className="empty-field-fertilizer">
              <div className="empty-field-grid">
                <div className="empty-field-title">{fieldFertilizationItem.fieldName}</div>
                <div className="empty-field-content">{fieldFertilizationItem.label}</div>
                <div className="empty-field-sub-content">
                  <ul>
                  {fieldFertilizationItem.missingAttributes.map((missingAttribute) => (
                    <li key={missingAttribute}>{missingAttribute}</li>
                  ))}
                  </ul>
                </div>
                {<div className="empty-field-button">
                  <Link to={`/managefields/${fieldFertilizationItem.fieldId}`} target="_blank">
                    <ActionButton secondary>
                      {intl.formatMessage({ id: 'GO_TO_FIELD_AND_ADD' })}
                    </ActionButton>
                  </Link>
                </div>}
              </div>
            </div>
          </div>
        )
      );
    }
  };

  getSaveButton() {
    const { fertilizationPlan, intl } = this.props;
    const { hasError, updateErrorMessage, hasSaveError, saveErrorMessage } = fertilizationPlan;
    let errorData = '';
    if (fertilizationPlan.data.fieldFertilizations?.length > 0) {
      if (hasError) {
        errorData = (
          <span>
            <ErrorText>
              {updateErrorMessage?.status === 500
                ? intl.formatMessage({ id: 'UNEXPECTED_ERR_MSG' })
                : updateErrorMessage?.data}
            </ErrorText>
          </span>
        );
      }
      if (hasSaveError) {
        errorData = (
          <span>
            <ErrorText>
              {saveErrorMessage?.status === 500
                ? intl.formatMessage({ id: 'UNEXPECTED_ERR_MSG' })
                : saveErrorMessage?.data}
            </ErrorText>
          </span>
        );
      }
      let saveButton = (
        <ActionButton onClick={() => this.createTaskSave()}>{intl.formatMessage({ id: 'SAVE' })}</ActionButton>
      );
      return (
        <div className="fixed-button">
          <div className="mineral-fertilizer-text-left">
            <span className="padding-right-class">{errorData}</span>
            <span className="padding-right-heavy">{saveButton}</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  createTaskSave = () => {
    this.setState({
      createTaskSave: true
    });
  }

  closeCreateTaskModel() {
    this.setState({
      createTaskSave: false,
      createTaskSaveConfirm: false,
      createTaskPopup: false
    });
  }

  closeCreateTaskSaveModel() {
    this.setState({
      createTaskSave: false,
      createTaskSaveConfirm: false,
      createTaskPopup: false
    });
    this.saveFertilizationPlan(false);
  }

  createTaskModel() {
    this.setState({
      createTaskSave: false,
      createTaskPopup: true
    });
    this.saveFertilizationPlan(true);    
  }

  goToTaskPage() {
    const { periodOption, sortyByOption } = this.props;
    SetSessionData(FILTER_TASK_STATUS, [{value: 'PLANNED', label: 'Planned'}]);
    SetSessionData(FILTER_TASK_ACTIVITY, [{value: 'act7', label: 'Fertilization'}]);
    sessionStorage.removeItem(FILTER_TASK_FIELD);
    this.setState({
      taskPage: true
    });
    periodOption(null);
    sortyByOption(
      {
        value: 'Date created',
        label: <FormattedMessage id="DATE_CREATED" />
      }
    );
  }

  saveFertilizationPlan = (createTask) => {
    const { fertilizationPlan, saveFertilizationPlan, activeFarm, userDetails } = this.props;
    const formDataObject = {
      planName: fertilizationPlan.data.planName,
      year: fertilizationPlan.data.year,
      version: fertilizationPlan.data.version
    };
    mixpanel.identify(userDetails.id);
    mixpanel.track(SAVE_PLAN);
    saveFertilizationPlan(activeFarm.farm.id, formDataObject, createTask);
  };

  render() {
    const { fertilizationPlan, mineralFertilizer, intl } = this.props;
    const { isLoading, saveSuccess } = fertilizationPlan;
    const { editMineralFertilizer, editMineralFertilizerValue, viewFertilizerPlan, createTaskSave, createTaskSaveConfirm, 
      taskPage, createTaskPopup } = this.state;
    return fertilizationPlan.data != null ? (
      <div className="manure-storage-list">
        {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        <Header
          bcLink={{ to: '/fertilization', text: intl.formatMessage({ id: 'FERTILIZATION_PLAN' }) }}
          bcTitle={fertilizationPlan.data.planName}
        />
        <div>
          <FertilizerManure 
            fertilizationPlan={fertilizationPlan}
            intl={intl}
          />
          <SectionContainer
            styling={{
              paddingTop: 'lowest',
              paddingBottom: 'lowest'
            }}
            className="fertilization-header-text"
          >
            <div className="fertilization-blue-header">
              <div className="fertilization-header-text">
                <span className="text-heading-font">{intl.formatMessage({ id: 'MINERAL_FERTILIZER' })}</span>
              </div>
              {this.getMineralFertilizer()}
            </div>
          </SectionContainer>
          <SectionContainer
            styling={{
              paddingTop: 'lowest',
              paddingBottom: 'lowest'
            }}
            className="fertilization-header-text"
          >
            <div>
              <div>
                {intl.formatMessage({ id: 'FERTILIZATION_CHECKBOX_HEADING' })} 
              </div>
              <div className="grid-layout-farm-header">
                {fertilizationPlan.data && fertilizationPlan.data.seasonStatus && fertilizationPlan.data.seasonStatus.map(seasons => (
                    <div>
                      <span className="padding-completed content-float-left">
                        {seasons.id === 1 ? intl.formatMessage({ id: 'FERTILIZATION_CHECKBOX_SPRING_FERTILIZATION' }) : 
                        seasons.id === 2 ? intl.formatMessage({ id: 'FERTILIZATION_CHECKBOX_SEASON1' }) :
                        seasons.id === 3 ? intl.formatMessage({ id: 'FERTILIZATION_CHECKBOX_SEASON2' }) : 
                        intl.formatMessage({ id: 'FERTILIZATION_CHECKBOX_SEASON3' })}
                      </span>
                      <span className="location-type-checkbox-width content-float-left">
                        <CheckboxButton
                          id={seasons.id === 1 ? '1~~'+!seasons.completed : 
                          seasons.id === 2 ? '2~~'+!seasons.completed :
                          seasons.id === 3 ? '3~~'+!seasons.completed : 
                          '4~~'+!seasons.completed}
                          label="completed"
                          labelText=""
                          stlyeClass="font-style-div"
                          checked={seasons.completed}
                          disabled={viewFertilizerPlan || isLoading}
                          onChange={e => this.updateCompletedAll(e)}
                        />
                      </span>
                    </div>
                ))}
              </div>
            </div>
          </SectionContainer>
          <SectionContainer
            styling={{
              paddingTop: 'none',
              paddingBottom: 'lowest'
            }}
            className="fertilization-header-text"
          >
            {this.getFieldFertilization()}
            {this.getEmptyFieldFertilization()}
          </SectionContainer>
          {!viewFertilizerPlan && this.getSaveButton()}
        </div>
        {saveSuccess && !createTaskPopup && <Redirect to="/fertilization" />}
        {taskPage && <Redirect to="/tasks" />}
        <MineralFertilizerUpdateModal
          editMineralFertilizer={editMineralFertilizer}
          editMineralFertilizerValue={editMineralFertilizerValue}
          closeEditMineralFertilizer={this.closeEditMineralFertilizer}
          mineralFertilizer={mineralFertilizer}
          setMineralFertilizerState={this.setMineralFertilizerState}
          saveMineralFertilizer={this.saveMineralFertilizer}
          addNewMineralFertilizer={this.addNewMineralFertilizer}
          intl={intl}
        />
        <FertilizerSaveModal
          onCreateTaskSave={createTaskSave}
          onCreateTaskSaveConfirm={createTaskSaveConfirm}
          closeCreateTaskModel={this.closeCreateTaskModel}
          closeCreateTaskSaveModel={this.closeCreateTaskSaveModel}
          createTaskModel={this.createTaskModel}
          fertilizationPlan={fertilizationPlan}
          goToTaskPage={this.goToTaskPage}
          intl={intl}
        />
      </div>
    ) : (
      <Redirect to="/fertilization" />
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fertilizationPlan: state.fertilizationPlan,
  mineralFertilizer: state.mineralFertilizer,
  userDetails: state.userDetails.data
});

function mapDispatchToProps(dispatch) {
  return {
    updateFertilizationPlan: (updateField, farmId, data) =>
      dispatch(FertilizerAction.updateFertilizationPlan(updateField, farmId, data)),
    saveFertilizationPlan: (farmId, data, createTask) =>
      dispatch(FertilizerAction.saveFertilizationPlan(farmId, data, createTask)),
    mineralFertilizerList: () => dispatch(MineralFertilizer.mineralFertilizerList()),
    updateMineralFertilizer: (farmId, data) =>
      dispatch(FertilizerAction.updateMineralFertilizer(farmId, data)),
    deleteMineralFertilizer: (farmId, data) =>
      dispatch(FertilizerAction.deleteMineralFertilizer(farmId, data)),
    addMineralFertilizer: (farmId, data) =>
      dispatch(FertilizerAction.addMineralFertilizer(farmId, data)),
    sortyByOption: option => dispatch(taskListSortByOption(option)),
    periodOption: days => dispatch({ type: TASK_PERIOD_VALUE, taskPeriod: days }),
    clickOnCompleted: (farmId, data) => dispatch(FertilizerAction.clickOnCompleted(farmId, data))
  };
}

export default injectIntl(
  reduxForm({
    form: 'FertilizerPlannerView',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FertilizerPlannerView)))
);
