import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import * as subscriptionAction from 'actions/Subscription';
import SubscriptionList from 'mui-components/settings/subscription/SubscriptionList';
import Loader from 'components/Loader';

class ManageSubscription extends React.Component {
  componentDidMount() {
    const { getSubscriptionList, activeFarm } = this.props;
    if (activeFarm) {
      getSubscriptionList();
    }
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, getSubscriptionList } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      getSubscriptionList();
    }
  }

  render() {
    const { activeFarm, hasSupportAdminRole, subscriptionDetails } = this.props;
    const { isLoading, hasError, errorMessage, subscriptionList } = subscriptionDetails;
    const enableSubscription = (activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data);
    return (
      <div className="asset-list-wrapper">
        {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        {subscriptionList?.length > 0 && (
          <SubscriptionList subscriptionList={subscriptionList} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSubscriptionList: () => dispatch(subscriptionAction.fetchSubscriptionUsers())
});

const mapStateToProps = state => ({
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  rolePermissions: state.RolesDetails,
  subscriptionDetails: state.subscription,
  hasSupportAdminRole: state.SupportAdminRole
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageSubscription));
