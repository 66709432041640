import React from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

class SearchGrassAnalysisCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl, item, qrCodeKey } = this.props;
    const gridTitleItemStyle = { flex: 3, paddingLeft: 5, paddingRight: 5 };
    const gridTitleItemStyleEmpty = { paddingLeft: 5, paddingRight: 5 };
    return (
      <li className={`list-item-grass full-width`} key={""} sx={{ padding: '8px !important' }}>
        {item !== null ?
        <Link
          className={`field-list-item-link`}
          to="#"
        >
          <Grid container direction={{ xs: 'column', sm: 'row' }}>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_1' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.qrCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_2' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.farmName}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_3' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.orgNo}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_4' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.analysisType}</Typography>
            </Grid>
          </Grid>
          <Grid container direction={{ xs: 'column', sm: 'row' }}>&nbsp;</Grid>
          <Grid container direction={{ xs: 'column', sm: 'row' }}>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_5' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.registeredDate}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_6' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.analysisDate}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_7' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.createdBy}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_8' })}</Typography>
              <Typography className='field-list-font-subtitle'>{item.updatedBy}</Typography>
            </Grid>
          </Grid>
        </Link> : 
        <Link
          className={`field-list-item-link`}
          to="#"
        >
          <Grid container direction={{ xs: 'column', sm: 'row' }}>
            <Grid item xs={12} sm={2} md={2} style={gridTitleItemStyleEmpty}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_1' })}</Typography>
              <Typography className='field-list-font-subtitle'>{qrCodeKey}</Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={10} style={gridTitleItemStyleEmpty}>
              <Typography className='field-list-font-title'>{intl.formatMessage({ id: 'GRASS_ANALYSIS_TEXT_ERROR' })}</Typography>
              <Typography className='field-list-font-subtitle'>&nbsp;</Typography>
            </Grid>
          </Grid>
      </Link>
        }
      </li>
    );
  }
}

export default injectIntl(SearchGrassAnalysisCard);
