import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getFormValues, Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import { TemplateFormFields } from 'containers/tasks/batch/templates/TemplateFields';
import SelectControl from 'components/forms/SelectBoxControl';
import TextBoxControl from 'components/forms/TextBoxControl';
import TextAreaControl from 'components/forms/TextAreaControl';
import isMatch from 'date-fns/isMatch'
import * as taskActions from 'actions/Tasks';
import Loader from 'components/Loader';
import _ from 'lodash';
import {
  SecondsTohoursMinutes,
  GetDateObj,
  GetValuesFromDate,
  ParseISOString,
  PadString,
  AmountValidation,
  getValueByDateAndTime
} from 'utils';
import {
  ACT20,
  ACT1,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT18,
  ACT28,
  ACT13,
  ACT16
} from 'constants/index';

class Template extends Component {
  constructor(props) {
    super(props);
    const { taskList } = this.props;
    this.state = {
      initializeState: false,
      errorFieldsState: null
    };
    let newState = {};
    taskList.forEach((list, index) => {
      newState[`startDate_${index}`] = null;
      newState[`endDate_${index}`] = null;
      newState[`startTime_${index}`] = null;
      newState[`endTime_${index}`] = null;
      newState[`updateDuration_${index}`] = false;
      newState[`stateFieldName_${index}`] = "";
    });
    this.setState(newState);
  }

  componentDidMount() {
    const { taskList } = this.props;
    taskList.map((list, index) =>
      this.fetchDateTime(list, index)
    );
    this.setState({
      initializeState: true
    });
  }


  componentDidUpdate() {
    const { initializeState } = this.state;
    if(initializeState) {
      this.initializeListValues();
      this.setState({
        initializeState: false
      });
    }
  }

  initializeListValues = () => {
    const { taskList } = this.props;
    taskList.map((list, index) =>
      this.initializeValues(list, index)
    );
  }

  initializeValues = (list, index) => {
    const { locations } = this.props;
    const tempStartDateTime = list.startTime
        ? GetValuesFromDate(new Date(ParseISOString(list.startTime)))
        : { date: '', time: '' };
    const tempEndDateTime = list.endTime
        ? GetValuesFromDate(new Date(ParseISOString(list.endTime)))
        : { date: '', time: '' };
    const { intl, change } = this.props;
    if (list.fieldId !== null) {
      this.setState({
        [`stateFieldName_${index}`]: list.fieldId
      });
    }
    if (list.vehicleAssetId !== null) {
      let selectedOption = {
        value: list.vehicleAssetId,
        label: `${list.vehicleAssetBrand} ${list.vehicleAssetModel}`
      };
      change(`vehicle_${index}`, selectedOption);
    }
    if (list.equipmentAssetId !== null) {
      let selectedOption = {
        value: list.equipmentAssetId,
        label: `${list.equipmentAssetBrand} ${list.equipmentAssetModel}`
      };
      change(`equipment_${index}`, selectedOption);
    }
    if (list.startTime !== null || list.startTime !== '') {
      change(`startTime_${index}`, tempStartDateTime.date+" "+tempStartDateTime.time);
    }
    if (list.endTime !== null || list.endTime !== '') {
      change(`endTime_${index}`, tempEndDateTime.date+" "+tempEndDateTime.time);
    }
    if (list.duration !== null) {
      change(`duration_${index}`, list.duration);
    }
    if (list.weather !== null) {
      let labelValue = "";
      if(list.weather === 'sunny') {
        labelValue = intl.formatMessage({ id: "SUNNY" })
      } else if(list.weather === 'cloudy') {
        labelValue = intl.formatMessage({ id: "CLOUDY" })
      } else if(list.weather === 'sun_and_rain') {
        labelValue = intl.formatMessage({ id: "SUNANDRAIN" })
      } else if(list.weather === 'overcast') {
        labelValue = intl.formatMessage({ id: "OVERCAST" })
      } else {
        labelValue = intl.formatMessage({ id: "RAIN" })
      }
      let selectedOption = {
        value: list.weather,
        label: labelValue
      };
      change(`weather_${index}`, selectedOption);
    }
    if (list.assigneeId !== null) {
      let selectedOption = {
        value: list.assigneeId,
        label: list.assigneeFirstName+" "+list.assigneeLastName
      };
      change(`assignee_${index}`, selectedOption);
    }
    if (list.notes !== null) {
      change(`notes_${index}`, list.notes);
    }
    if(list.dynamicData !== null) {
      const dynamicDataObj = JSON.parse(list.dynamicData);
      if(dynamicDataObj.mix_text) {
        change(`seedMix_${index}`, dynamicDataObj.mix_text);
      }
      if(dynamicDataObj.amount) {
        change(`amount_${index}`, dynamicDataObj.amount);
      }
      if(dynamicDataObj.unit) {
        let selectedOption = {
          value: dynamicDataObj.unit,
          label: dynamicDataObj.unit
        };
        change(`unit_${index}`, selectedOption);
      }
      if(dynamicDataObj.cut_text) {
        let selectedOption = {
          value: dynamicDataObj.cut_text,
          label: dynamicDataObj.cut_text
        };
        change(`cut_${index}`, selectedOption);
      }
      if(dynamicDataObj.cut_amount) {
        change(`cutamount_${index}`, dynamicDataObj.cut_amount);
      }
      if(dynamicDataObj.cut_unit) {
        let selectedOption = {
          value: dynamicDataObj.cut_unit,
          label: dynamicDataObj.cut_unit
        };
        change(`cutunit_${index}`, selectedOption);
      }
      if(dynamicDataObj.ensilation_text) {
        change(`ensilationtype_${index}`, dynamicDataObj.ensilation_text);
      }
      if(dynamicDataObj.ensilation_amount) {
        change(`ensilationamount_${index}`, dynamicDataObj.ensilation_amount);
      }
      if(dynamicDataObj.ensilation_unit) {
        let selectedOption = {
          value: dynamicDataObj.ensilation_unit,
          label: dynamicDataObj.ensilation_unit
        };
        change(`ensilationunit_${index}`, selectedOption);
      }
      if(dynamicDataObj.drymatter) {
        change(`drymatter_${index}`, dynamicDataObj.drymatter);
      }
      if(dynamicDataObj.weight) {
        change(`weight_${index}`, dynamicDataObj.weight);
      }
      if(dynamicDataObj.locationId) {
        const selecteItem = _.filter(locations, ['id', dynamicDataObj.locationId]);
        if(selecteItem.length > 0) {
          let selectedOption = {
            value: selecteItem[0].id,
            label: selecteItem[0].name
          };
          change(`locationId_${index}`, selectedOption);
        }
      }
      if(dynamicDataObj.total_harvest_text) {
        change(`totalharvest_${index}`, dynamicDataObj.total_harvest_text);
      }
      if(dynamicDataObj.water_percent) {
        change(`waterpercentage_${index}`, dynamicDataObj.water_percent);
      }
      if(dynamicDataObj.mineralFerti_type) {
        let selectedOption = {
          value: dynamicDataObj.mineralFerti_type,
          label: dynamicDataObj.mineralFerti_type
        };
        change(`fertilizertype_${index}`, selectedOption);
      }
      if(dynamicDataObj.combo_ferti_amount) {
        change(`fertilizeramount_${index}`, dynamicDataObj.combo_ferti_amount);
      }
      if(dynamicDataObj.combo_ferti_unit) {
        let selectedOption = {
          value: dynamicDataObj.combo_ferti_unit,
          label: dynamicDataObj.combo_ferti_unit
        };
        change(`mineralfertilizerunit_${index}`, selectedOption);
      }
      if(dynamicDataObj.sowing_type_text) {
        change(`seedmix_${index}`, dynamicDataObj.sowing_type_text);
      }
      if(dynamicDataObj.combo_sowing_amount) {
        change(`sowingamount_${index}`, dynamicDataObj.combo_sowing_amount);
      }
      if(dynamicDataObj.combo_sowing_unit) {
        let selectedOption = {
          value: dynamicDataObj.combo_sowing_unit,
          label: dynamicDataObj.combo_sowing_unit
        };
        change(`sowingunit_${index}`, selectedOption);
      }
    }
  }

  fetchVehicle = selectedVehicle => {
    const { taskDetails } = this.props;
    const { allVehicles } = taskDetails;
    return _.filter(
      allVehicles,
      item =>
        selectedVehicle.label === `${item.asset.brand} ${item.asset.model}` &&
        selectedVehicle.value === item.asset.assetId
    )[0];
  };

  fetchEquipment = selectedEquipment => {
    const { taskDetails } = this.props;
    const { allEquipments } = taskDetails;
    return _.filter(
      allEquipments,
      item =>
        selectedEquipment.label === `${item.asset.brand} ${item.asset.model}` &&
        selectedEquipment.value === item.asset.assetId
    )[0];
  };

  fetchDateTime = (list,index) => {
    if (list.startTime || list.endTime) {
      const tempStartDateTime = list.startTime
        ? GetValuesFromDate(new Date(ParseISOString(list.startTime)))
        : { date: '', time: '' };
      const tempEndDateTime = list.endTime
        ? GetValuesFromDate(new Date(ParseISOString(list.endTime)))
        : { date: '', time: '' };
      this.setState({
        [`startDate_${index}`]: tempStartDateTime.date,
        [`endDate_${index}`]: tempEndDateTime.date,
        [`startTime_${index}`]: tempStartDateTime.time,
        [`endTime_${index}`]: tempEndDateTime.time
      });
    }
  };

  calculateDuration = (list,index) => {
    let startDate = this.state[`startDate_${index}`];
    let startTime = this.state[`startTime_${index}`];
    let endDate = this.state[`endDate_${index}`];
    let endTime = this.state[`endTime_${index}`];
    let updateDuration = this.state[`updateDuration_${index}`];
    if(updateDuration) {
      const sDate = startDate && GetDateObj(startDate, startTime);
      const eDate = endDate && GetDateObj(endDate, endTime);
      if(sDate && eDate && eDate >= sDate) {
        const diffInMs = Math.abs(eDate - sDate);
        const hrsMin = SecondsTohoursMinutes(diffInMs / (1000));
        return `${PadString(hrsMin.hours)} : ${PadString(hrsMin.minutes)}`;
      }
    } else {
      if(list && list.netDuration !== null && list.netDuration !== 0) {
        const hrsMin = SecondsTohoursMinutes(list.netDuration);
        return `${PadString(hrsMin.hours)} : ${PadString(hrsMin.minutes)}`;
      } else if(list && list.netDuration === 0) {
        return '00 : 00';
      } else {
        return '-- : --';
      }
    }
    return '-- : --';
  };

  handleDateChange(e, name, index) {
    let splitDate = e.target.value.split(" ");
    if(name === 'startTime') {
      this.setState({  
        [`startDate_${index}`]: splitDate[0],
        [`startTime_${index}`]: splitDate[1],
        [`updateDuration_${index}`]: true
      });
    } else {
      this.setState({  
        [`endDate_${index}`]: splitDate[0],
        [`endTime_${index}`]: splitDate[1],
        [`updateDuration_${index}`]: true
      });
    }
  }

  getHeaderList = () => {
    const { intl, actId, activitiesCommon, fieldDetailsOptionsArray, locations } = this.props;
    const fields = TemplateFormFields(actId, activitiesCommon, locations, fieldDetailsOptionsArray);
    return fields.map(field =>
      <td className={field['headerClass']} key={field['name']}>
        {intl.formatMessage({ id: field['label'] })}
      </td>
    );
  }

  getValueList = () => {
    const { taskList } = this.props;
    return taskList.map((list, index) =>
      <tr className='task-batch-border-top' key={index}>
        {this.getValueFieldList(list, index)}
      </tr>
    );
  }

  handleSelectChange = (event, index) => {
    this.setState({
      [`stateFieldName_${index}`]: event.target.value
    });
  };

  getValueFieldList = (list, index) => {
    const { actId, activitiesCommon, fieldDetailsOptionsArray, locations } = this.props;
    const fields = TemplateFormFields(actId, activitiesCommon, locations, fieldDetailsOptionsArray);
    const { intl } = this.props;
    let data = "";
    data = fields.map(field =>
      field['input_type'] === 'fielddropdown' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <div className="dropdowns dropdown-padding">
          <select value={this.state[`stateFieldName_${index}`]} name={`${field['name']}_${index}`} 
            onChange={(value) => this.handleSelectChange(value, index)} className="customized-select-dropdown customized-select-dropdown-min-width">
            {field['options'].map((option, index) => (
              <option key={index} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </td>
      : field['input_type'] === 'dropdown' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <div className="dropdowns">
          <Field
            name={`${field['name']}_${index}`}
            component={SelectControl}
            styleName={field['stlyeClass']}
            placeholder={intl.formatMessage({ id: field['placeholder'] })}
            controlId={`${field['name']}_${index}`}
            options={field['options']}
          />
        </div>
      </td>
      : field['input_type'] === 'subdropdown' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <div className="dropdowns">
          <Field
            name={`${field['name']}_${index}`}
            component={SelectControl}
            styleName={field['stlyeClass']}
            placeholder={intl.formatMessage({ id: field['placeholder'] })}
            controlId={`${field['name']}_${index}`}
            options={field['options']}
          />
        </div>
      </td>
      : field['input_type'] === 'text' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <Field
          name={`${field['name']}_${index}`}
          component={TextBoxControl}
          controlId={`${field['name']}_${index}`}
          type="text"
          stlyeClass={`${field['stlyeClass']}`}
          placeholdertext={intl.formatMessage({ id: field['placeholder'] })}
          disabled={field['disabled']}
          units={field.units ? intl.formatMessage({ id: field.units }) ? intl.formatMessage({ id: field.units }) : field.units : null}
        />
      </td>
      : field['input_type'] === 'textarea' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <Field
          name={`${field['name']}_${index}`}
          component={TextAreaControl}
          controlId={`${field['name']}_${index}`}
          stlyeClass={field['stlyeClass']}
        />
      </td>
      : field['input_type'] === 'label' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <span className="input-content-task-batch">{this.calculateDuration(list,index)}</span>
      </td> 
      : field['input_type'] === 'date' ?
      <td className={field['valueClass']} key={`${field['name']}_${index}`}>
        <Field
          name={`${field['name']}_${index}`}
          component={TextBoxControl}
          controlId={`${field['name']}_${index}`}
          type="text"
          stlyeClass={field['stlyeClass']}
          placeholdertext={intl.formatMessage({ id: field['placeholder'] })}
          disabled={field['disabled']}
          onChange={(value) => this.handleDateChange(value, field['name'], index)}
        />
      </td>
      : <td>&nbsp;</td>
    )
    return data;
  }

  handleSubmit = values => {
    const { taskList, createTask, activeFarm, changeSetSuccessMessage } = this.props;
    const { errorFieldsState } = this.state;
    const errors = validate(values, this.props);
    if(errorFieldsState === null) {
      this.setState({
        errorFieldsState: errors
      });
    }
    if (Object.keys(errors).length > 0) {
      handleFocusOnError(errors, errorFieldsState);
      return;
    }

    let taskValueArray = [];
    taskList.map((task, index) => {
      const taskValue = {
        "farmId": task.farmId,
        "activityId": task.activityId,
        "fieldId": this.state[`stateFieldName_${index}`]
      }
      if(values[`vehicle_${index}`]) {
        let vehicle = this.fetchVehicle(values[`vehicle_${index}`]);
        taskValue["vehicleAssetId"] = vehicle && vehicle.asset && vehicle.asset.assetId ? vehicle.asset.assetId : null;
        taskValue["vehicleAssetAssignmentId"] = vehicle && vehicle.assetAssignmentId ? vehicle.assetAssignmentId : null;
      }
      if(values[`equipment_${index}`]) {
        let equipment = this.fetchEquipment(values[`equipment_${index}`]);
        taskValue["equipmentAssetId"] = equipment && equipment.asset && equipment.asset.assetId ? equipment.asset.assetId : null;
        taskValue["equipmentAssetAssignmentId"] = equipment && equipment.assetAssignmentId ? equipment.assetAssignmentId : null;
      }
      if(values[`assignee_${index}`]) {
        taskValue["assigneeId"] = values[`assignee_${index}`].value;
      }
      if(values[`weather_${index}`]) {
        taskValue["weather"] = values[`weather_${index}`].value;
      }
      if(task.status) {
        taskValue["status"] = task.status;
      }
      if(values[`notes_${index}`]) {
        taskValue["notes"] = values[`notes_${index}`];
      }
      if(values[`startTime_${index}`] && values[`endTime_${index}`]) {
        let startTimeSplit = values[`startTime_${index}`].split(" ");
        let endTimeSplit = values[`endTime_${index}`].split(" ");
        let startTime = getValueByDateAndTime(startTimeSplit[0],startTimeSplit[1]);
        let endTime = getValueByDateAndTime(endTimeSplit[0],endTimeSplit[1]);
        taskValue["startTime"] = startTime;
        taskValue["endTime"] = endTime;

        const diffInMs = Math.abs(new Date(startTime) - new Date(endTime));
        taskValue["netDuration"] = diffInMs / 1000;
      }
      taskValue["id"] = task.id;
      taskValue["waterJournalAdded"] = false;
      taskValue["waterJournal"] = null;
      taskValue["dynamicData"] = JSON.stringify(this.getDynamicValue(values, index));
      taskValueArray.push(taskValue);
      return null;
    });
    changeSetSuccessMessage(true);
    createTask(taskValueArray, "batchId", true, activeFarm.farm.id, "complete");
  }

  getDynamicValue = (values, index) => {
    const { selectedActivity } = this.props;
    let dynamicData = {};
    dynamicData["activity_id"] = selectedActivity.value;
    dynamicData["activity_name"] = selectedActivity.label;
    if(values[`seedMix_${index}`]) {
      dynamicData["mix_text"] = values[`seedMix_${index}`];
    }
    if(values[`amount_${index}`]) {
      dynamicData["amount"] = values[`amount_${index}`];
    }
    if(values[`unit_${index}`]) {
      dynamicData["unit"] = values[`unit_${index}`].value;
    }
    if(values[`cut_${index}`]) {
      dynamicData["cut_text"] = values[`cut_${index}`].value;
    }
    if(values[`cutamount_${index}`]) {
      dynamicData["cut_amount"] = values[`cutamount_${index}`];
    }
    if(values[`cutunit_${index}`]) {
      dynamicData["cut_unit"] = values[`cutunit_${index}`].value;
    }
    if(values[`ensilationtype_${index}`]) {
      dynamicData["ensilation_text"] = values[`ensilationtype_${index}`];
    }
    if(values[`ensilationamount_${index}`]) {
      dynamicData["ensilation_amount"] = values[`ensilationamount_${index}`];
    }
    if(values[`ensilationunit_${index}`]) {
      dynamicData["ensilation_unit"] = values[`ensilationunit_${index}`].value;
    }
    if(values[`drymatter_${index}`]) {
      dynamicData["drymatter"] = values[`drymatter_${index}`];
    }
    if(values[`weight_${index}`]) {
      dynamicData["weight"] = values[`weight_${index}`];
    }
    if(values[`locationId_${index}`]) {
      dynamicData["locationId"] = values[`locationId_${index}`].value;
    }
    if(values[`totalharvest_${index}`]) {
      dynamicData["total_harvest_text"] = values[`totalharvest_${index}`];
    }
    if(values[`waterpercentage_${index}`]) {
      dynamicData["water_percent"] = values[`waterpercentage_${index}`];
    }
    if(values[`fertilizertype_${index}`]) {
      dynamicData["mineralFerti_type"] = values[`fertilizertype_${index}`].value;
    }
    if(values[`fertilizeramount_${index}`]) {
      dynamicData["combo_ferti_amount"] = values[`fertilizeramount_${index}`];
    }
    if(values[`mineralfertilizerunit_${index}`]) {
      dynamicData["combo_ferti_unit"] = values[`mineralfertilizerunit_${index}`].value;
    }
    if(values[`seedmix_${index}`]) {
      dynamicData["sowing_type_text"] = values[`seedmix_${index}`];
    }
    if(values[`sowingamount_${index}`]) {
      dynamicData["combo_sowing_amount"] = values[`sowingamount_${index}`];
    }
    if(values[`sowingunit_${index}`]) {
      dynamicData["combo_sowing_unit"] = values[`sowingunit_${index}`].value;
    }
    return dynamicData;
  };

  render() {
    const { intl, handleSubmit, taskList, actId, taskDetails } = this.props;
    return (
      (actId !== null && taskList.length > 0) ?
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        {taskDetails.isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        <div className="task-batch-textcontainer table-fixed-div">
          <table cellSpacing="0" cellPadding="0" className={`task-batch-div-header`}>
            <thead>
              <tr>
                {this.getHeaderList()}
              </tr>
            </thead>
            <tbody className="task-batch-font-size task-batch-border-top">
              {this.getValueList()}
            </tbody>
          </table>
        </div>
        <div className="mybutton">
          <span className="feedback">
            <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
              {intl.formatMessage({ id: 'update' })}
            </ActionButton>
          </span>
        </div>
        <div className="fixed-button-task">
          <span className="padding-right-heavy-task">&nbsp;</span>
        </div>
      </form> :
      <div className='padding-top-no-content '>
        {actId !== null && intl.formatMessage({ id: 'NO_TASK_FOUND' })}
      </div>
    );
  }
}

function validate(values, props) {
  let errors = {};
  const { taskList } = props;
  taskList.map((list, index) =>
    errors = validatingFields(values, index, props, errors)
  );
  return errors;
}

function validatingFields(values, index, props, errors) {
  const { actId, intl } = props;
  if(!values[`startTime_${index}`]) {
    errors[`startTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(!values[`endTime_${index}`]) {
    errors[`endTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(values[`startTime_${index}`] && values[`endTime_${index}`]) {
    let startTimeSplit = values[`startTime_${index}`].split(" ");
    let endTimeSplit = values[`endTime_${index}`].split(" ");
    let startTime = getValueByDateAndTime(startTimeSplit[0],startTimeSplit[1]);
    let endTime = getValueByDateAndTime(endTimeSplit[0],endTimeSplit[1]);
    if(startTime > endTime) {
      errors[`endTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
    }
  }
  if(values[`startTime_${index}`] && !isMatch(values[`startTime_${index}`], 'dd.MM.yyyy HH:mm')) {
    errors[`startTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(values[`endTime_${index}`] && !isMatch(values[`endTime_${index}`], 'dd.MM.yyyy HH:mm')) {
    errors[`endTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }

  if(actId === ACT1 || ACT20) {
    if(values[`amount_${index}`] && !AmountValidation(values[`amount_${index}`])) {
      errors[`amount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "invalid" })} />
    }
  }
  if(actId === ACT9 || actId === ACT10 || actId === ACT28 || actId === ACT11 || actId === ACT12 || ACT16 || ACT18 || ACT20) {
    if(values[`cutamount_${index}`] && !AmountValidation(values[`cutamount_${index}`])) {
      errors[`cutamount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
    if(values[`ensilationamount_${index}`] && !AmountValidation(values[`ensilationamount_${index}`])) {
      errors[`ensilationamount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
    if(values[`drymatter_${index}`] && !AmountValidation(values[`drymatter_${index}`])) {
      errors[`drymatter_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
    if(values[`drymatter_${index}`] < 1 || values[`drymatter_${index}`] > 100) {
      errors[`drymatter_${index}`] = <FormattedMessage id="DRY_MATTER_ERROR_MSG" children={intl.formatMessage({ id: "DRY_MATTER_ERROR_MSG" })} />
    }
    if(values[`weight_${index}`] && !AmountValidation(values[`weight_${index}`])) {
      errors[`weight_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
  }
  if(actId === ACT13) {
    if(values[`totalharvest_${index}`] && !AmountValidation(values[`totalharvest_${index}`])) {
      errors[`totalharvest_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
    if(values[`waterpercentage_${index}`] && !AmountValidation(values[`waterpercentage_${index}`])) {
      errors[`waterpercentage_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
  }
  if(actId === ACT20) {
    if(values[`fertilizeramount_${index}`] && !AmountValidation(values[`fertilizeramount_${index}`])) {
      errors[`fertilizeramount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
    if(values[`seedmix_${index}`] && !AmountValidation(values[`seedmix_${index}`])) {
      errors[`seedmix_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
    if(values[`sowingamount_${index}`] && !AmountValidation(values[`sowingamount_${index}`])) {
      errors[`sowingamount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
    }
  }
  return errors;
}

function handleFocusOnError(errors, errorFieldsState) {
  const errorFields = Object.keys(errors);
  const stateErrorFields = errorFieldsState && Object.keys(errorFieldsState);

  errorFieldsState && stateErrorFields.forEach((field) => {
    const element = document.getElementsByName(field)[0];
    if (element) {
      element.style.border = '';
      let errorMessageElement = document.getElementById(`${field}-error`);
      errorMessageElement.innerHTML = '';
    }
  });

  errorFields.forEach((field) => {
    const element = document.getElementsByName(field)[0];
    if (element) {
      element.style.border = '1px solid red';
      let errorMessageElement = document.getElementById(`${field}-error`);
      if (!errorMessageElement) {
        errorMessageElement = document.createElement('span');
        errorMessageElement.id = `${field}-error`;
        errorMessageElement.className = 'error-text';
        errorMessageElement.style.color = 'red';
        errorMessageElement.style.fontSize = '12px';
        errorMessageElement.style.marginTop = '4px';
        element.parentNode.insertBefore(errorMessageElement, element.nextSibling);
      }
      errorMessageElement.innerHTML = errors[field].props.children;
    }
  });

  // Focus on the first error field
  if (errorFields.length > 0) {
    const firstErrorField = errorFields[0];
    const firstElement = document.getElementsByName(firstErrorField)[0];
    if (firstElement) {
      firstElement.focus();
    }
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('Template')(state),
  activeFarm: state.farmList.activeFarm,
  taskDetails: state.taskDetails,
});

const mapDispatchToProps = dispatch => ({
  createTask: (taskData, type, taskComplete, farmId, status) => dispatch(taskActions.createUpdateTask(taskData, type, taskComplete, farmId, status, true)),
});

export default injectIntl(
  reduxForm({
    form: 'Template',
    validate: () => ({}),
    touchOnBlur: false,
    touchOnChange: false
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Template)))
);