import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography, Checkbox, Box, Grid, Menu, MenuItem } from '@mui/material';

class FieldCard extends React.Component {
  constructor(props) {
    super(props);
    this.snapshotRef = React.createRef();
    this.state = {
      anchorEl: false,
      deleteConfirmShow: false
    };
  }

  componentDidMount() {
  }

  // to compute the co-ordinates
  computeCoOrds = () => {
    const { farm, field } = this.props;
    if (field.gpsPoints.length === 0) {
      if (farm.farm.location !== null) {
        return farm.farm.location;
      }
    }
    return field.gpsPoints[0];
  };

  checkBoxChange = e => {
    const { handleCheckbox } = this.props;
    handleCheckbox(e);
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  handleDelete = (event, fieldId) => {
    event.preventDefault();
    const { updateState } = this.props;
    this.setState({ anchorEl: false });
    updateState(true, fieldId);
  };

  render() {
    const { field, checked, intl, disable, fieldMasterData, disableIcon } = this.props;
    const [fieldCropName] = field.cropId !== null ? 
      (fieldMasterData.cropList ? _.filter(fieldMasterData.cropList, ['id', field.cropId]) : '') : (fieldMasterData.cropList ? _.filter(fieldMasterData.cropList, ['id', 0]) : '') ;
    const gridTitleItemStyle = { flex: 3, paddingLeft: 5, paddingRight: 5 };
    const gridItemStyle = { flex: 1.5, paddingLeft: 5, paddingRight: 10, paddingTop: 10, borderRight: '' };
    const gridMediumItemStyle = { ...gridItemStyle, flex: 2 };
    const lastBeforeGridItemStyle = { flex: 1, paddingLeft: 10, paddingRight: 0, paddingTop: 10 };
    const lastGridItemStyle = { ...lastBeforeGridItemStyle, flex: '0.1 0 auto' };

    return (
      <li className={`show-checkbox list-item full-width`} key={field.fieldId}>
        <div className="checkbox-holder">
          <span>
            <Checkbox
              checked={checked === undefined ? false : checked}
              labelTextHidden
              labelText=""
              id={field.fieldId}
              onChange={e => this.checkBoxChange(e)}
            />
          </span>
        </div>
        <Link
          className={`field-list-item-link ${disable && 'disabled'}`}
          to={`/managefields/${field.fieldId}`}
        >
          <Grid container direction={{ xs: 'column', sm: 'row' }}>
            <Grid item xs={12} sm={3} style={gridTitleItemStyle}>
              <Typography className='field-list-font-title'>{field.displayName}</Typography>
              {field.fieldSize ? (
                <Typography className='field-list-font-subtitle'>{field.fieldSize} daa</Typography>
              ) : ''}
            </Grid>
            <Grid item xs={12} sm={2} style={gridMediumItemStyle}>
              <Typography variant="caption">
                <span className='field-list-font-subtitleheading'>{intl.formatMessage({ id: 'FV_CROP' })}: </span>
                <span title={fieldCropName ? fieldCropName.name : ''} className='field-list-font-subtitle'>
                  {fieldCropName ? fieldCropName.name : ''}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} style={gridMediumItemStyle}>
              <Typography variant="caption">
                <span className='field-list-font-subtitleheading'>{intl.formatMessage({ id: 'FIELD_LIST_YIELD' })}: </span>
                <span title={field.yieldGoal ? field.yieldGoal : '--'} className='field-list-font-subtitle'>
                  {field.yieldGoal ? field.yieldGoal : '--'}
                </span>
              </Typography>
              <Typography variant="caption" sx={{ float: 'right' }}>
                <span className='field-list-font-subtitleheading'>{intl.formatMessage({ id: 'FIELD_LIST_HARVEST' })}: </span>
                <span title={field.harvests ? field.harvests : '--'} className='field-list-font-subtitle'>
                  {field.harvests ? field.harvests : '--'}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} style={gridItemStyle}>
              <Typography variant="caption">
                <span className='field-list-font-subtitleheading'>{intl.formatMessage({ id: 'FIELD_LIST_MANURE' })}: </span>
                <span title={field.manureApplied ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' })} className='field-list-font-subtitle'>
                  {field.manureApplied ? intl.formatMessage({ id: 'YES' }) : intl.formatMessage({ id: 'NO' })}
                </span>
              </Typography>
              <Typography variant="caption" sx={{ float: 'right' }}>
                <span className='field-list-font-subtitleheading'>{intl.formatMessage({ id: 'FIELD_LIST_AGE' })}: </span>
                <span title={field.age ? field.age : '--'} className='field-list-font-subtitle'>
                  {field.age ? field.age : '--'}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} style={lastGridItemStyle}>
              <Box component='span' sx={{ float: 'right' }}>
                <MoreVertIcon onClick={this.handleClick} />
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {disableIcon ?
                  <MenuItem onClick={(e)=>this.handleDelete(e,field.fieldId)} sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                    {intl.formatMessage({ id: 'AEF_DF' })}
                  </MenuItem> :
                  <MenuItem onClick={this.handleClose} disabled={true} sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                    {intl.formatMessage({ id: 'AEF_DF' })}
                  </MenuItem>
                  }
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Link>
      </li>
    );
  }
}

export default injectIntl(FieldCard);
