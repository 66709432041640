import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import TextBoxControl from 'components/forms/TextBoxControl';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import SelectControl from 'components/forms/SelectBoxControl';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import { CrossIcon } from '@tine/lib-frontend-components/elements/icons';
import { getDropDownListValues, getYearsForKPI, getDropDownValuesForYear } from 'utils/index';
import * as FieldActions from 'actions/Fields';
import Loader from 'components/Loader';
import mixpanel from 'mixpanel-browser';
import { ADD_BALE, ADD_BALE_FROM_TASK, ADD_NEW_BALE, ADD_BALE_COMPLETE } from 'constants/MixPanel';
import _ from 'lodash';

class AddBaleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddBalesMain: false,
      showAddBales: false,
      showAddBalesTask: false,
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: true,
      displayHarvest2: true,
      displayHarvest3: true,
      displayHarvest4: true,
      harvestFieldValues: [],
      quantityPlaceHolder: "",
      noHarvestError: false,
      noSortFieldError: false,
      baleStorageError: false,
      noQuantityError: false,
      changedDisplayHarvest: false,
      quantityMismatchError: false,
      selectedYearTaskDropdown: null,
      selectedTaskYear: null,
      selectedStorage: null
    };
  }

  componentDidMount = () => {
    const currentYear = new Date().getFullYear();
    this.setState({
      selectedYear: {"label": currentYear, "value": currentYear}
    });
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getTaskLocationsData, getTaskLocations } = this.props;
    const { showAddBalesTask, changedDisplayHarvest } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      getTaskLocations(activeFarm.farm.id);
      history.push('/storages');
    }
    if(showAddBalesTask && !changedDisplayHarvest && getTaskLocationsData && getTaskLocationsData.length > 0) {
      this.fieldsChangeInitialize(null);
      this.setState({
        changedDisplayHarvest: true
      });
    }
  }

  fieldsChangeInitialize(yearSelected) {
    const { getTaskLocationsData } = this.props;
    this.setState({
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: false,
      displayHarvest2: false,
      displayHarvest3: false,
      displayHarvest4: false
    });
    let yearData = [];
    let fieldKeyPair = {};
    let fieldDataKeyPair = [];
    getTaskLocationsData.map(balesYear => (
      //eslint-disable-next-line
      yearData.includes(balesYear.year) ? '' : yearData.push(balesYear.year),
      balesYear.balesByHarvest && balesYear.balesByHarvest.map(balesHarvest => (  
        balesHarvest && balesHarvest.balesByField.map(balesField => (
          !fieldKeyPair.hasOwnProperty(balesField.id) ?
          (fieldKeyPair = {},
          fieldKeyPair['id'] = balesField.id,
          fieldKeyPair['name'] = balesField.name,
          balesField.year === (yearSelected !== null ? yearSelected : yearData[0]) ? fieldDataKeyPair.push(fieldKeyPair) : '') : ''
        ))
      ))
    ));
    this.setState({
      selectedTaskYear: yearData,
      harvestFieldValues: fieldDataKeyPair
    });
    if(yearSelected === null) {
      this.setState({
        selectedYearTaskDropdown: { "label": yearData[0], "value": yearData[0]}
      });
    }
    getTaskLocationsData.map(getTaskData => (
      getTaskData.balesByHarvest && getTaskData.balesByHarvest.map(getTaskHarvest => (
        getTaskData.year === (yearSelected !== null ? yearSelected : yearData[0]) ? (
        //eslint-disable-next-line
        getTaskHarvest.harvest === 1 ? this.setState({ displayHarvest1: true }) : '',
        getTaskHarvest.harvest === 2 ? this.setState({ displayHarvest2: true }) : '',
        getTaskHarvest.harvest === 3 ? this.setState({ displayHarvest3: true }) : '',
        getTaskHarvest.harvest === 4 ? this.setState({ displayHarvest4: true }) : ''
        ) : ''
      ))
    ))
  }

  onChangeYear = e => {
    this.setState({
      selectedYear: { "label": e.label, "value": e.value}
    });
  }

  onChangeYearAdd = e => {
    this.getAddInitialize();
    this.setState({
      selectedYearTaskDropdown: e,
      quantityPlaceHolder: ""
    });
    this.fieldsChangeInitialize(e.value);
  }

  handleSubmit = values => {
    const { showAddBales, showHarvest1, showHarvest2, showHarvest3, showHarvest4, quantityPlaceHolder, selectedYear, selectedYearTaskDropdown } = this.state;
    const { activeFarm, addFeedEvent, addBaleTask, locations, userDetails } = this.props;
    this.setState({
      noHarvestError: false,
      noSortFieldError: false,
      quantityMismatchError: false,
      baleStorageError: false,
      noQuantityError: false,
      noAnalysisParameter: false
    });

    if(showAddBales) {
      if (!values.baleName) {
        this.setState({
          baleStorageError: true
        });
        return;
      }
      if (values.baleName && !values.baleName.value) {
        this.setState({
          baleStorageError: true
        });
        return;
      }
      if(!showHarvest1 && !showHarvest2 && !showHarvest3 && !showHarvest4) {
        this.setState({
          noHarvestError: true
        });
        return;
      }
      let selectedHarvest = _.filter(locations, ['id', values.baleName.value])[0];
      if(selectedHarvest.type === "INTERNAL" && (!values.baleFieldName || values.baleFieldName === 'undefined')) {
        this.setState({
          noSortFieldError: true
        });
        return;
      }
      if(selectedHarvest.type === "INTERNAL" && values.baleFieldName && values.baleFieldName.value === '') {
        this.setState({
          noSortFieldError: true
        });
        return;
      }
      let baleQuan = values.baleQuantity ? values.baleQuantity.replace(',','.') : '';
      if (!baleQuan || !baleQuan.trim()) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      if (baleQuan && isNaN(baleQuan)) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      if (baleQuan && parseFloat(baleQuan) <= 0) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      let baleHarvest = 1;
      if(showHarvest1) { baleHarvest = 1; }
      if(showHarvest2) { baleHarvest = 2; }
      if(showHarvest3) { baleHarvest = 3; }
      if(showHarvest4) { baleHarvest = 4; }

      let formData = {};
      formData["farmId"] = activeFarm.farm.id;
      formData["harvest"] = baleHarvest;
      formData["year"] = selectedYear.value;
      formData["storageId"] = values.baleName.value;
      if(values.baleFieldName && values.baleFieldName.value) {
        formData["fieldId"] = values.baleFieldName.value;
      }
      formData["quantity"] = baleQuan;
      mixpanel.identify(userDetails.id);
      mixpanel.track(ADD_BALE_COMPLETE);
      addFeedEvent(activeFarm.farm.id, formData);
    } else {
      if (!values.baleName) {
        this.setState({
          baleStorageError: true
        });
        return;
      }
      if (values.baleName && !values.baleName.value) {
        this.setState({
          baleStorageError: true
        });
        return;
      }      
      if(!showHarvest1 && !showHarvest2 && !showHarvest3 && !showHarvest4) {
        this.setState({
          noHarvestError: true
        });
        return;
      }
      if (!values.baleFieldName) {
        this.setState({
          noSortFieldError: true
        });
        return;
      }
      if (values.baleFieldName && !values.baleFieldName.value) {
        this.setState({
          noSortFieldError: true
        });
        return;
      }
      let baleQuan = values.baleQuantity ? values.baleQuantity.replace(',','.') : '';
      if (!baleQuan || !baleQuan.trim()) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      if (baleQuan && isNaN(baleQuan)) {
        this.setState({
          noQuantityError: true
        });
        return;
      }
      if (baleQuan && parseFloat(baleQuan) <= 0) {
        this.setState({
          noQuantityError: true
        });
        return;
      }      
      if(parseFloat(baleQuan) > parseFloat(quantityPlaceHolder)) {
        this.setState({
          quantityMismatchError: true
        });
        return;
      }
  
      let baleHarvest = 1;
      if(showHarvest1) { baleHarvest = 1; }
      if(showHarvest2) { baleHarvest = 2; }
      if(showHarvest3) { baleHarvest = 3; }
      if(showHarvest4) { baleHarvest = 4; }
      let formData = {};
      formData["farmId"] = activeFarm.farm.id;
      formData["harvest"] = baleHarvest;
      formData["storageId"] = values.baleName.value;
      formData["year"] = selectedYearTaskDropdown.value;
      if(values.baleFieldName && values.baleFieldName.value) {
        formData["fieldId"] = values.baleFieldName.value;
      }
      formData["quantity"] = baleQuan;
      addBaleTask(activeFarm.farm.id, formData);
    }
  };

  openShowAddBalesMain = () => {
    const { userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_BALE);
    this.setState({
      showAddBalesMain: true,
      showAddBales: false,
      showAddBalesTask: false
    });
  }

  openShowAddBales = () => {
    const { fieldList, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_NEW_BALE);
    this.getAddInitialize();
    let data = [];
    let keyPair = {};
    fieldList && fieldList.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.fieldId,
      keyPair['name'] = field.displayName,
      data.push(keyPair)
    ))
    this.setState({
      harvestFieldValues: data,
      showAddBalesMain: false,
      showAddBales: true,
      showAddBalesTask: false
    });
  }

  openShowAddBalesTask = () => {
    const { userDetails, activeFarm, getTaskLocations } = this.props;
    this.getAddInitialize();
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_BALE_FROM_TASK);
    getTaskLocations(activeFarm.farm.id);
    this.setState({
      showAddBalesMain: false,
      showAddBales: false,
      showAddBalesTask: true,
      changedDisplayHarvest: false
    });
  }

  closeShowAddBalesMain = () => {
    this.getAddInitialize();
    this.setState({
      showAddBalesMain: false,
      showAddBales: false,
      showAddBalesTask: false
    });
  }

  closeShowAddBalesTask = () => {
    this.getAddInitialize();
    this.setState({
      showAddBalesMain: false,
      showAddBales: false,
      showAddBalesTask: false
    });
  }

  closeShowAddBales = () => {
    this.getAddInitialize();
    this.setState({
      showAddBalesMain: false,
      showAddBales: false,
      showAddBalesTask: false
    });
  }

  getAddInitialize = () => {
    const { initialize } = this.props;
    const formValues = {
      baleName: { label: '', value: '' },
      baleFieldName: { label: '', value: '' },
      baleQuantity: ''
    };
    this.setState({
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      displayHarvest1: true,
      displayHarvest2: true,
      displayHarvest3: true,
      displayHarvest4: true,
      harvestFieldValues: [],
      noHarvestError: false,
      noSortFieldError: false,
      quantityMismatchError: false,
      baleStorageError: false,
      noQuantityError: false,
      noAnalysisParameter: false,
      quantityPlaceHolder: ""
    })
    initialize(formValues);
  };

  onChangeSelectedHarvest = e => {
    this.setState({
      baleStorageError: false,
      selectedStorage: e
    });
  }

  onSelectHarvest = (harvest) => {
    const { initialize } = this.props;
    const { showAddBales, selectedStorage } = this.state;
    const formValues = {
      baleName: selectedStorage,
      baleFieldName: { label: '', value: '' },
      baleQuantity: ''
    };
    initialize(formValues);
    this.setState({
      noHarvestError: false
    });
    if(showAddBales) {
      this.setState({
        showHarvest1: harvest === 1 ? true : false,
        showHarvest2: harvest === 2 ? true : false,
        showHarvest3: harvest === 3 ? true : false,
        showHarvest4: harvest === 4 ? true : false
      });
    } else {
      const { getTaskLocationsData } = this.props;
      const { selectedYearTaskDropdown } = this.state;
      let quantity = "";
      let selectedYear = _.filter(getTaskLocationsData, ['year', selectedYearTaskDropdown.value])[0];
      let selectedHarvest = [];
      let data = [];
      let keyPair = {};
      if(selectedYear.year === selectedYearTaskDropdown.value) {
        quantity = selectedYear.quantity.toString();
      }
      selectedYear && selectedYear.balesByHarvest.map(balesHarvest => (
        //eslint-disable-next-line
        balesHarvest.harvest === harvest ? selectedHarvest.push(balesHarvest) : ''
      ))
      selectedHarvest[0] && selectedHarvest[0].balesByField.map(balesField => (
        //eslint-disable-next-line
        keyPair = {},
        keyPair['id'] = balesField.id,
        keyPair['name'] = balesField.name,
        data.push(keyPair)
      ));
      if(selectedHarvest[0]) {
        quantity = selectedHarvest[0].quantity.toString();
      }      
      this.setState({
        harvestFieldValues: data,
        quantityPlaceHolder: quantity
      });
      this.setState({
        showHarvest1: harvest === 1 ? true : false,
        showHarvest2: harvest === 2 ? true : false,
        showHarvest3: harvest === 3 ? true : false,
        showHarvest4: harvest === 4 ? true : false
      });
    }
  }

  onChangeSelectedField = e => {
    const { showAddBalesTask, selectedYearTaskDropdown } = this.state;
    this.setState({
      noSortFieldError: false
    });
    if(showAddBalesTask) {
      const { getTaskLocationsData } = this.props;
      const { showHarvest1, showHarvest2, showHarvest3, showHarvest4 } = this.state;
      let selectedHarvest = 1;
      if(showHarvest1) {
        selectedHarvest = 1;
      } else if(showHarvest2) {
        selectedHarvest = 2;
      } else if(showHarvest3) {
        selectedHarvest = 3;
      } else if(showHarvest4) {
        selectedHarvest = 4;
      }
      let selectedYear = _.filter(getTaskLocationsData, ['year', selectedYearTaskDropdown.value])[0];
      let selectedHarvestValue = [];
      let quantity = "";
      if(selectedYear.year === selectedYearTaskDropdown.value) {
        quantity = selectedYear.quantity.toString();
      }
      selectedYear && selectedYear.balesByHarvest.map(balesHarvest => (
        //eslint-disable-next-line
        balesHarvest.harvest === selectedHarvest ? selectedHarvestValue.push(balesHarvest) : ''
      ))
      if(e.value) {
        let selectedField = _.filter(selectedHarvestValue[0].balesByField, ['id', e.value])[0];
        quantity = selectedField.quantity.toString();
        this.setState({
          quantityPlaceHolder: quantity
        });
      } else {
        this.setState({
          quantityPlaceHolder: quantity
        });
      }
    }
  }
  
  render() {
    const { intl, getFeedEventListData, locations, getAnalysisStorageListData, handleSubmit, getTaskLocationsData } = this.props;
    const { showAddBales, showHarvest1, showHarvest2, showHarvest3, showHarvest4, displayHarvest1, displayHarvest2, 
      displayHarvest3, displayHarvest4, harvestFieldValues, noHarvestError, noSortFieldError, baleStorageError, 
      noQuantityError, showAddBalesMain, showAddBalesTask, quantityPlaceHolder, quantityMismatchError, selectedYear,
      selectedTaskYear, changedDisplayHarvest, selectedYearTaskDropdown } = this.state;
    return (
      <div className={`add-harvest ${getAnalysisStorageListData && getAnalysisStorageListData.length <= 0 ? 'remove-cursor' : ''}`} 
        onClick={() => { getAnalysisStorageListData && getAnalysisStorageListData.length > 0 && this.openShowAddBalesMain(); }}>
        <span className="pad-right">{intl.formatMessage({ id: 'BALE_OVERVIEW_BALES_ANALYSIS' })}</span>
        <div className="add-icon">
          <img src={`./images/bale_add.png`} className="bale-icon-size" alt="draw" />
        </div>
        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '24%'}}
          width={{mobile: '90%', tablet: '55%', web: '24%'}}
          height={{mobile: '90%', tablet: '75%', web: '75%'}}
          show={showAddBalesMain}
          closeIcon={
            <span className="close-modal">
              <CrossIcon scale={0.5} />
            </span>
          }
          onClose={() => this.closeShowAddBalesMain()}>
            <div>
              <div className='text-align-center'>
                <Emphasis size="extralarge">{intl.formatMessage({ id: 'BALE_OVERVIEW_BALES_ANALYSIS' })}</Emphasis>
              </div>
              <div>
                <div className='main-bale-storage-form-style'>
                  <div className="align-center-span">
                    <Emphasis size="normal">{intl.formatMessage({ id: 'ADD_BALE_MAIN_CONTENT_HEADING' })}</Emphasis>
                  </div>
                  <div className="align-justify-span padding-top-div">
                    {intl.formatMessage({ id: 'ADD_BALE_MAIN_CONTENT' })}
                  </div>
                  <div onClick={() => this.openShowAddBalesTask()}>
                    <div className="padding-bottom-border">
                      <div className="padding-top-div lightbox-padding-bottom-div-small">
                        <span>
                          <span className='add-bale-header'>{intl.formatMessage({ id: 'ADD_BALE_MAIN_HEADING_1' })}</span>
                        </span>
                        <span className="storage-arrow">
                          <span className="icon-arrow-up-accordion add-bales-arrow-label" />
                        </span>
                      </div>
                      <div className="align-justify-span">
                        {intl.formatMessage({ id: 'ADD_BALE_MAIN_HEADING_1_CONTENT' })}
                      </div>
                    </div>
                  </div>
                  <div onClick={() => this.openShowAddBales()}>
                    <div>
                      <div className="padding-top-div lightbox-padding-bottom-div-small">
                        <span>
                          <span className='add-bale-header'>{intl.formatMessage({ id: 'ADD_BALE_MAIN_HEADING_2' })}</span>
                        </span>
                        <span className="storage-arrow">
                          <span className="icon-arrow-up-accordion add-bales-arrow-label" />
                        </span>
                      </div>
                      <div className="align-justify-span">
                        {intl.formatMessage({ id: 'ADD_BALE_MAIN_HEADING_2_CONTENT' })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ModalDialog>
        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '25%'}}
          width={{mobile: '90%', tablet: '55%', web: '25%'}}
          height={{mobile: '90%', tablet: '75%', web: '75%'}}
          show={showAddBalesTask}
          closeIcon=""
          onClose={() => {}}>
          {getFeedEventListData && getFeedEventListData.taskLocationIsLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
          {getTaskLocationsData && getTaskLocationsData.length > 0 && changedDisplayHarvest && <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <div className='text-align-center'>
                <span className='fertilization-header'>{intl.formatMessage({ id: 'BALE_OVERVIEW_BALES_ANALYSIS' })}</span>
              </div>
              <div>
                <div className='bale-storage-form-style'>
                  <div>
                    <Field
                      name="year"
                      label={intl.formatMessage({ id: 'CHOOSE_YEAR' })}
                      controlId="year"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownValuesForYear(selectedTaskYear)}
                      defaultValue={selectedYearTaskDropdown}
                      onChange={e => this.onChangeYearAdd(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="bale-option">
                    {locations && <Field
                      name="baleName"
                      label={intl.formatMessage({ id: 'ADD_BALE_NAME_DROPDOWN' })}
                      controlId="baleName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(locations, false)}
                      onChange={e => this.onChangeSelectedHarvest(e)}
                      placeholder=""
                    />}
                  </div>
                  <div className="bale-option">
                    <div className="padding-bottom-div">
                      <EmphasisLight size="small">{intl.formatMessage({ id: 'ADD_BALE_HARVEST' })}</EmphasisLight>
                    </div>
                    <div>
                      <span className={`number-padding ${displayHarvest1 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest1 && this.onSelectHarvest(1); }}>
                        {showHarvest1 ?
                          <img src={`./images/h1_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest1 ?
                          <img src={`./images/h1.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h1_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest2 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest2 && this.onSelectHarvest(2); }}>
                        {showHarvest2 ?
                          <img src={`./images/h2_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest2 ?
                          <img src={`./images/h2.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h2_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest3 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest3 && this.onSelectHarvest(3); }}>
                        {showHarvest3 ?
                          <img src={`./images/h3_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest3 ?
                          <img src={`./images/h3.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h3_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest4 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest4 && this.onSelectHarvest(4); }}>
                        {showHarvest4 ?
                          <img src={`./images/h4_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest4 ?
                          <img src={`./images/h4.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h4_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                    </div>
                  </div>
                  <div className="padding-top-div-large">
                    <Field
                      name="baleFieldName"
                      label={intl.formatMessage({ id: 'ADD_BALE_FIELDS' })}
                      controlId="baleFieldName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(harvestFieldValues, true)}
                      onChange={e => this.onChangeSelectedField(e)}
                    />
                  </div>
                  <div className="padding-top-div-medium full-width">
                    <Field
                      name="baleQuantity"
                      label={intl.formatMessage({ id: 'ADD_BALE_QUANTITY' })}
                      component={TextBoxControl}
                      controlId="baleQuantity"
                      type="text"
                      units={`${intl.formatMessage({ id: 'ADD_BALE_QUANTITY_UNITS' })} ${quantityPlaceHolder ? '('+quantityPlaceHolder+')' : ''}`}
                    />
                  </div>
                  <div className="lightbox-field-padding-top-large">
                    <span className="paddingRight">
                      {baleStorageError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_1' })}</ErrorText>}
                      {noHarvestError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_2' })}</ErrorText>}
                      {noSortFieldError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_3' })}</ErrorText>}
                      {noQuantityError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_4' })}</ErrorText>}
                      {quantityMismatchError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_5' })}</ErrorText>}
                      {getFeedEventListData.saveSuccess && window.location.reload()}
                    </span>
                  </div>
                  <div className="bale-button-style">
                    <span className="paddingRight">
                      <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                        {intl.formatMessage({ id: 'ADD_BALE_BUTTON' })}
                      </ActionButton>
                    </span>
                    <span className="bale-list-cancel-button" onClick={() => this.closeShowAddBalesTask()}>
                      {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>}
          {getTaskLocationsData && getTaskLocationsData.length <= 0 && 
          <div>
            <div className='text-align-center'>
              <Emphasis size="extralarge">{intl.formatMessage({ id: 'BALE_OVERVIEW_BALES_ANALYSIS' })}</Emphasis>
            </div>
            <div>
              <div className='main-bale-storage-form-style padding-top-div-extra-large'>
                <div className="align-center-span">
                  <Emphasis size="normal">{intl.formatMessage({ id: 'NO_DATA_FOUND' })}</Emphasis>
                </div>
              </div>
              <div className="padding-top-div-extra-large">
                <div className="bale-button-style">
                  <span className="bale-list-cancel-button" onClick={() => this.closeShowAddBalesTask()}>
                    {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                  </span>
                </div>
              </div>
            </div>
          </div>}
        </ModalDialog>

        <ModalDialog
          minWidth={{mobile: '90%', tablet: '55%', web: '25%'}}
          width={{mobile: '90%', tablet: '55%', web: '25%'}}
          height={{mobile: '90%', tablet: '75%', web: '75%'}}
          show={showAddBales}
          closeIcon=""
          onClose={() => {}}>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <div className='text-align-center'>
                <span className='fertilization-header'>{intl.formatMessage({ id: 'BALE_OVERVIEW_BALES_ANALYSIS' })}</span>
              </div>
              <div>
                <div className='bale-storage-form-style'>
                  <div>
                    <Field
                      name="year"
                      label={intl.formatMessage({ id: 'CHOOSE_YEAR' })}
                      controlId="year"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getYearsForKPI()}
                      defaultValue={selectedYear}
                      onChange={e => this.onChangeYear(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="bale-option">
                    {locations && <Field
                      name="baleName"
                      label={intl.formatMessage({ id: 'ADD_BALE_NAME_DROPDOWN' })}
                      controlId="baleName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(locations, false)}
                      onChange={e => this.onChangeSelectedHarvest(e)}
                      placeholder=""
                    />}
                  </div>
                  <div className="bale-option">
                    <div className="padding-bottom-div">
                      <EmphasisLight size="small">{intl.formatMessage({ id: 'ADD_BALE_HARVEST' })}</EmphasisLight>
                    </div>
                    <div>
                      <span className={`number-padding ${displayHarvest1 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest1 && this.onSelectHarvest(1); }}>
                        {showHarvest1 ?
                          <img src={`./images/h1_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest1 ?
                          <img src={`./images/h1.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h1_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest2 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest2 && this.onSelectHarvest(2); }}>
                        {showHarvest2 ?
                          <img src={`./images/h2_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest2 ?
                          <img src={`./images/h2.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h2_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest3 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest3 && this.onSelectHarvest(3); }}>
                        {showHarvest3 ?
                          <img src={`./images/h3_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest3 ?
                          <img src={`./images/h3.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h3_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                      <span className={`number-padding ${displayHarvest4 ? 'style-cursor' : ''}`} 
                        onClick={() => { displayHarvest4 && this.onSelectHarvest(4); }}>
                        {showHarvest4 ?
                          <img src={`./images/h4_blue.png`} className="bale-icon-size" alt="draw" /> :
                          displayHarvest4 ?
                          <img src={`./images/h4.png`} className="bale-icon-size" alt="draw" /> :
                          <img src={`./images/h4_grey.png`} className="bale-icon-size" alt="draw" />
                        }
                      </span>
                    </div>
                  </div>
                  <div className="padding-top-div-large">
                    <Field
                      name="baleFieldName"
                      label={intl.formatMessage({ id: 'ADD_BALE_FIELDS' })}
                      controlId="baleFieldName"
                      component={SelectControl}
                      styleName="select-box-common"
                      options={getDropDownListValues(harvestFieldValues, true)}
                      onChange={e => this.onChangeSelectedField(e)}
                    />
                  </div>
                  <div className="padding-top-div-medium full-width">
                    <Field
                      name="baleQuantity"
                      label={intl.formatMessage({ id: 'ADD_BALE_QUANTITY' })}
                      component={TextBoxControl}
                      controlId="baleQuantity"
                      type="text"
                      units={`${intl.formatMessage({ id: 'ADD_BALE_QUANTITY_UNITS' })}`}
                    />
                  </div>
                  <div className="lightbox-field-padding-top-large">
                    <span className="paddingRight">
                      {baleStorageError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_1' })}</ErrorText>}
                      {noHarvestError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_2' })}</ErrorText>}
                      {noSortFieldError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_3' })}</ErrorText>}
                      {noQuantityError && <ErrorText>{intl.formatMessage({ id: 'ADD_BALE_ERROR_4' })}</ErrorText>}
                      {getFeedEventListData.saveSuccess && window.location.reload()}
                    </span>
                  </div>
                  <div className="bale-button-style">
                    <span className="paddingRight">
                      <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
                        {intl.formatMessage({ id: 'ADD_BALE_BUTTON' })}
                      </ActionButton>
                    </span>
                    <span className="bale-list-cancel-button" onClick={() => this.closeShowAddBales()}>
                      {intl.formatMessage({ id: 'CANCEL_BALE_BUTTON' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  locations: state.baleFeedEvent.locationData,
  getTaskLocationsData: state.baleFeedEvent.taskLocationData,
  getFeedEventListData: state.baleFeedEvent,
  fieldList: state.fieldDetails.data,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  addFeedEvent: (farmId, data) => dispatch(baleFeedEventActions.addFeedEvent(farmId, data)),
  addBaleTask: (farmId, data) => dispatch(baleFeedEventActions.addBaleTask(farmId, data)),
  fetchFieldList: (farmId) => dispatch(FieldActions.fetchFieldList(farmId)),
  getTaskLocations: (farmId) => dispatch(baleFeedEventActions.getTaskLocations(farmId)),
});

export default injectIntl(
  reduxForm({
    form: 'AddBaleModalForm'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBaleModal)))
);
