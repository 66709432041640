import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import TaskBatchList from 'containers/tasks/batch/TaskBatchList';
import * as fieldActions from 'actions/Fields';
import * as taskActions from 'actions/Tasks';
import Loader from 'components/Loader';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import * as Seasons from 'actions/Seasons';
import _ from 'lodash';
import {
  selectTaskPeriodOptions
} from 'constants/DropdownAndTranslationOptions';
import { getUsers } from 'actions/Users';
import { getDropDownActivitiesList } from 'utils/index';
import { fetchTaskList } from 'actions/Tasks';
import * as taskBatchAction from 'actions/TaskBatch';
import { isMobile } from 'react-device-detect';

class BatchTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedActId: "",
      selectedActivity: null,
      selectedPeriod: "",
      taskListActivities: [],
      fetchTaskDetails: true,
      activeFarmDetails: props.activeFarmDetails,
      vehicleList: props.taskDetails.vehicleList,
      equipmentList: props.taskDetails.equipmentList,
      allVehicles: props.taskDetails.allVehicles,
      allEquipments: props.taskDetails.allEquipments,
      setSuccessMessage: false
    };
  }

  componentDidMount = () => {
    const {
      activeFarm,
      farmsData,
      history,
      fetchFieldList,
      getVehicles,
      getEquipment,
      getAllAssets,
      taskList,
      taskDetails,
      userInfo,
      getLocations,
      seasons,
      seasonsList
    } = this.props;
    if (activeFarm) {
      if (taskList.taskListPositionInfo.fromTask && taskList.data) {
        let taskCount = taskList.data.length;
        this.fetchList(0, taskCount < 15 ? 15 : taskCount, null, taskList.taskPeriod, null, null, null);
      } else {
        this.fetchList(0, 15, null, taskList.taskPeriod, null, null, null);
      }
    }
    const { allEquipments, allVehicles } = taskDetails;
    const { activeFarmDetails, vehicleList, equipmentList } = this.state;
    if (activeFarm && !activeFarmDetails) {
      fetchFieldList(activeFarm.farm.id);
      getLocations(activeFarm.farm.id);
      seasons.seasonsList = null;
      seasonsList();
    }
    if (activeFarm && !vehicleList) {
      getVehicles(userInfo.data.idToken, activeFarm.farm.id);
    }
    if (activeFarm && !equipmentList) {
      getEquipment(userInfo.data.idToken, activeFarm.farm.id);
    }
    if (activeFarm && !(allEquipments || allVehicles)) {
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
    }
    if (activeFarm && !activeFarmDetails) {
      fetchFieldList(activeFarm.farm.id);
    }
    if (farmsData && !farmsData.isLoading && farmsData.data && farmsData.data.length < 1) {
      history.push('/managefields');
    }
  };

  componentDidUpdate(prevProps) {
    const { fetchTaskDetails } = this.state; 
    const {
      taskList,
      activeFarm,
      userInfo,
      taskDetails,
      getAllAssets
    } = this.props;
    const { createAssetEntityIsSuccess } = taskDetails;
    if(fetchTaskDetails && taskList.data && !taskList.isLoading) {
      this.setState({taskListActivities: taskList.paginationDetails.activities});
      this.setState({fetchTaskDetails: false});
    }
    if (prevProps.activeFarm !== activeFarm) {
      window.location.replace("/#/task-batch");
      window.location.reload();
    }
    if (createAssetEntityIsSuccess) {
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
    }
  }

  fetchList = (offset, listSize, sortBy, days, statuses, activityIds, fieldIds) => {
    const { activeFarm, getTaskList, taskList } = this.props;
    const sortByValue = sortBy || taskList.sortBy.value;
    getTaskList(
      activeFarm.farm.id,
      sortByValue === 'Date created' ? '' : sortByValue.toLowerCase(),
      offset,
      listSize,
      days,
      statuses,
      activityIds,
      fieldIds
    );
  };

  onChangeActivity = actId => {
    const { getTaskBatch, activeFarm } = this.props;
    const { selectedPeriod, taskListActivities } = this.state;
    getTaskBatch(activeFarm.farm.id, actId.target.value, selectedPeriod === null ? 0 : selectedPeriod);
    const result = _.filter(getDropDownActivitiesList(taskListActivities), ['value', actId.target.value]);
    this.setState({
      selectedActId: actId.target.value,
      selectedActivity: result[0]
    });
  }

  onChangePeriod = days => {
    const { getTaskBatch, activeFarm, getLocations, seasonsList } = this.props;
    const { selectedActId } = this.state;
    this.setState({
      selectedPeriod: days.target.value
    });
    if(selectedActId !== "") {
      getLocations(activeFarm.farm.id);
      seasonsList();
      getTaskBatch(activeFarm.farm.id, selectedActId, days.target.value === "" ? 0 : days.target.value);      
    }
  };

  changeSetSuccessMessage = (state) => {
    this.setState({
      setSuccessMessage: state
    })
  }

  render() {
    const { intl, fieldDetails, taskDetails, activitiesCommon, taskBatch, locations, seasons } = this.props;
    const {
      taskListActivities,
      selectedActId,
      selectedActivity,
      setSuccessMessage
    } = this.state;
    return (
      <div className={`full-width ${isMobile ? 'main-content-mobile' : 'main-content'}`} style={{ backgroundColor: '#ffffff' }}>
        <SectionContainer
          styling={{
            backgroundColor: 'white',
            paddingAllSides: 'low',
            paddingTop: 'lowest',
            mobileOffset: true
          }}
        >
          <div className="tm-filter-template">
            <div className="tm-filter-batch-filter sort-by">
              <span className="filter-dd-lbl">
                <Text>
                  <FormattedMessage id="TASK_BATCH_SELECT_PERIOD" />:
                </Text>
              </span>
              {/*<Selectbox
                styleName="filter-batch-dropdown"
                options={TaskPeriodOptions}
                onChange={v => this.onChangePeriod(v)}
              />*/}
              <select value={this.state['selectedPeriod']} name="selectPeriod" 
                onChange={v => this.onChangePeriod(v)} className="customized-select-dropdown">
                {selectTaskPeriodOptions.map((option, index) => (
                  <option key={option.value} value={option.value}>{intl.formatMessage({ id: `${option.label}` })}</option>
                ))}
              </select>
            </div>
            <div className="tm-filter-sortby">
              <span className="filter-dd-lbl">
                <Text>{intl.formatMessage({ id: 'TASK_BATCH_SELECT_ACTIVITY' })}</Text>:
              </span>
              {/*<Selectbox
                styleName="filter-batch-dropdown"
                options={getDropDownActivities(taskListActivities)}
                value={selectedActivity}
                onChange={v => this.onChangeActivity(v)}
              />*/}
              <select value={this.state['selectedActId']} name="selectActivity" 
                onChange={v => this.onChangeActivity(v)} className="customized-select-dropdown template-width">
                {getDropDownActivitiesList(taskListActivities).map((option, index) => (
                  <option key={option.value} value={option.value}>
                    {option.label === "" ? "" : intl.formatMessage({ id: `${option.label}` })}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </SectionContainer>
        <SectionContainer
          styling={{
            backgroundColor: 'white',
            paddingAllSides: 'low',
            paddingTop: 'lowest',
            mobileOffset: true
          }}
        >
        {taskBatch.isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        {!taskBatch.isLoading && fieldDetails !== null && taskBatch.taskList !== null && taskBatch.taskList !== undefined && 
        locations !== null && seasons !== null &&
        <TaskBatchList
          actId={selectedActId}
          fieldDetails={fieldDetails}
          taskDetails={taskDetails}
          activitiesCommon={activitiesCommon}
          taskList={taskBatch.taskList.taskBeans}
          pesticideSize={taskBatch.taskList.pesticidesSize}
          intl={intl}
          locations={locations}
          seasons={seasons}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={this.changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
        />}
        </SectionContainer>
        {taskDetails?.isSuccess && !taskDetails?.isLoading && setSuccessMessage && (
          <ModalDialog
            minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
            width={{mobile: '50%', tablet: '35%', web: '25%'}}
            height={{mobile: '25%', tablet: '25%', web: '20%'}}
            show={setSuccessMessage}
            closeIcon=""
            onClose={() => {}}>
            <div className="text-align-center">
              <div>
                <div className='fertilizer-form-style'>
                  <div className="font-popup-success">{intl.formatMessage({ id: 'TASK_SAVED_SUCCESS' })}</div>
                </div>
                <div className='fertilizer-form-style'>
                  <span className="batch-task-save-button" onClick={() => this.changeSetSuccessMessage(false)}>
                    {intl.formatMessage({ id: 'OK' })}
                  </span>
                </div>
              </div>
            </div>
          </ModalDialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fieldDetails: state.fieldDetails,
  activeFarmDetails: state.fieldDetails.data,
  taskList: state.TaskList,
  taskDetails: state.taskDetails,
  userInfo: state.userDetails,
  activitiesCommon: state.masterData.data[0]?.activities,
  taskBatch: state.taskBatch,
  locations: state.baleFeedEvent.locationData,
  seasons: state.seasons
});

const mapDispatchToProps = dispatch => ({
  getTaskList: (farmId, sortBy, offset, numberOfTasks, days, statuses, activityIds, fieldIds) =>
    dispatch(fetchTaskList(farmId, sortBy, offset, numberOfTasks, days, statuses, activityIds, fieldIds)),
  fetchAssignee: (farmId, token) => dispatch(getUsers(farmId, token)),
  fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
  getVehicles: (token, id) => dispatch(taskActions.getVehicles(token, id)),
  getEquipment: (token, id) => dispatch(taskActions.getEquipment(token, id)),
  getAllAssets: (token, category, farmId) => dispatch(taskActions.getAllAssets(token, category, farmId)),
  getTaskBatch: (farmId, activityId, days) => dispatch(taskBatchAction.getTaskBatch(farmId, activityId, days)),
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  seasonsList: () => dispatch(Seasons.seasonsList())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BatchTask));
