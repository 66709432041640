import { SUBSCRIPTION_URL } from 'constants/ApiUrl';
import {
  SUBSCRIPTION_LOADING,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE
} from 'constants/store/Subscription';
import apiRequest from 'utils/ApiRequest';

/**
 * to get subscription user list
 * @param {string} farmId
 */
export const fetchSubscriptionUsers = () => async dispatch => {
  dispatch({ type: SUBSCRIPTION_LOADING, payload: true });
  await dispatch(
    apiRequest({
      url: `${SUBSCRIPTION_URL}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch({ type: SUBSCRIPTION_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: SUBSCRIPTION_FAILURE, payload: error });
    });
};