import { GRASS_ANALYSIS_URL, GRASS_ANALYSIS_URL_VERSION_2, GRASS_ANALYSIS_SEARCH_URL } from 'constants/ApiUrl';
import {
  ADD_GRASS_ANALYSIS_LOADING,
  ADD_GRASS_ANALYSIS_SUCCESS,
  ADD_GRASS_ANALYSIS_ERROR,
  GET_GRASS_ANALYSIS_LOADING,
  GET_GRASS_ANALYSIS_SUCCESS,
  GET_GRASS_ANALYSIS_ERROR,
  DELETE_GRASS_ANALYSIS_LOADING,
  DELETE_GRASS_ANALYSIS_SUCCESS,
  DELETE_GRASS_ANALYSIS_ERROR,
  GRASS_ANALYSIS_SEARCH_LOADING,
  GRASS_ANALYSIS_SEARCH_SUCCESS,
  GRASS_ANALYSIS_SEARCH_ERROR
} from 'constants/store/GrassAnalysis';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';
import { POST, GET, DELETE } from 'constants/index';

/**
 * to register grass analysis
 * @param {String} farmId
 */
export const registerGrassAnalysis = (farmId, data) => async (dispatch, getState) => {
  dispatch({ type: ADD_GRASS_ANALYSIS_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${GRASS_ANALYSIS_URL}/registrations/farms/${farmId}?language=${GetLanguageCode(preferredLanguage)}`,
      method: POST,
      data
    })
  )
    .then(response => {
      dispatch({ type: ADD_GRASS_ANALYSIS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: ADD_GRASS_ANALYSIS_ERROR, payload: error, error: 'create' });
    });
};

/**
 * to get grass analysis
 * @param {String} farmId
 */
 export const getGrassAnalysis = (farmId) => async (dispatch, getState) => {
  dispatch({ type: GET_GRASS_ANALYSIS_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${GRASS_ANALYSIS_URL_VERSION_2}/farms/${farmId}?language=${GetLanguageCode(preferredLanguage)}`,
      method: GET
    })
  )
    .then(response => {
      dispatch({ type: GET_GRASS_ANALYSIS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_GRASS_ANALYSIS_ERROR, payload: error, error: 'get' });
    });
};

/**
 * to delete grass analysis
 * @param {String} farmId
 */
 export const deleteGrassAnalysis = (farmId, qrCode) => async (dispatch, getState) => {
  dispatch({ type: DELETE_GRASS_ANALYSIS_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${GRASS_ANALYSIS_URL}/farms/${farmId}/qr-codes/${qrCode}?language=${GetLanguageCode(preferredLanguage)}`,
      method: DELETE
    })
  )
    .then(response => {
      dispatch({ type: DELETE_GRASS_ANALYSIS_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: DELETE_GRASS_ANALYSIS_ERROR, payload: error, error: 'delete' });
    });
};

/**
 * to search grass analysis
 */
export const searchGrassAnalysis = (data) => async (dispatch, getState) => {
  dispatch({ type: GRASS_ANALYSIS_SEARCH_LOADING, payload: true });
  await dispatch(
    apiRequest({
      url: `${GRASS_ANALYSIS_SEARCH_URL}`,
      method: POST,
      data
    })
  )
    .then(response => {
      dispatch({ type: GRASS_ANALYSIS_SEARCH_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: GRASS_ANALYSIS_SEARCH_ERROR, payload: error });
    });
};
