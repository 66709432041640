import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Stack, Box, Chip, Grid, Typography, Menu, MenuItem } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import * as mineralFertilizerStorageActions from 'actions/MineralFertilizerStorage';
import Loader from 'components/Loader';
import ModalWindow from 'mui-components/storages/mineralstorage/ModalWindow';
import * as mineralFertilizerActions from 'actions/MineralFertilizer';

class MineralStorageListCard extends React.Component {
  constructor(props) {
    super(props);
    this.closeDialogWindowEdit = this.closeDialogWindowEdit.bind(this);
    this.selectedNameMethodEdit = this.selectedNameMethodEdit.bind(this);
    this.selectedWeightMethodEdit = this.selectedWeightMethodEdit.bind(this);
    this.selectedStorageMethodEdit = this.selectedStorageMethodEdit.bind(this);
    this.editNewMineralFertilizer = this.editNewMineralFertilizer.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.state = {
      open: true,
      showDelete: false,
      anchorEl: false,
      selectedId: null,
      openItems: [],
      openDialogWindowEdit: false,
      disableCreateButtonEdit: false,
      selectedNameEdit: '',
      selectedWeightEdit: '',
      selectedStorageEdit: ''
    };
  }
 
  componentDidMount = () => {
    const newOpenItems = [...this.state.openItems];
    newOpenItems[0] = true;
    this.setState({ openItems: newOpenItems });
  };

  componentDidUpdate = () => {
    const { openDialogWindowEdit, showDelete } = this.state;
    const { activeFarm, mineralFertilizerStorage, getMineralStorage } = this.props;
    const { isSuccess, isDeleteSuccess } = mineralFertilizerStorage;
    if(isSuccess && openDialogWindowEdit) {
      getMineralStorage(activeFarm.farm.id);
      this.setState({
        openDialogWindowEdit: false
      });
      mineralFertilizerStorage.isSuccess = false;
    }
    if(isDeleteSuccess && showDelete) {
      getMineralStorage(activeFarm.farm.id);
      this.setState({
        showDelete: false
      });
      mineralFertilizerStorage.isDeleteSuccess = false;
    }
  };

  handleListClick = (event, index) => {
    event.preventDefault();
    //this.setState({ [`open${index+1}`]: !this.state[`open${index+1}`] });
    const newOpenItems = [...this.state.openItems];
    // Toggle the state for the clicked item
    newOpenItems[index] = !newOpenItems[index];
    this.setState({ openItems: newOpenItems });
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  confirmDelete(event,selectedId) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
    this.setState({
      showDelete: true,
      selectedId: selectedId
    })
  }

  showDialog = action => {
    const { deleteMineralStorage, activeFarm } = this.props;
    const { selectedId } = this.state;
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
    if (action) {
      deleteMineralStorage(activeFarm.farm.id, selectedId);
    } else {
      this.setState({
        showDelete: false
      });
    }
  };

  editNewMineralFertilizer = (mineralList) => {
    this.setState({
      selectedNameEdit: mineralList.name,
      selectedWeightEdit: mineralList.weight,
      selectedStorageEdit: mineralList.storageBags,
      openDialogWindowEdit: true
    });
  }

  closeDialogWindowEdit = () => {
    this.setState({
      openDialogWindowEdit: false
    });
  }

  selectedNameMethodEdit = (event) => {
    const { selectedWeightEdit, selectedStorageEdit } = this.state;
    let errorCode = 0;
    this.setState({
      selectedNameEdit: event.target.value
    });
    if(event.target.value === '') {
      errorCode = 1;
    } else {
      errorCode = 0;
    }
    if(errorCode === 0 && selectedStorageEdit === '') {
      errorCode = 1;
    }
    if(errorCode === 0 && selectedWeightEdit !== '' && isNaN(selectedWeightEdit)) {
      errorCode = 1;
    }
    if(errorCode === 0 && selectedStorageEdit !== '' && isNaN(selectedStorageEdit)) {
      errorCode = 1;
    }
    if(errorCode === 0) {
      this.setState({
        disableCreateButtonEdit: false
      });
    } else {
      this.setState({
        disableCreateButtonEdit: true
      });
    }
  }

  selectedStorageMethodEdit = (event) => {
    const { selectedNameEdit, selectedWeightEdit } = this.state;
    let errorCode = 0;
    this.setState({
      selectedStorageEdit: event.target.value
    });
    if(selectedNameEdit === '') {
      errorCode = 1;
    }
    if(errorCode === 0) {
      if(event.target.value === '') {
        errorCode = 1;
      } else if(event.target.value !== '' && isNaN(event.target.value)) {
        errorCode = 1;
      } else {
        errorCode = 0;
      }
    }
    if(errorCode === 0 && selectedWeightEdit !== '' && isNaN(selectedWeightEdit)) {
      errorCode = 1;
    }
    if(errorCode === 0) {
      this.setState({
        disableCreateButtonEdit: false
      });
    } else {
      this.setState({
        disableCreateButtonEdit: true
      });
    }
  }

  selectedWeightMethodEdit = (event) => {
    const { selectedNameEdit, selectedStorageEdit } = this.state;
    let errorCode = 0;
    this.setState({
      selectedWeightEdit: event.target.value
    });
    if(selectedNameEdit === '') {
      errorCode = 1;
    }
    if(selectedStorageEdit === '') {
      errorCode = 1;
    }
    if(errorCode === 0) {
      if(event.target.value !== '' && isNaN(event.target.value)) {
        errorCode = 1;
      } else {
        errorCode = 0;
      }
    }
    if(errorCode === 0 && selectedStorageEdit !== '' && isNaN(selectedStorageEdit)) {
      errorCode = 1;
    }
    if(errorCode === 0) {
      this.setState({
        disableCreateButtonEdit: false
      });
    } else {
      this.setState({
        disableCreateButtonEdit: true
      });
    }
  }

  handleSubmitEdit = (mineralList) => {
    const mineralListValues = {...mineralList};
    const { activeFarm, updateMineralStorage } = this.props;
    const { selectedNameEdit, selectedWeightEdit, selectedStorageEdit } = this.state;
    mineralListValues.name = selectedNameEdit;
    mineralListValues.storageBags = parseInt(selectedStorageEdit);
    if(selectedWeightEdit !== '') {
      mineralListValues.weight = selectedWeightEdit;
    } else {
      mineralListValues.weight = '';
    }
    updateMineralStorage(activeFarm.farm.id, mineralListValues, mineralListValues.id);
    this.setState({
      disableCreateButton: false,
      selectedNameEdit: '',
      selectedWeightEdit: '',
      selectedStorageEdit: ''
    });
  }

  render() {
    const { mineralList, key, intl, index, mineralFertilizer, mineralFertilizerStorage } = this.props;
    const { isLoading, hasError, errorMsg } = mineralFertilizerStorage;
    const { showDelete } = this.state;
    const titleCss = { color: '#191C1C', fontWeight: '400', fontSize: '16px', fontFamily: 'Inter' };
    const subTitleCss = { ...titleCss, color: '#3F4949', fontSize: '14px', padding: '5px 0px' };
    const subTitlePaddingCss = { ...subTitleCss, paddingTop: '3px' };
    const subHeadingTextCss = { color: '#191C1C', fontFamily:'Inter', fontWeight: '500px', fontSize: '12px' };
    const subTextCss = { ...subHeadingTextCss, fontSize: '16px' };
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    }
    return (
      <Box sx={{ width: '100%', flexGrow: 1, padding: '30px' }}>
        {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', border: 1, borderRadius: '5px', borderColor: '#dddddd', padding: '0', paddingBottom: '0' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton disableRipple onClick={(e) => this.handleListClick(e, index)}  key={key} 
            sx={{ bgcolor: '#f1f1f1', borderBottom: 1, borderColor: '#dddddd', borderTopRadius: '5px' }}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={6}>
                <Typography variant="body2" style={subTitleCss}>
                  <span style={{ color: '#191C1C', fontWeight: '400', fontSize: '16px', fontFamily: 'Inter' }}>{mineralList.name}</span><br/>
                  <span>{intl.formatMessage({ id: 'CREATED' })}: {mineralList.createdTime === null ? '' : mineralList.createdTime.split("T")[0]}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ paddingTop: '10px' }}>
                <Stack direction="row" justifyContent="end">
                  <Box component='span' className='grid-padding-dot-mf'>
                    <Typography variant="body2" style={subTitlePaddingCss}>
                      {intl.formatMessage({ id: 'WEIGHT_IN_KG' })}:&nbsp;
                      <span style={{ fontWeight: '700' }}>{mineralList.product === null ? '' : mineralList.product+' kg'}</span>
                    </Typography>
                  </Box>
                  <Chip
                    icon={<CreateOutlinedIcon fontSize='small' />}
                    label={<FormattedMessage id="EDIT" />}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => this.editNewMineralFertilizer(mineralList)}
                    sx ={{color: '#cc0000', borderColor: '#cc0000'}}
                    className='grid-padding'
                  />
                  <Box component='span' className='grid-padding-dot-mf'>
                    <MoreVertIcon onClick={this.handleClick} sx={{ cursor: 'pointer' }} />
                    <Menu
                      id="basic-menu"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={(e)=>this.confirmDelete(e,mineralList.id)} style={menuItemStyle}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
                        }}
                      >
                        {intl.formatMessage({ id: 'DELETE' })}
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            {this.state.openItems[index] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={this.state.openItems[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ paddingTop: '20px', paddingBottom: '20px'}}>
              <ListItemButton disableRipple>
                <Grid container>
                  <Grid item sx={{ paddingRight: '10%' }}>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="updated" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>
                      {mineralList.lastUpdatedTime === null ? '' : mineralList.lastUpdatedTime.split("T")[0]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" style={subHeadingTextCss}><FormattedMessage id="WEIGHT_IN_KG" /></Typography>
                    <Typography variant="subtitle1" style={subTextCss}>
                      {mineralList.storageBags === null ? '' : mineralList.storageBags+' sekker'}<br/>
                      <span style={{ fontWeight: '400', fontSize: '14px' }}>{mineralList.weight === null ? '' : mineralList.weight+' kg'}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        <ModalWindow
          intl={intl}
          openDialogWindowEdit={this.state.openDialogWindowEdit}
          closeDialogWindowEdit={this.closeDialogWindowEdit}
          disableCreateButtonEdit={this.state.disableCreateButtonEdit}
          mineralFertilizer={mineralFertilizer}
          selectedNameMethodEdit={this.selectedNameMethodEdit}
          selectedWeightMethodEdit={this.selectedWeightMethodEdit}
          selectedStorageMethodEdit={this.selectedStorageMethodEdit}
          handleSubmitEdit={this.handleSubmitEdit}
          hasError={hasError}
          errorMsg={errorMsg && errorMsg.data}
          mineralList={mineralList}
        />
        {showDelete && (
          <ConfirmDialogBox
            heading={`${intl.formatMessage({ id: 'MF_DELETE_HEADING' })}?`}
            body={intl.formatMessage({ id: 'MF_DELETE_MESSAGE' })}
            confirmText="DELETE"
            cancelText="CANCEL"
            callback={action => this.showDialog(action)}
            warning
          />
        )}
      </Box>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  mineralFertilizer: state.mineralFertilizer,
  mineralFertilizerStorage: state.mineralFertilizerStorage
});

const mapDispatchToProps = dispatch => ({
  mineralFertilizerList: () => dispatch(mineralFertilizerActions.mineralFertilizerList()),
  deleteMineralStorage: (farmId, storageId) => dispatch(mineralFertilizerStorageActions.deleteMineralStorage(farmId, storageId)),
  updateMineralStorage: (farmId, data, storageId) => dispatch(mineralFertilizerStorageActions.UpdateMineralStorage(farmId, data, storageId)),
  getMineralStorage: (farmId) => dispatch(mineralFertilizerStorageActions.fetchMineralStorageList(farmId))
})

export default reduxForm({
  form: 'MineralStorageListCardForm'
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MineralStorageListCard)));
 