import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import Text from '@tine/lib-frontend-components/elements/typography/text';

import ProfileImage from 'components/ProfileImage';
import Loader from 'components/Loader';
import KpiCard from 'mui-mobile-components/stats/KpiCard';
import NitrogenKpiCard from 'mui-mobile-components/stats/NitrogenKpiCard';
import * as StatsAction from 'actions/Stats';
import requestProfileImg from 'actions/ProfilePicture';
/*import { IMG_DOWNLOAD_REQUEST } from 'constants/index';*/
import { MinsToHrsMins } from 'utils';
import { ADD_TASK, ADD_FIELD, ENTER_BALE_STORAGE } from 'constants/MixPanel';
import NitrogenKpiCardDialog from 'mui-mobile-components/stats/NitrogenKpiCardDialog';
import { Dialog } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { EXPAND_OVER_FERTILIZATION, EXPAND_UNDER_FERTILIZATION } from 'constants/MixPanel';

class StatsActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldWorkedHours: 0,
      filterValue: { value: 'All fields', label: 'All fields' },
      cutHarvest: [],
      totalHarvestAmount: 0,
      fieldFilterValue: {},
      harvestFilterValue: {},
      selectedHarvestFieldId: '',
      selectedHarvestId: '',
      dryMatterHarvest: [],
      totalDryMatterAmount: 0,
      fieldDryMatterFilterValue: {},
      dryMatterFilterValue: {},
      selectedDryMatterFieldId: '',
      selectedDryMatterId: '',
      totalDryMatterHarvest: [],
      totalTotalDryMatterAmount: 0,
      fieldTotalDryMatterFilterValue: {},
      totalDryMatterFilterValue: {},
      totalSelectedDryMatterFieldId: '',
      totalSelectedDryMatterIdf: '',
      initialUpdate: false,
      selectYear: null,
      open1: false,
      open2: false,
      open3: false,
      selectedValue: 'second'
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleAlignment = this.handleAlignment.bind(this);
  }

  componentDidMount = () => {
    const { activeFarm, fetchStatsActivities } = this.props;
    this.resetWorkedHours();
    if (activeFarm && this.state.selectYear !== this.props.yearSelected) {
      fetchStatsActivities(activeFarm.farm.id, this.props.yearSelected);
    } else {
      const { history } = this.props;
      history.push('/settings');
    }
    this.initialSetup();
    this.setState({
      initialUpdate: false,
      selectYear: this.props.yearSelected
    });
  };

  componentDidUpdate = prevProps => {
    const { activeFarm, fetchStatsActivities } = this.props;
    let {
      props: {
        statsDetails: { activities }
      },
      state: { initialUpdate }
    } = this;
    if (activeFarm !== prevProps.activeFarm || this.state.selectYear !== this.props.yearSelected) {
      this.setState({
        initialUpdate: false,
        selectYear: this.props.yearSelected
      });
      activities.data = null;
      fetchStatsActivities(activeFarm.farm.id, this.props.yearSelected);
      this.resetWorkedHours();
      this.initialSetup();
    }
    if(!initialUpdate) {
      this.initialSetup();
    }
  };

  initialSetup = () => {
    const {
      props: {
        statsDetails: { activities }
      }
    } = this;
    const { data } = activities;    
    if(data && data !== null) {
      /** Initial setup of harvest */
      if (data.harvestData.length > 0 && data.harvestData[0].cutHarvests.length > 0) {
        this.setState({
          cutHarvest: this.harvestFilterOption(data.harvestData[0].cutHarvests),
          fieldFilterValue: { value: data.harvestData[0].name, label: data.harvestData[0].name },
          harvestFilterValue: { value: data.harvestData[0].cutHarvests[0].name, label: data.harvestData[0].cutHarvests[0].name },
          totalHarvestAmount: data.harvestData[0].cutHarvests[0].value,
          selectedHarvestFieldId: data.harvestData[0].id,
          selectedHarvestId: data.harvestData[0].cutHarvests[0].name
        });          
      } else {
        this.setState({
          cutHarvest: [],
          fieldFilterValue: {},
          harvestFilterValue: {},
          totalHarvestAmount: '0',
          selectedHarvestFieldId: '',
          selectedHarvestId: ''

        });
      }

      /** Initial setup of drymatter */
      if (data.harvestData.length > 0 && data.harvestData[0].dryMatterHarvests.length > 0) {
        this.setState({
          dryMatterHarvest: this.harvestFilterOption(data.harvestData[0].dryMatterHarvests),
          fieldDryMatterFilterValue: { value: data.harvestData[0].name, label: data.harvestData[0].name },
          dryMatterFilterValue: { value: data.harvestData[0].dryMatterHarvests[0].name, label: data.harvestData[0].dryMatterHarvests[0].name },
          totalDryMatterAmount: data.harvestData[0].dryMatterHarvests[0].value,
          selectedDryMatterFieldId: data.harvestData[0].id,
          selectedDryMatterIdf: data.harvestData[0].dryMatterHarvests[0].name
        });          
      } else {
        this.setState({
          dryMatterHarvest: [],
          totalDryMatterAmount: 0,
          fieldDryMatterFilterValue: {},
          dryMatterFilterValue: {},
          selectedDryMatterFieldId: '',
          selectedDryMatterId: ''
        });
      }
      /** Initial setup of total drymatter */
      if (data.harvestData.length > 0 && data.harvestData[0].totalDryMatterHarvests.length > 0) {
        this.setState({
          totalDryMatterHarvest: this.harvestFilterOption(data.harvestData[0].totalDryMatterHarvests),
          fieldTotalDryMatterFilterValue: { value: data.harvestData[0].name, label: data.harvestData[0].name },
          totalDryMatterFilterValue: { value: data.harvestData[0].totalDryMatterHarvests[0].name, label: data.harvestData[0].totalDryMatterHarvests[0].name },
          totalTotalDryMatterAmount: data.harvestData[0].totalDryMatterHarvests[0].value,
          totalSelectedDryMatterFieldId: data.harvestData[0].id,
          totalSelectedDryMatterIdf: data.harvestData[0].totalDryMatterHarvests[0].name
        });          
      } else {
        this.setState({
          totalDryMatterHarvest: [],
          totalTotalDryMatterAmount: 0,
          fieldTotalDryMatterFilterValue: {},
          totalDryMatterFilterValue: {},
          totalSelectedDryMatterFieldId: '',
          totalSelectedDryMatterIdf: ''
        });
      }
      this.setState({
        initialUpdate: true
      });
    }
  }

  resetWorkedHours = () => {
    const { intl } = this.props;
    this.setState({
      fieldWorkedHours: 0,
      filterValue: { value: 'All fields', label: intl.formatMessage({ id: 'ALL_FIELDS' }) }
    });
  };

  /**
   * to show slected filed worked hours
   * @param {Object} field
   */
  onWorkFilterChange = field => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    if (field.value === 'All fields') {
      this.setState({
        fieldWorkedHours: this.getHrsMins(data.totalWorkdoneInAllFields),
        filterValue: field
      });
    } else {
      const [seletedField] = _.filter(data.workdoneInFields, ['id', field.value]);
      this.setState({
        fieldWorkedHours: this.getHrsMins(seletedField.hours),
        filterValue: field
      });
    }
  };

  /**
   * to show selected harvest
   * @param {Object} harvest
   */
   onHarvestFilterChange = harvest => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    const { selectedHarvestFieldId } = this.state;
    const [seletedField] = _.filter(data.harvestData, ['id', selectedHarvestFieldId]);
    const [selectedHarvest] = _.filter(seletedField.cutHarvests, ['name', harvest.value]);
    this.setState({
      harvestFilterValue: { value: selectedHarvest.name, label: selectedHarvest.name },
      totalHarvestAmount: selectedHarvest.value,
      selectedHarvestId: selectedHarvest.name
    });
  };

  /**
   * to show selected drymatter
   * @param {Object} drymatter
   */
   onDryMatterFilterChange = drymatter => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    const { selectedDryMatterFieldId } = this.state;
    const [seletedField] = _.filter(data.harvestData, ['id', selectedDryMatterFieldId]);
    const [selectedDrymatter] = _.filter(seletedField.dryMatterHarvests, ['name', drymatter.value]);
    this.setState({
      dryMatterFilterValue: { value: selectedDrymatter.name, label: selectedDrymatter.name },
      totalDryMatterAmount: selectedDrymatter.value,
      selectedDryMatterId: selectedDrymatter.name
    });
  };

  /**
   * to show selected drymatter
   * @param {Object} drymatter
   */
   onTotalDryMatterFilterChange = drymatter => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    const { totalSelectedDryMatterFieldId } = this.state;
    const [seletedField] = _.filter(data.harvestData, ['id', totalSelectedDryMatterFieldId]);
    const [selectedDrymatter] = _.filter(seletedField.totalDryMatterHarvests, ['name', drymatter.value]);
    this.setState({
      totalDryMatterFilterValue: { value: selectedDrymatter.name, label: selectedDrymatter.name },
      totalTotalDryMatterAmount: selectedDrymatter.value,
      totalSelectedDryMatterId: selectedDrymatter.name
    });
  };

  /**
   * to show selected field harvest
   * @param {Object} field harvest
   */
   onFieldFilterChange = field => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    const { selectedHarvestId, cutHarvest } = this.state;
    const [fieldHarvest] = _.filter(data.harvestData, ['id', field.value]);
    if(JSON.stringify(cutHarvest) !== JSON.stringify(this.harvestFilterOption(fieldHarvest.cutHarvests))) {
      this.setState({
        cutHarvest: this.harvestFilterOption(fieldHarvest.cutHarvests),
        harvestFilterValue: { value: fieldHarvest.cutHarvests[0].name, label: fieldHarvest.cutHarvests[0].name },
        totalHarvestAmount: fieldHarvest.cutHarvests[0].value,
        selectedHarvestId: fieldHarvest.cutHarvests[0].name
      });
    } 
    else {
      const [selectedHarvest] = _.filter(fieldHarvest.cutHarvests, ['name', selectedHarvestId]);
      this.setState({
        totalHarvestAmount: selectedHarvest.value
      });
    }
    this.setState({
      fieldFilterValue: { value: fieldHarvest.name, label: fieldHarvest.name },
      selectedHarvestFieldId: fieldHarvest.id
    });
  };

  /**
   * to show selected field drymatter
   * @param {Object} field drymatter
   */
   onDryMatterFieldFilterChange = field => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    const { selectedDryMatterId, dryMatterHarvest } = this.state;
    const [fieldHarvest] = _.filter(data.harvestData, ['id', field.value]);
    if(JSON.stringify(dryMatterHarvest) !== JSON.stringify(this.harvestFilterOption(fieldHarvest.dryMatterHarvests))) {
      this.setState({
        dryMatterHarvest: this.harvestFilterOption(fieldHarvest.dryMatterHarvests),
        dryMatterFilterValue: { value: fieldHarvest.dryMatterHarvests[0].name, label: fieldHarvest.dryMatterHarvests[0].name },
        totalDryMatterAmount: fieldHarvest.dryMatterHarvests[0].value,
        selectedDryMatterId: fieldHarvest.dryMatterHarvests[0].name
      });
    } 
    else {
      const [selectedHarvest] = _.filter(fieldHarvest.dryMatterHarvests, ['name', selectedDryMatterId]);
      this.setState({
        totalDryMatterAmount: selectedHarvest.value
      });
    }
    this.setState({
      fieldDryMatterFilterValue: { value: fieldHarvest.name, label: fieldHarvest.name },
      selectedDryMatterFieldId: fieldHarvest.id
    });
  };

  /**
   * to show selected total field drymatter
   * @param {Object} total field drymatter
   */
   onTotalDryMatterFieldFilterChange = field => {
    const {
      statsDetails: {
        activities: { data }
      }
    } = this.props;
    const { totalSelectedDryMatterId, totalDryMatterHarvest } = this.state;
    const [fieldHarvest] = _.filter(data.harvestData, ['id', field.value]);
    if(JSON.stringify(totalDryMatterHarvest) !== JSON.stringify(this.harvestFilterOption(fieldHarvest.totalDryMatterHarvests))) {
      this.setState({
        totalDryMatterHarvest: this.harvestFilterOption(fieldHarvest.totalDryMatterHarvests),
        totalDryMatterFilterValue: { value: fieldHarvest.totalDryMatterHarvests[0].name, label: fieldHarvest.totalDryMatterHarvests[0].name },
        totalTotalDryMatterAmount: fieldHarvest.totalDryMatterHarvests[0].value,
        totalSelectedDryMatterId: fieldHarvest.totalDryMatterHarvests[0].name
      });
    } 
    else {
      const [selectedHarvest] = _.filter(fieldHarvest.totalDryMatterHarvests, ['name', totalSelectedDryMatterId]);
      this.setState({
        totalTotalDryMatterAmount: selectedHarvest.value
      });
    }
    this.setState({
      fieldTotalDryMatterFilterValue: { value: fieldHarvest.name, label: fieldHarvest.name },
      totalSelectedDryMatterFieldId: fieldHarvest.id
    });
  };

  getHrsMins = hrs => {
    const { intl } = this.props;
    if (hrs < 1) {
      return `0${intl.formatMessage({ id: 'HRS_UNIT' })} 0${intl.formatMessage({
        id: 'MINS_UNIT'
      })}`;
    }
    const hrsMins = MinsToHrsMins(hrs * 60);
    return `${hrsMins.hours}${intl.formatMessage({ id: 'HRS_UNIT' })} ${
      hrsMins.minutes
    }${intl.formatMessage({ id: 'MINS_UNIT' })}`;
  };

  getHours = hrs => {
    if (hrs < 1) {
      return 0;
    }
    const hrsMins = MinsToHrsMins(hrs * 60);
    return hrsMins.hours;
  };

  getMinutes = min => {
    const hrsMins = MinsToHrsMins(min * 60);
    return hrsMins.minutes;
  };

  /**
   * to get fields dropdown
   * @param {array} workdoneInFields
   * @returns {array}
   */
  filterOption = workdoneInFields => {
    const { intl } = this.props;
    if (workdoneInFields?.length > 0) {
      let options = workdoneInFields.map(fields => ({ value: fields.id, label: fields.name }));
      options = _.orderBy(options, [data => data.label.toLowerCase()], ['asc']);
      options.unshift({ value: 'All fields', label: intl.formatMessage({ id: 'ALL_FIELDS' }) });
      return options;
    }
    return [];
  };

  /**
   * to get fields dropdown
   * @param {array} workdoneInFields
   * @returns {array}
   */
   fieldFilterOption = workdoneInFields => {
    if (workdoneInFields?.length > 0) {
      return workdoneInFields.map(fields => ({ value: fields.id, label: fields.name }));
    }
    return [];
  };

  /**
   * to get harvest dropdown
   * @param {array} harvestInFields
   * @returns {array}
   */
   harvestFilterOption = harvestInFields => {
    if (harvestInFields?.length > 0) {
      return harvestInFields.map(fields => ({ value: fields.name, label: fields.name }));
    }
    return [];
  };

  /**
   * to get the Kpi description
   * @param {string} type
   * @returns {string}
   */
  getKpiDescription = type => {
    const {
      statsDetails: {
        activities: { data }
      },
      intl
    } = this.props;
    switch (type) {
      case 'field':
        if (data.totalWorkdoneInAllFields <= 0) {
          return data.workdoneInFields.length > 0
            ? intl.formatMessage({ id: 'NO_WORK' })
            : intl.formatMessage({ id: 'NO_FIELD' });
        }
        return intl.formatMessage({ id: 'HRS_YR' });
      case 'manure':
        if (data.manureUsage <= 0) {
          return intl.formatMessage({ id: 'NO_MANURE_EQUIPMENT' });
        }
        return intl.formatMessage({ id: 'TOTAL_YEAR' });
      case 'mineral':
        if (data.mineralFertilizerUsage <= 0) {
          return intl.formatMessage({ id: 'NOT_USED_MINERAL' });
        }
        return intl.formatMessage({ id: 'TOTAL_YEAR' });
      case 'harvest':
        if (data.harvestData.length <= 0) {
          return intl.formatMessage({ id: 'HARVEST_MISSING' });
        }
        return intl.formatMessage({ id: 'TOTAL_YEAR' });
      case 'drymatter':
        if (data.harvestData.length <= 0) {
          return intl.formatMessage({ id: 'DRYMATTER_MISSING' });
        }
        return intl.formatMessage({ id: 'TOTAL_YEAR' });
      case 'totaldrymatter':
        if (data.harvestData.length <= 0) {
          return intl.formatMessage({ id: 'TOTAL_DRYMATTER_MISSING' });
        }
        return intl.formatMessage({ id: 'TOTAL_YEAR' });
      default:
        return '';
    }
  };

  handleClick = (event, selected) => {
    const { userDetails } = this.props;
    const { open1, open2, open3 } = this.state;
    if(selected === 1) {
      if(!open1) {
        mixpanel.identify(userDetails.id);
        mixpanel.track(EXPAND_OVER_FERTILIZATION);
      }
      this.setState({
        open1: !open1
      });
    } else if(selected === 2) {
      if(!open2) {
        mixpanel.identify(userDetails.id);
        mixpanel.track(EXPAND_UNDER_FERTILIZATION);
      }
      this.setState({
        open2: !open2
      });
    } else {
      this.setState({
        open3: !open3
      });
    }
  };

  getUserList = workdoneByUsers => {
    const { profilePictures, /*AWSLoginDetails, fetchProfileImg*/ } = this.props;
    return workdoneByUsers.map(user => {
      const userImage = _.find(profilePictures, ['userId', user.id]);
      /*if (!userImage) {
        fetchProfileImg(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, user.id, 'userImage');
      }*/
      return (
        <li className="full-width" key={user.id}>
          <ProfileImage size="xsmall" imgUrl={userImage ? userImage.image : null} />
          <Text>{user.name}</Text>
          <Emphasis>{this.getHrsMins(user.hours)}</Emphasis>
        </li>
      );
    });
  };

  handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      this.setState ({
        selectedValue: newAlignment
      });
    }
  };

  render() {
    const {
      props: {
        statsDetails: { statsHeader, activities },
        intl,
        history,
        userDetails,
        onSelectChange,
        completeSelectedYear,
        openNitrogenListModal,
        nitrogenListModal,
        handleYearChange,
        onFieldChange,
        selectedNitrogen,
        handleSelectedNitrogen,
        selectedFieldCheckbox
      },
      state: { filterValue, cutHarvest, fieldFilterValue, harvestFilterValue, totalHarvestAmount,
               dryMatterHarvest, fieldDryMatterFilterValue, dryMatterFilterValue, totalDryMatterAmount,
               totalDryMatterHarvest, fieldTotalDryMatterFilterValue, totalDryMatterFilterValue, totalTotalDryMatterAmount, fieldWorkedHours }
    } = this;
    const isLoading = activities.isLoading || statsHeader.isLoading;
    const { data } = activities;
    let totalHrs = 0;
    let totalHours = 0;
    let totalMinutes = 0;
    if(data && data.totalWorkdoneInAllFields > 0) {
      if(data && data.totalWorkdoneInAllFields > 0 && filterValue.value === 'All fields') {
        totalHours = this.getHours(data.totalWorkdoneInAllFields);
        totalMinutes = this.getMinutes(data.totalWorkdoneInAllFields);
      } else {
        const [seletedField] = _.filter(data.workdoneInFields, ['id', filterValue.value]);
        totalHours = this.getHours(seletedField.hours);
        totalMinutes = this.getMinutes(seletedField.hours);
      }
    }
    const { open1, open2, open3, selectedValue } = this.state;
    return (
      openNitrogenListModal ? 
        <Dialog onClose={() => {}} open={openNitrogenListModal} PaperProps={{
          style: {
            backgroundColor: '#ffffff',
            borderRadius: "28px",
            width: "80%",
            minWidth: "80%",
            maxWidth: "80%",
            height: "90%"
          }}}>
          <NitrogenKpiCardDialog
            nitrogenListModal={nitrogenListModal}
            selectedValue={selectedValue}
            intl={intl}
            onSelectChange={onSelectChange}
            completeSelectedYear={completeSelectedYear}
            fertilizationEfficiency={data && data.fertilizationEfficiency}
            data={data}
            handleYearChange={handleYearChange}
            selectYear={this.state.selectYear}
            onFieldChange={onFieldChange}
            selectedNitrogen={selectedNitrogen}
            handleSelectedNitrogen={handleSelectedNitrogen}
            selectedFieldCheckbox={selectedFieldCheckbox}
          />
        </Dialog>
       :
      <div className="stats-activity">
        {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        {data && (
          <div className="div-kpi-box">
            <div className={`kpi-box-nitrogen`}>
              <NitrogenKpiCard
                open1={open1}
                open2={open2}
                open3={open3}
                handleClick={this.handleClick}
                intl={intl}
                nitrogenListModal={nitrogenListModal}
                data={data}
              />
            </div>
            <KpiCard
              title={intl.formatMessage({ id: 'WORK_FIELD' })}
              description={this.getKpiDescription('field')}
              route={data.workdoneInFields.length === 0 ? '/managefields/new' : '/tasks/tasklist/create'}
              routeText={data.workdoneInFields.length === 0 ? intl.formatMessage({ id: 'CREATE_FIELD' }) : intl.formatMessage({ id: 'ADD_TASK_STATS' })}
              totalAmount={totalHrs}
              totalHours={totalHours}
              totalMinutes={totalMinutes}
              value={data.totalWorkdoneInAllFields}
              filterOption={this.filterOption(data.workdoneInFields)}
              filterValue={filterValue}
              valueUnit1={intl.formatMessage({ id: 'HRS_UNIT' })}
              valueUnit2={intl.formatMessage({ id: 'MINS_UNIT' })}
              onFilterChange={e => {
                this.onWorkFilterChange(e);
              }}
              isLoading={isLoading}
              history={history}
              totalDuration={data.totalWorkdoneInAllFields}
              fieldWorkedHours={fieldWorkedHours}
              mixPanelDetails={data.workdoneInFields.length === 0 ? ADD_FIELD : ADD_TASK}
              userDetails={userDetails}
            />
            <KpiCard
              title={intl.formatMessage({ id: 'USE_MANURE' })}
              description={this.getKpiDescription('manure')}
              value={data.manureUsage}
              valueUnit="m3"
              isLoading={isLoading}
            />
            <KpiCard
              title={intl.formatMessage({ id: 'USE_MINERAL' })}
              description={this.getKpiDescription('mineral')}
              value={data.mineralFertilizerUsage}
              valueUnit="kg"
              isLoading={isLoading}
            />
            <FlexGridItem>
              <div
                className={`kpi-box kpi-box--users${
                  data.totalWorkdoneInAllFields > 0 ? ' kpi-box--active' : ''
                }`}
              >
                <div className="kpi-box__title">
                  <EmphasisLight size="small">
                    {intl.formatMessage({ id: 'WORK_CARRIED' })}
                  </EmphasisLight>
                </div>
                <ul className="kpi-user-list">{this.getUserList(data.workdoneByUsers)}</ul>
              </div>
            </FlexGridItem>
            <KpiCard
              title={intl.formatMessage({ id: 'AMOUNT_BALES' })}
              description={this.getKpiDescription('harvest')}
              totalHarvestAmount={totalHarvestAmount}
              harvestValue={totalHarvestAmount}
              valueUnit="rundballer"
              fieldFilterOption={this.fieldFilterOption(data.harvestData)}
              harvestFilterOption={cutHarvest}
              fieldFilterValue={fieldFilterValue}
              harvestFilterValue={harvestFilterValue}
              onFieldFilterChange={e => {
                this.onFieldFilterChange(e);
              }}
              onHarvestFilterChange={e => {
                this.onHarvestFilterChange(e);
              }}
              isLoading={isLoading}
              route={data.harvestData.length <= 0 ? '/storages' : ''}
              routeText={intl.formatMessage({ id: 'ADD_BALE_TITLE' })}
              history={history}
              mixPanelDetails={ENTER_BALE_STORAGE}
              userDetails={userDetails}
            />
            <KpiCard
              title={intl.formatMessage({ id: 'AMOUNT_DRY_MATTER' })}
              description={this.getKpiDescription('drymatter')}
              totalHarvestAmount={totalDryMatterAmount}
              harvestValue={totalDryMatterAmount}
              valueUnit="kg/daa"
              fieldFilterOption={this.fieldFilterOption(data.harvestData)}
              harvestFilterOption={dryMatterHarvest}
              fieldFilterValue={fieldDryMatterFilterValue}
              harvestFilterValue={dryMatterFilterValue}
              onFieldFilterChange={e => {
                this.onDryMatterFieldFilterChange(e);
              }}
              onHarvestFilterChange={e => {
                this.onDryMatterFilterChange(e);
              }}
              isLoading={isLoading}
            />
            <KpiCard
              title={intl.formatMessage({ id: 'TOTAL_AMOUNT_DRYMATTER' })}
              description={this.getKpiDescription('totaldrymatter')}
              totalHarvestAmount={totalTotalDryMatterAmount}
              harvestValue={totalTotalDryMatterAmount}
              valueUnit="kg"
              fieldFilterOption={this.fieldFilterOption(data.harvestData)}
              harvestFilterOption={totalDryMatterHarvest}
              fieldFilterValue={fieldTotalDryMatterFilterValue}
              harvestFilterValue={totalDryMatterFilterValue}
              onFieldFilterChange={e => {
                this.onTotalDryMatterFieldFilterChange(e);
              }}
              onHarvestFilterChange={e => {
                this.onTotalDryMatterFilterChange(e);
              }}
              isLoading={isLoading}
            />
          </div>            
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  statsDetails: state.statsDetails,
  activeFarm: state.farmList.activeFarm,
  profilePictures: state.profileImage.userImage,
  userDetails: state.userDetails
  /*AWSLoginDetails: state.AWSLogin.data*/
});

const mapDispatchToProps = dispatch => ({
  fetchStatsActivities: (farmId, yearSelected) => dispatch(StatsAction.fetchStatsActivities(farmId, yearSelected)),
  fetchProfileImg: (awsLogin, type, userId, userType) =>
    dispatch(requestProfileImg(awsLogin, type, userId, userType))
});
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(StatsActivity)));
