import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import { AlertIcon } from '@tine/lib-frontend-components/elements/icons';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import ExpandingContainer from '@tine/lib-frontend-components/components/accordion/expanding-container';
import Loader from 'components/Loader';
import SoilListContent from 'mui-components/fields/soilsamplesimport/SoilListContent';
import SoilListHeader from 'mui-components/fields/soilsamplesimport/SoilListHeader';
import * as SoilSamplesAction from 'actions/SoilSamples';
import { OWNER, ADMIN, ADVISOR } from 'constants/index';

class SoilSampleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleSoilValue: null
    };
  }
  componentDidMount = () => {
    const { getSoilSampleList, activeFarm } = this.props;
    getSoilSampleList(activeFarm.farm.id);
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getSoilSampleList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      getSoilSampleList(activeFarm.farm.id);
    }
  }

  getList = () => {
    const {
      soilSamples: { sampleList },
      labels,
      activeFarm,
      hasSupportAdminRole
    } = this.props;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    if (sampleList && sampleList.soilSampleResults && sampleList.soilSampleResults.length > 0) {
      return sampleList.soilSampleResults.map((soilItem, index) => (
        <ExpandingContainer
          key={index}
          collapsedContent={
            <SoilListHeader labels={labels} values={soilItem} hasPermission={hasPermission} />
          }
          expanded={index === 0}
          expandedContent={
            <SoilListContent
              labels={labels}
              values={soilItem.sampleFieldMap}
              hasPermission={hasPermission}
            />
          }
          withTimeline={false}
        />
      ));
    }
    return null;
  };

  getSampleInfo = () => {
    const { soilSamples } = this.props;
    if (soilSamples.sampleList) {
      const { sampleList } = soilSamples;
      return (
        <div className="sample-uploaded-info">
          <AlertIcon scale={0.75} />
          <p className="field-info-text">
            <Text size="small">{sampleList.unMappedFieldMessage} </Text>
          </p>
          <p>
            <EmphasisLight size="small">{sampleList.unMappedFieldNames}</EmphasisLight>
          </p>
        </div>
      );
    }
    return null;
  };

  render() {
    const { soilSamples } = this.props;
    return (
      <SectionContainer
        styling={{
          paddingTop: 'low',
          paddingBottom: 'low',
          paddingLeft: 'none',
          paddingRight: 'none'
        }}
      >
        {soilSamples.isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
        {this.getSampleInfo()}
        {this.getList()}
      </SectionContainer>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  soilSamples: state.soilSamples,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getSoilSampleList: farmId => dispatch(SoilSamplesAction.getSoilSampleList(farmId))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SoilSampleList)));
