import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter, Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
import FertilizerModal from 'containers/fertilization/planner/FertilizerModal';
import FertilizerPlanList from 'containers/fertilization/planner/FertilizerPlanList';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import { AddIcon } from '@tine/lib-frontend-components/elements/icons';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import { OWNER, ADMIN, ADVISOR, FILTER_TASK_STATUS, FILTER_TASK_ACTIVITY, FILTER_TASK_FIELD } from 'constants/index';
import * as FertilizerAction from 'actions/FertilizationPlan';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import LoaderDynamic from 'components/LoaderDynamic';
import Loader from 'components/Loader';
import mixpanel from 'mixpanel-browser';
import { START_NEW_PLAN, CREATE_NEW_PLAN, EDIT_PLAN, EXPORT_PLAN, VIEW_PLAN, UPDATE_FP_PLANNING, UPDATE_FP_RECALCULATE } from 'constants/MixPanel';
import generatePdfDocument from 'containers/fertilization/planner/FertilizerPlanPdf';
import { SetSessionData } from 'utils';
import { TASK_PERIOD_VALUE } from 'constants/store/Tasks';
import {
  taskListSortByOption
} from 'actions/Tasks';

class FertilizerPlanner extends React.Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      createFertilizer: false,
      createTask: false,
      updateTask: false,
      createTaskConfirm: false,
      createNewFertilizer: false,
      createValue: false,
      formData: null,
      confirmDialog: false,
      confirmErrorMessage: null,
      viewFertilizer: false,
      processLoading: false,
      keepExisting: false,
      generatePdf: false,
      selectedYear: null,
      createTaskPopup: false,
      taskPage: false,
      updateDataValue: null,
      mineralCalculationOn: false,
      mineralCalculationOnUpdate: false,
      timerCount: 0,
      timerErrorValue: false,
      displayErrorFields: false
    };
    this.createFertilizerPlan = this.createFertilizerPlan.bind(this);
    this.createFertilizerTask = this.createFertilizerTask.bind(this);
    this.updateFertilizerPlanModal = this.updateFertilizerPlanModal.bind(this);
    this.closeFertilizerPlan = this.closeFertilizerPlan.bind(this);
    this.closeUpdateTask = this.closeUpdateTask.bind(this);
    this.updateFertilizerPlan = this.updateFertilizerPlan.bind(this);
    this.onChangeTextBox = this.onChangeTextBox.bind(this);
    this.handleSubmitModel = this.handleSubmitModel.bind(this);
    this.keepExistingPlanModel = this.keepExistingPlanModel.bind(this);
    this.discardExistingPlanModel = this.discardExistingPlanModel.bind(this);
    this.closeCreateTaskModel=this.closeCreateTaskModel.bind(this);
    this.createTaskModel=this.createTaskModel.bind(this);
    this.goToTaskPage=this.goToTaskPage.bind(this);
    this.toggleMineralCalculationOn=this.toggleMineralCalculationOn.bind(this);
    this.toggleMineralCalculationOnUpdate=this.toggleMineralCalculationOnUpdate.bind(this);
    this.getEmptyFieldFertilization=this.getEmptyFieldFertilization.bind(this);
    this.closeDisplayErrorFields = this.closeDisplayErrorFields.bind(this);
  }

  getInitialize = () => {
    const { initialize } = this.props;
    const formValues = {
      planName: '',
      year: []
    };
    initialize(formValues);
  };

  componentDidMount = () => {
    const { fertilizationPlan, activeFarm, fertilizationPlanList } = this.props;
    fertilizationPlan.saveSuccess = false;
    fertilizationPlanList(activeFarm.farm.id);
    this.setState({
      keepExisting: false
    });
    this.getInitialize();
  };

  componentDidUpdate(prevProps) {
    const { formData, confirmDialog, processLoading, keepExisting, generatePdf, createTaskConfirm, 
      createTaskPopup, mineralCalculationOn, timerCount, timerErrorValue, displayErrorFields } = this.state;
    const {
      history,
      intl,
      activeFarm,
      fertilizationPlan,
      createFertilizationPlan,
      fertilizationPlanList,
      getCreatedFertilizationPlan
    } = this.props;
    const {
      fertilizerPlanAvailable,
      data,
      hasError,
      errorMessage,
      isSuccess,
      isCreateSuccess,
      creatingData,
      hasReportError,
      isReportLoading,
      isReportSuccess,
      reportData
    } = fertilizationPlan;
    if(fertilizationPlan.createTaskData && fertilizationPlan.createTaskData !== null && !createTaskConfirm && createTaskPopup) {
      this.setState({
        createTaskSave: false,
        createTaskConfirm: true
      });
    }
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      fertilizationPlanList(activeFarm.farm.id);
    }
    if (!hasError && fertilizerPlanAvailable && data === 1) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: true,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: false
      });
    }
    if (!hasError && fertilizerPlanAvailable && data === 0) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: false
      });
      const formDataObject = { planName: formData.planName, year: formData.year.value, mineralization: mineralCalculationOn };
      createFertilizationPlan(activeFarm.farm.id, '', formDataObject);
    }
    if (!hasError && fertilizerPlanAvailable && data === 2) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: false
      });
      this.getInitialize();
    }
    if (!confirmDialog && hasError && errorMessage?.status === 409) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: true,
        processLoading: false,
        confirmErrorMessage: errorMessage?.data,
        displayErrorFields: false
      });
    }
    if (!displayErrorFields && hasError && errorMessage?.status === 422) {
      fertilizationPlan.fertilizerPlanAvailable = false;
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: false,
        displayErrorFields: true
      });
    }
    if (
      !hasError &&
      isCreateSuccess &&
      !processLoading &&
      creatingData.farmId === null &&
      !keepExisting && 
      timerCount === 0 &&
      data !== "timerError"
    ) {
      this.setState({
        createFertilizer: false,
        createNewFertilizer: false,
        confirmDialog: false,
        processLoading: true,
        displayErrorFields: false,
        timerCount: new Date().getTime()+180000
      });
    }
    if (isSuccess && data !== '' && data !== null && data !== "timerError" && data.farmId != null && !keepExisting) {
      this.setState({
        processLoading: false,
        timerCount: 0
      });
      clearTimeout(this.interval);
    }
    if (hasError && errorMessage?.status === 404 && processLoading) {
      this.setState({
        processLoading: false,
        updateTask: false,
        timerCount: 0
      });
      clearTimeout(this.interval);
      this.getInitialize();
    }
    if (isSuccess && data !== null && data === "timerError" && !timerErrorValue) {
      this.setState({
        processLoading: false,
        timerErrorValue: true,
        updateTask: false
      });
    }
    if (processLoading && !keepExisting && !displayErrorFields) {
      this.interval = setTimeout(() => {
        if(timerCount < new Date().getTime()+5000) {
          getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, "timerErrorMessage");
        } else {
          getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, null);
        }
      }, 5000);
    }
    if(processLoading && timerCount > 0 && timerCount < new Date().getTime()+5000) {
      this.setState({
        processLoading: false,
        updateTask: false
      });
      clearTimeout(this.interval);
      getCreatedFertilizationPlan(activeFarm.farm.id, creatingData.transactionId, "timerErrorMessage"); 
    }
    if (isReportSuccess && !isReportLoading && !hasReportError && reportData != null && generatePdf) {
      generatePdfDocument(reportData,intl);
      this.setState({generatePdf: false});
    }
  }

  createFertilizerPlan() {
    const { userDetails } = this.props;
    this.setState({
      createFertilizer: true,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(START_NEW_PLAN);
  }

  createFertilizerTask = event => {
    const targetValue = event.target.attributes.name.value.split('~~');
    this.setState({
      createTask: true,
      selectedYear: targetValue[2]
    });
  }

  getEmptyFieldFertilization = () => {
    const { fertilizationPlan, intl } = this.props;
    if (fertilizationPlan.errorMessage != null && fertilizationPlan.errorMessage.data?.length > 0) {
      return _.orderBy(fertilizationPlan.errorMessage.data, [fieldData => fieldData.fieldName && fieldData.fieldName.toLowerCase()], ['asc']).map((fieldFertilizationItem,index) => (
          <div
            key={fertilizationPlan.errorMessage.data.fieldId + '_' + index}
            className="padding-bottom-alignment"
          >
            <div className="empty-field-fertilizer">
              <div className="empty-field-grid">
                <div className="empty-field-title">{fieldFertilizationItem.fieldName}</div>
                <div className="empty-field-content">{fieldFertilizationItem.label}</div>
                <div className="empty-field-sub-content">
                  <ul>
                  {fieldFertilizationItem.missingAttributes && fieldFertilizationItem.missingAttributes.map((missingAttribute) => (
                    <li key={missingAttribute}>{missingAttribute}</li>
                  ))}
                  </ul>
                </div>
                {<div className="empty-field-button">
                  <Link to={`/managefields/${fieldFertilizationItem.fieldId}`} target="_blank">
                    <ActionButton secondary>
                      {intl.formatMessage({ id: 'GO_TO_FIELD_AND_ADD' })}
                    </ActionButton>
                  </Link>
                </div>}
              </div>
            </div>
          </div>
        )
      );
    }
  };

  updateFertilizerPlanModal = event => {
    const targetValue = event.target.attributes.name.value.split('~~');
    this.setState({
      updateTask: true,
      updateDataValue: targetValue,
      timerCount: 0,
      mineralCalculationOnUpdate: (targetValue[4] === 'true') ? true : false
    });
  }

  closeFertilizerPlan() {
    this.setState({
      createFertilizer: false,
      createNewFertilizer: false,
      confirmDialog: false,
      mineralCalculationOn: false,
      mineralCalculationOnUpdate: false
    });
    this.getInitialize();
  }

  closeDisplayErrorFields() {
    const { fertilizationPlan } = this.props;
    fertilizationPlan.hasError = false;
    fertilizationPlan.errorMessage = null;
    fertilizationPlan.creatingData = null;
    fertilizationPlan.isCreateSuccess = null;
    this.setState({
      displayErrorFields: false,
      timerCount: 0
    });
    clearTimeout(this.interval);
    this.getInitialize();
  }

  closeUpdateTask() {
    this.setState({
      updateTask: false
    })
  }

  getFertilizerPlanList = () => {
    const { intl, handleSubmit, activeFarm, fertilizationPlan, hasSupportAdminRole } = this.props;
    const {
      createFertilizer,
      createTask,
      updateTask,
      createTaskConfirm,
      createNewFertilizer,
      confirmDialog,
      createValue,
      confirmErrorMessage,
      viewFertilizer,
      mineralCalculationOn,
      mineralCalculationOnUpdate,
      displayErrorFields
    } = this.state;
    const { planList, isListLoading } = fertilizationPlan;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    let returnData = '';
    if (planList !== null && planList.fertilizerPlanMap !== null) {
      returnData = (
        <div>
          <FertilizerPlanList
            planList={planList}
            createFertilizerTask={this.createFertilizerTask}
            editFertilizerPlan={this.editFertilizerPlan}            
            updateFertilizerPlanModal={this.updateFertilizerPlanModal}
            generatePdfFertilizerPlan={this.generatePdfFertilizerPlan}
            viewFertilizer={viewFertilizer}
            intl={intl}
          />
        </div>
      );
    } else {
      returnData = !isListLoading && (
        <SectionContainer className="fertilization-grey-header">
          {hasPermission ? (
            <div className="fertilization-create">
              <div className="fertilization-create-button">
                <ActionButton secondary onClick={this.createFertilizerPlan}>
                  {intl.formatMessage({ id: 'CREATE_FERTILIZATION' })}
                </ActionButton>
              </div>
            </div>
          ) : (
            intl.formatMessage({ id: 'MASTER_ASSET_PERMISSION' })
          )}
        </SectionContainer>
      );
    }
    return (
      <div>
        {returnData}
        <FertilizerModal
          onCloseFertilizerPlan={this.closeFertilizerPlan}
          onCreateFertilizer={createFertilizer}
          onCreateTask={createTask}
          onUpdateTask={updateTask}
          closeUpdateTask={this.closeUpdateTask}
          updateFertilizerPlan={this.updateFertilizerPlan}
          onCreateTaskConfirm={createTaskConfirm}
          onCreateNewFertilizer={createNewFertilizer}
          onConfirmDialog={confirmDialog}
          handleSubmit={handleSubmit(this.handleSubmit)}
          onChangeTextBox={this.onChangeTextBox}
          handleSubmitModel={this.handleSubmitModel}
          keepExistingPlanModel={this.keepExistingPlanModel}
          discardExistingPlanModel={this.discardExistingPlanModel}
          closeCreateTaskModel={this.closeCreateTaskModel}
          createTaskModel={this.createTaskModel}
          onCreateValue={createValue}
          confirmErrorMessage={confirmErrorMessage}
          goToTaskPage={this.goToTaskPage}
          fertilizationPlan={fertilizationPlan}
          intl={intl}
          mineralCalculationOn={mineralCalculationOn}
          toggleMineralCalculationOn={this.toggleMineralCalculationOn}
          mineralCalculationOnUpdate={mineralCalculationOnUpdate}
          toggleMineralCalculationOnUpdate={this.toggleMineralCalculationOnUpdate}
          displayErrorFields={displayErrorFields}
          onCloseDisplayErrorFields={this.closeDisplayErrorFields}
          getEmptyFieldFertilization={this.getEmptyFieldFertilization}
        />
      </div>
    );
  };

  handleSubmit = values => {
    const { activeFarm, intl, checkFertilizationPlan, hasSupportAdminRole } = this.props;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    if (hasPermission) {
      checkFertilizationPlan(activeFarm.farm.id, values.year.value);
      this.setState({ formData: values });
    } else {
      intl.formatMessage({ id: 'MASTER_ASSET_PERMISSION' });
    }
  };

  handleSubmitModel() {
    const { createFertilizationPlan, activeFarm, userDetails } = this.props;
    const { formData, mineralCalculationOn } = this.state;
    const formDataObject = { planName: formData.planName, year: formData.year.value, mineralization: mineralCalculationOn };
    mixpanel.identify(userDetails.id);
    mixpanel.track(CREATE_NEW_PLAN);
    createFertilizationPlan(activeFarm.farm.id, '', formDataObject);
  }

  keepExistingPlanModel() {
    const { createFertilizationPlan, activeFarm, userDetails } = this.props;
    const { formData, mineralCalculationOn } = this.state;
    const formDataObject = { planName: formData.planName, year: formData.year.value, mineralization: mineralCalculationOn };
    this.setState({
      keepExisting: true
    });
    mixpanel.identify(userDetails.id);
    mixpanel.track(CREATE_NEW_PLAN);
    createFertilizationPlan(activeFarm.farm.id, 'keep', formDataObject);
  }

  toggleMineralCalculationOn() {
    const { mineralCalculationOn } = this.state;
    this.setState({
      mineralCalculationOn: !mineralCalculationOn
    });
  }

  toggleMineralCalculationOnUpdate() {
    const { mineralCalculationOnUpdate } = this.state;
    this.setState({
      mineralCalculationOnUpdate: !mineralCalculationOnUpdate
    });
  }

  discardExistingPlanModel() {
    const { createFertilizationPlan, activeFarm } = this.props;
    const { formData, mineralCalculationOn } = this.state;
    const formDataObject = { planName: formData.planName, year: formData.year.value, mineralization: mineralCalculationOn };
    createFertilizationPlan(activeFarm.farm.id, 'delete', formDataObject);
  }

  closeCreateTaskModel() {
    this.setState({
      createTask: false,
      createTaskConfirm: false,
      createTaskPopup: false,
      mineralCalculationOn: false
    });
  }

  goToTaskPage() {
    const { periodOption, sortyByOption } = this.props;
    SetSessionData(FILTER_TASK_STATUS, [{value: 'PLANNED', label: 'Planned'}]);
    SetSessionData(FILTER_TASK_ACTIVITY, [{value: 'act7', label: 'Fertilization'}]);
    sessionStorage.removeItem(FILTER_TASK_FIELD);
    this.setState({
      taskPage: true
    });
    periodOption(null);
    sortyByOption(
      {
        value: 'Date created',
        label: <FormattedMessage id="DATE_CREATED" />
      }
    );
  }

  createTaskModel() {
    const { createTaskFertilizationPlan, activeFarm } = this.props;
    const { selectedYear } = this.state;
    this.setState({
      createTask: false,
      createTaskPopup: true
    });
    createTaskFertilizationPlan(activeFarm.farm.id, selectedYear);
  }

  updateFertilizerPlan = (actual) => {
    const { recalculateFertilizationPlan, activeFarm, userDetails } = this.props;
    const targetValue = this.state.updateDataValue;
    const mineralCalculationOnValue = this.state.mineralCalculationOnUpdate;
    const formDataObject = { "year": targetValue[2], "version": targetValue[3], "mineralization": mineralCalculationOnValue };
    mixpanel.identify(userDetails.id);
    if(actual) {
      mixpanel.track(UPDATE_FP_RECALCULATE);
    } else {
      mixpanel.track(UPDATE_FP_PLANNING);
    }    
    recalculateFertilizationPlan(activeFarm.farm.id, formDataObject, actual);
  };

  editFertilizerPlan = event => {
    const { editFertilizationPlan, activeFarm, userDetails } = this.props;
    const targetValue = event.target.attributes.name.value.split('~~');
    if (targetValue[0] === 'view') {
      mixpanel.identify(userDetails.id);
      mixpanel.track(VIEW_PLAN);
      this.setState({ viewFertilizer: true });
    } else {
      mixpanel.identify(userDetails.id);
      mixpanel.track(EDIT_PLAN);
      this.setState({ viewFertilizer: false });
    }
    editFertilizationPlan(activeFarm.farm.id, targetValue[1], targetValue[2], targetValue[3]);
  };

  generatePdfFertilizerPlan = event => {
    const { fertilizationPlanReport, activeFarm, userDetails } = this.props;
    const targetValue = event.target.attributes.name.value.split('~~');
    this.setState({ generatePdf:true });
    mixpanel.identify(userDetails.id);
    mixpanel.track(EXPORT_PLAN);
    fertilizationPlanReport(activeFarm.farm.id, targetValue[1], targetValue[2], targetValue[3]);
  };

  onChangeTextBox = event => {
    if (event.target.value.toLowerCase() === 'create' || event.target.value.toLowerCase() === 'opprett') {
      this.setState({
        createValue: true
      });
    } else {
      this.setState({
        createValue: false
      });
    }
  };

  render() {
    const { fertilizationPlan, activeFarm, intl, hasSupportAdminRole } = this.props;
    const { viewFertilizer, processLoading, keepExisting, taskPage } = this.state;
    const {
      hasError,
      errorMessage,
      data,
      creatingData,
      isLoading,
      isListLoading,
      isReportLoading,
      isSuccess,
      isCreateSuccess
    } = fertilizationPlan;
    const hasPermission = activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN  || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data;
    return !hasError &&
      isCreateSuccess &&
      processLoading &&
      creatingData !== null &&
      !keepExisting ? (
      <div style={{ position: 'relative', minHeight: '50vh' }}>
        <LoaderDynamic styleName="fixed-loader" loadingText={creatingData.message} />
      </div>
    ) : (
      <div className="manure-storage-list">
        <SectionContainer
          styling={{
            paddingTop: 'low'
          }}
        >
          {hasError ? (
            <div>
              <span>
                <ErrorText>
                  {errorMessage?.status === 500 ? intl.formatMessage({ id: 'UNEXPECTED_ERR_MSG' })
                    : (errorMessage?.status !== 409 && errorMessage?.status !== 422
                    ? errorMessage?.data
                    : '')}
                </ErrorText>
              </span>
            </div>
          ) : null}
          {!processLoading && data === "timerError" ? (
            <div>
              <span>
                <ErrorText>
                  {intl.formatMessage({ id: 'UNEXPECTED_ERR_MSG_FERTILIZATION' })}
                </ErrorText>
              </span>
            </div>
          ) : null}
          {data === 2 ? (
            <ErrorText>{intl.formatMessage({ id: 'FERTILIZATION_ALREADY_AVAILABLE' })}</ErrorText>
          ) : (
            ''
          )}
          {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
          {isListLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
          {isReportLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
          {isSuccess && !processLoading && viewFertilizer && data !== '' && data !== 'timerError' && data !== null && data.farmId !== null && (
            <Redirect to="/fertilization/planner/view" />
          )}
          {taskPage && <Redirect to="/tasks" />}
          {isSuccess && !processLoading && !viewFertilizer && data !== '' && data !== 'timerError' && data !== null && data.farmId !== null && (
            <Redirect to="/fertilization/planner/edit" />
          )}          
          {isCreateSuccess && keepExisting && <Redirect to="/fertilization/planner/edit" />}
          <div className="fertilizerplan-list__Head">
            {hasPermission ? <div className="add-fertilizer" onClick={() => this.createFertilizerPlan()}>
              <span className="pad-right">{intl.formatMessage({ id: 'CREATE_NEW' })}</span>
              <div className="add-icon">
                <AddIcon />
              </div>
            </div> :
            <div className="add-fertilizer">
              <span className="pad-right"></span>
              <div className="add-icon">
              </div>
            </div>}
            <div>
              <Text>
                <div>{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_1' })}</div>
                <div className="padding-top-size">{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_2' })}</div>
                <div>{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_3' })}</div>
                <div className="fertilizer-padding-left">{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_4' })}</div>
                <div className="fertilizer-padding-left">{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_5' })}</div>
                <div className="fertilizer-padding-left">{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_6' })}</div>
                <div>{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_7' })}</div>
                <div>{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_8' })}</div>
                <div>{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_9' })}</div>
                <div>{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_10' })}</div>
                <div className="mineral-fertilizer-text-bold padding-top-size">{intl.formatMessage({ id: 'FERTILIZATION_CONTENT_11' })}</div>
              </Text>
            </div>
          </div>
          <SectionContainer
            styling={{
              paddingLeft: 'none',
              paddingRight: 'none',
              paddingTop: 'high',
              paddingBottom: 'lowest'
            }}
          >
            {this.getFertilizerPlanList()}
          </SectionContainer>
        </SectionContainer>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { intl } = props;
  if (!values.planName || !values.planName.trim() || values.planName == null) {
    errors.planName = intl.formatMessage({ id: 'required' });
  }
  if (!values.year || values.year?.length < 1) {
    errors.year = intl.formatMessage({ id: 'required' });
  }
  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fertilizationPlan: state.fertilizationPlan,
  userDetails: state.userDetails.data,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDispatchToProps(dispatch) {
  return {
    checkFertilizationPlan: (farmId, year) =>
      dispatch(FertilizerAction.checkFertilizationPlanAvailability(farmId, year)),
    createFertilizationPlan: (farmId, deleteDraftPlan, data) =>
      dispatch(FertilizerAction.createFertilizationPlan(farmId, deleteDraftPlan, data)),
    fertilizationPlanList: farmId => dispatch(FertilizerAction.fertilizationPlanList(farmId)),
    editFertilizationPlan: (farmId, planName, year, version) =>
      dispatch(FertilizerAction.editFertilizationPlan(farmId, planName, year, version)),
    recalculateFertilizationPlan: (farmId, data, actual) =>
      dispatch(FertilizerAction.recalculateFertilizationPlan(farmId, data, actual)),
    fertilizationPlanReport: (farmId, planName, year, version) =>
      dispatch(FertilizerAction.fertilizationPlanReport(farmId, planName, year, version)),
    getCreatedFertilizationPlan: (farmId, transactionId, timerValue) =>
      dispatch(FertilizerAction.getCreatedFertilizationPlan(farmId, transactionId, timerValue)),
    createTaskFertilizationPlan: (farmId, year) =>
      dispatch(FertilizerAction.createTaskFertilizationPlan(farmId, year)),
    sortyByOption: option => dispatch(taskListSortByOption(option)),
    periodOption: days => dispatch({ type: TASK_PERIOD_VALUE, taskPeriod: days })
  };
}

export default injectIntl(
  reduxForm({
    form: 'FertilizerPlanner',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FertilizerPlanner)))
);
