import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import MineralStorageListCard from 'mui-components/storages/mineralstorage/MineralStorageListCard';
import * as mineralFertilizerStorageActions from 'actions/MineralFertilizerStorage';
import { buttonRoundStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import { Stack, Box, Chip } from '@mui/material';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import { ADD_MINERAL_FERTILISER } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

class MineralStorageList extends React.Component {
  componentDidMount = () => {
    const { history, activeFarm, getMineralStorage } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    } else {
      getMineralStorage(activeFarm.farm.id);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { history, activeFarm, getMineralStorage, location } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (location !== prevProps.location) {
      getMineralStorage(activeFarm.farm.id);
    }
    if (activeFarm !== prevProps.activeFarm) {
      getMineralStorage(activeFarm.farm.id);
    }
  };

  render() {
    const { intl, activeFarm, mineralFertilizerStorage, userDetails, addNewMineralFertilizer } = this.props;
    const mineralFertilizerStorageLength = mineralFertilizerStorage && mineralFertilizerStorage.mineralStorageList && mineralFertilizerStorage.mineralStorageList.length;
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    return (
      <div style={{ padding: '10px 20px 30px 20px' }}>
        {mineralFertilizerStorage.mineralStorageList && mineralFertilizerStorage.mineralStorageList.length === 0 ?
          <div className="manageFarmsLayout">
            <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
              <div className="manageFarmsLayoutDiv">
                <div><img src="images/NoMineral.png" alt="No Mineral Fertiliser" width="120" /></div>
                <Box style={inputStyle}>
                  <Stack className="text-with-padding">
                    <div style={titleFont}>
                      {intl.formatMessage({ id: 'NO_MINERAL_STORAGE' })}
                    </div>
                  </Stack>
                  <Stack className="text-with-padding">
                    <div style={subTitleFont}>
                      {intl.formatMessage({ id: 'NO_MINERAL_STORAGE_CONTENT' })}
                    </div>
                  </Stack>
                  <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                    <Stack direction="row" justifyContent="center">
                      <Chip
                        icon={<PlusIcon />}
                        label={intl.formatMessage({ id: 'ADD_MINERAL_FERTILIZER' })}
                        color="primary"
                        variant="outlined"
                        clickable
                        onClick={() => {mixpanel.identify(userDetails.id); mixpanel.track(ADD_MINERAL_FERTILISER); addNewMineralFertilizer();}}
                        style={buttonRoundStyle}
                      />
                    </Stack>
                  </div>
                </Box>
              </div>
            </div>
          </div> :
          mineralFertilizerStorage.mineralStorageList?.length >= 1 &&
          mineralFertilizerStorage.mineralStorageList.map((mineralList, index) => (
            <div style={{ paddingBottom: '15px' }}>
              <MineralStorageListCard
                mineralList={mineralList}
                farm={activeFarm}
                key={mineralList.id}
                mineralFertilizerStorageLength={mineralFertilizerStorageLength}
                index={index}
                intl={intl}
              />
            </div>
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  mineralFertilizerStorage: state.mineralFertilizerStorage,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getMineralStorage: (farmId) => dispatch(mineralFertilizerStorageActions.fetchMineralStorageList(farmId)),
  createMineralStorage: (farmId, data) => dispatch(mineralFertilizerStorageActions.createMineralStorage(farmId, data))
});

export default injectIntl(
  reduxForm({
    form: 'MineralStorageList'
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(MineralStorageList)))
);
