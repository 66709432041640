import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import { GetSessionData, GetLocalData  } from 'utils';
import { LOCAL_KEY_USER } from 'constants/index';

import LoginRedirect from 'containers/login/LoginRedirect';
import ForgotPassword from 'containers/login/ForgotPassword';
import ChangePassword from 'containers/login/ChangePassword';
import SignupForm from 'containers/login/SignupForm';
import PageNotFound from 'components/PageNotFound';
import NetworkMessage from 'containers/networkstatus/NetworkStatus';
import { pageview } from 'utils/GoogleAnalytics';
import { VIEW_PRICE_PLAN, WEB } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';
import pkceChallenge from 'pkce-challenge'
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI, OAUTH_AUTH_URL, OAUTH_AUDIENCE_URL } from 'constants/OauthProperties';

const searchParams = new URLSearchParams(document.location.search);
const uuid = uuidv4();
const pkce = pkceChallenge(128);
const code_challenge = pkce.code_challenge;
const code_verifier = pkce.code_verifier;
const listItemText = {
    color: "#191C1C",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.5px"
};
const listPaddingText = { paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: '10px' }
const listPaddingText1 = { paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 }
const language = navigator.language || navigator.userLanguage;
const Login = ({ history, intl, userDetails, location: { state } }) => (
  ((!userDetails || !userDetails.data) && window.location.href.includes('registration')) ? 
  (<div className="login-page-wrapper">
    <div className={`${searchParams.get('code') === null ? 'login-container' : ''}`}>
      {searchParams.get('code') === null &&
      <div className='eanaLogo'>
        <svg xmlns="http://www.w3.org/2000/svg" width="173" height="53" viewBox="0 0 173 53" fill="none">
          <g clip-path="url(#clip0_3216_350)">
            <path d="M160.044 47.8242C157.197 51.0186 153.171 53.0002 148.058 53.0002C138.514 53.0002 129.825 46.6001 129.825 32.9742C129.825 19.6474 138.195 13.2506 147.662 13.2506C152.957 13.2506 158.116 15.4338 160.416 19.4231V13.9285H170.102V42.1947L172.999 52.6995H162.878L161.414 47.4065C161.285 46.8765 161.193 45.9694 161.193 45.9694C160.897 46.6326 160.511 47.2561 160.044 47.8242ZM160.497 32.9742C160.497 24.9941 155.257 21.5315 150.122 21.5315C144.861 21.5315 139.825 25.0688 139.825 32.9742C139.825 40.8048 144.861 44.4169 150.122 44.4169C155.259 44.4169 160.497 40.878 160.497 32.9742Z" fill="#00696D"/>
            <path d="M124.614 28.3085V52.6538H114.77V28.8125C114.77 24.4461 111.308 21.5606 106.465 21.5606C100.591 21.5606 96.8119 25.4003 96.8119 31.7239V52.6521H86.968V24.3859L84.0713 13.8812H94.1928L95.6566 19.1742C95.7852 19.7041 95.9189 20.6161 95.9189 20.6161C95.9189 20.6161 96.3217 19.7513 96.6113 19.2376C99.0744 15.432 103.697 13.2017 109.878 13.2017C119.168 13.2049 124.614 19.0474 124.614 28.3085Z" fill="#00696D"/>
            <path d="M67.8805 47.8242C65.0334 51.0186 61.0071 53.0002 55.8941 53.0002C46.3502 53.0002 37.6616 46.6001 37.6616 32.9742C37.6616 19.6474 46.0314 13.2506 55.4981 13.2506C60.7929 13.2506 65.9522 15.4338 68.2524 19.4231V13.9285H77.9386V42.1947L80.8354 52.6995H70.7138L69.25 47.4065C69.1215 46.8765 69.0289 45.9694 69.0289 45.9694C68.7331 46.6326 68.347 47.2561 67.8805 47.8242ZM68.333 32.9742C68.333 24.9941 63.0931 21.5315 57.9578 21.5315C52.6974 21.5315 47.6614 25.0688 47.6614 32.9742C47.6614 40.8048 52.6974 44.4169 57.9578 44.4169C63.0948 44.4169 68.333 40.878 68.333 32.9742Z" fill="#00696D"/>
            <path d="M0.00134277 52.6974V-0.000244141H33.5249L36.0566 9.18449H10.3183V21.4563H28.5062V30.641H10.3183V43.5143H34.2637L36.7953 52.699H0.00134277V52.6974Z" fill="#00696D"/>
          </g>
          <defs>
            <clipPath id="clip0_3216_350">
              <rect width="173" height="53" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>}
      <div className={`${searchParams.get('code') === null ? 'logoLeftContent' : 'logoLeftContentNoColor'}`}>
        {userDetails.data && (
          window.history.pushState(null, '', window.location.origin+'/#/stats'),
          <>
            <Redirect to={state && state.from ? state.from.pathname : '/stats'} />
            {pageview(state && state.from ? state.from.pathname : '/stats')}
          </>
        )}
        <Switch>
          <Route exact path="/forgotpassword/changepassword" component={ChangePassword} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/registration" component={SignupForm} />
          <Route exact path="/" component={LoginRedirect} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
      {searchParams.get('code') === null &&
      <div className="footer-login-page-register"><FormattedMessage id="FOOTER_CONTENT_SIGN_IN" /></div>}
    </div>
    {searchParams.get('code') === null &&
    <div style={{ width: '100%', padding: '8px', background: '#CCE8E9' }}>
      <div className="logoRightContent">
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText1'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_1' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_1_NB' })}
          </div>
          <div className='firstParagraphText3'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_2' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_2_NB' })}
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_3' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_3_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_4' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_4_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_5' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_5_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_6' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_6_NB' })}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_7' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_7_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_8' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_8_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_9' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_9_NB' })}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_10' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_10_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_11' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_11_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_12' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_12_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_13' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_13_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_14' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_14_NB' })}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_15' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_15_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText1}>
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_16' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_16_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText1}>
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_17' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_17_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText1}>
                <div style={listItemText}>{language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_18' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_18_NB' })}&nbsp;
                <span className="priceListLink" onClick={() => {mixpanel.track(VIEW_PRICE_PLAN, {'Platform':WEB}); window.open('https://eana.no/produkter-og-priser', '_blank');}}>
                {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_19' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_19_NB' })}</span></div>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </div>}
    <NetworkMessage />
  </div>) :     
  ((!userDetails && !userDetails.data) && OAUTH_REDIRECT_URI+"/#/" !== window.location.href && searchParams.get('code') === null && !GetSessionData(LOCAL_KEY_USER) && !GetLocalData(LOCAL_KEY_USER)) ? (sessionStorage.setItem("codeVerifier", code_verifier), window.location.replace(`${OAUTH_AUTH_URL}/oauth2/auth?response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${OAUTH_REDIRECT_URI}/auth&scope=openid+offline&code_challenge_method=S256&audience=${OAUTH_AUDIENCE_URL}&state=${uuid}&code_challenge=${code_challenge}`)) :
  (<div className="login-page-wrapper">
    <div className={`${searchParams.get('code') === null ? 'login-container' : ''}`}>
      {searchParams.get('code') === null &&
      <div className='eanaLogo'>
        <svg xmlns="http://www.w3.org/2000/svg" width="173" height="53" viewBox="0 0 173 53" fill="none">
          <g clip-path="url(#clip0_3216_350)">
            <path d="M160.044 47.8242C157.197 51.0186 153.171 53.0002 148.058 53.0002C138.514 53.0002 129.825 46.6001 129.825 32.9742C129.825 19.6474 138.195 13.2506 147.662 13.2506C152.957 13.2506 158.116 15.4338 160.416 19.4231V13.9285H170.102V42.1947L172.999 52.6995H162.878L161.414 47.4065C161.285 46.8765 161.193 45.9694 161.193 45.9694C160.897 46.6326 160.511 47.2561 160.044 47.8242ZM160.497 32.9742C160.497 24.9941 155.257 21.5315 150.122 21.5315C144.861 21.5315 139.825 25.0688 139.825 32.9742C139.825 40.8048 144.861 44.4169 150.122 44.4169C155.259 44.4169 160.497 40.878 160.497 32.9742Z" fill="#00696D"/>
            <path d="M124.614 28.3085V52.6538H114.77V28.8125C114.77 24.4461 111.308 21.5606 106.465 21.5606C100.591 21.5606 96.8119 25.4003 96.8119 31.7239V52.6521H86.968V24.3859L84.0713 13.8812H94.1928L95.6566 19.1742C95.7852 19.7041 95.9189 20.6161 95.9189 20.6161C95.9189 20.6161 96.3217 19.7513 96.6113 19.2376C99.0744 15.432 103.697 13.2017 109.878 13.2017C119.168 13.2049 124.614 19.0474 124.614 28.3085Z" fill="#00696D"/>
            <path d="M67.8805 47.8242C65.0334 51.0186 61.0071 53.0002 55.8941 53.0002C46.3502 53.0002 37.6616 46.6001 37.6616 32.9742C37.6616 19.6474 46.0314 13.2506 55.4981 13.2506C60.7929 13.2506 65.9522 15.4338 68.2524 19.4231V13.9285H77.9386V42.1947L80.8354 52.6995H70.7138L69.25 47.4065C69.1215 46.8765 69.0289 45.9694 69.0289 45.9694C68.7331 46.6326 68.347 47.2561 67.8805 47.8242ZM68.333 32.9742C68.333 24.9941 63.0931 21.5315 57.9578 21.5315C52.6974 21.5315 47.6614 25.0688 47.6614 32.9742C47.6614 40.8048 52.6974 44.4169 57.9578 44.4169C63.0948 44.4169 68.333 40.878 68.333 32.9742Z" fill="#00696D"/>
            <path d="M0.00134277 52.6974V-0.000244141H33.5249L36.0566 9.18449H10.3183V21.4563H28.5062V30.641H10.3183V43.5143H34.2637L36.7953 52.699H0.00134277V52.6974Z" fill="#00696D"/>
          </g>
          <defs>
            <clipPath id="clip0_3216_350">
              <rect width="173" height="53" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>}
      <div className={`${searchParams.get('code') === null ? 'logoLeftContent' : 'logoLeftContentNoColor'}`}>
        {userDetails.data && (
          window.history.pushState(null, '', window.location.origin+'/#/stats'),
          <>
            <Redirect to={state && state.from ? state.from.pathname : '/stats'} />
            {pageview(state && state.from ? state.from.pathname : '/stats')}
          </>
        )}          
        <Switch>
          <Route exact path="/forgotpassword/changepassword" component={ChangePassword} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/registration" component={SignupForm} />
          <Route exact path="/" component={LoginRedirect} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
      {searchParams.get('code') === null &&
      <div className="footer-login-page"><FormattedMessage id="FOOTER_CONTENT_SIGN_IN" /></div>}
    </div>
    {searchParams.get('code') === null &&
    <div style={{ width: '100%', padding: '8px', background: '#CCE8E9' }}>
      <div className="logoRightContent">
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText1'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_1' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_1_NB' })}
          </div>
          <div className='firstParagraphText3'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_2' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_2_NB' })}
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_3' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_3_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_4' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_4_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_5' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_5_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_6' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_6_NB' })}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_7' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_7_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_8' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_8_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_9' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_9_NB' })}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_10' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_10_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_11' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_11_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_12' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_12_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_13' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_13_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText}>
                <Box component="span" className="boxDot" />
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_14' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_14_NB' })}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
        <div className='logoRightContentFirstParagraph'>
          <div className='firstParagraphText2'>
            {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_15' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_15_NB' })}
          </div>
          <div className='firstParagraphText3'>
            <List>
              <ListItem style={listPaddingText1}>
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_16' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_16_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText1}>
                <div style={listItemText}>
                  {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_17' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_17_NB' })}
                </div>
              </ListItem>
              <ListItem style={listPaddingText1}>
                <div style={listItemText}>{language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_18' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_18_NB' })}&nbsp;
                <span className="priceListLink" onClick={() => {mixpanel.track(VIEW_PRICE_PLAN, {'Platform':WEB}); window.open('https://eana.no/produkter-og-priser', '_blank');}} href="#">
                {language === 'en-US' ? intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_19' }) : intl.formatMessage({ id: 'LOGIN_PAGE_TEXT_19_NB' })}</span></div>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </div>}
    <NetworkMessage />
  </div>)
);
const mapStateToProps = state => ({
  userDetails: state.userDetails
});
export default injectIntl(withRouter(connect(mapStateToProps)(Login)));
