import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetDayTimeDiff, GetLocalData, DeleteLocalData, GetSessionData, SetSessionData  } from 'utils';
import { getNewLoginToken, loginSuccess } from 'actions/Login';
import { LOCAL_KEY_USER, IS_OFFLINE, IS_ONLINE, REFRESH_TOKEN } from 'constants/index';
import { LOGIN_EXPIRE_DAYS} from 'constants/Environment';
import Loader from 'components/Loader';
import AppRoutes from 'components/routes';
import GAPageView from 'components/GAPageView';
import { GAConfig } from 'utils/GoogleAnalytics';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from 'constants/MixPanel';
import "@fontsource/inter";

class EannaApp extends Component {
  componentDidMount = prevProps => {
    const { history, logInSuccess, userDetails } = this.props;
    // get user data from local or session
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    if (!user || (userDetails && userDetails.logoutSuccess)) {
      history.push('/');
    } else {
      const loggedInDays = GetDayTimeDiff(new Date(user.loginDate), new Date());
      if (loggedInDays.days > LOGIN_EXPIRE_DAYS) {
        history.push('/');
      } else {
        logInSuccess(user);
        //this.getRefreshToken(networkStatus);
      }
    }
    // google analytics config
    GAConfig();
    mixpanel.init(MIXPANEL_ID);

    window.addEventListener('online', this.networkStatusOnline);
    window.addEventListener('offline', this.networkStatusOffline);
  };

  componentDidUpdate = prevProps => {
    const { logInSuccess, userDetails } = this.props;
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    if (userDetails.data || user) {
      if(userDetails.data && userDetails.data.sessionTimeOut) {
        if((new Date().toISOString() > userDetails.data.sessionTimeOut && GetSessionData(REFRESH_TOKEN) === false) || 
        (new Date().toISOString() > userDetails.data.sessionTimeOut && GetSessionData(REFRESH_TOKEN) === null)) {
          SetSessionData(REFRESH_TOKEN, true);
          this.getRefreshToken(true);
        }
      }
      if (
        prevProps.userDetails.data &&
        prevProps.userDetails.data.accessToken !== userDetails.data.accessToken
      ) {
        logInSuccess(user);
      }
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.callSessionToken);
    window.removeEventListener('online', this.networkStatusOnline);
    window.removeEventListener('offline', this.networkStatusOffline);
  };

  // to check network status
  networkStatusCheck = () => {
    const { checkNetworkStatus } = this.props;
    const netowrkStatus = typeof navigator.onLine === 'boolean' ? navigator.onLine : true;
    if (netowrkStatus) {
      checkNetworkStatus(IS_ONLINE);
    } else {
      checkNetworkStatus(IS_OFFLINE);
    }
    return netowrkStatus;
  };

  // offline call
  networkStatusOffline = () => {
    const { checkNetworkStatus } = this.props;
    checkNetworkStatus(IS_OFFLINE);
  };

  networkStatusOnline = () => {
    const { checkNetworkStatus } = this.props;
    checkNetworkStatus(IS_ONLINE);
    this.getRefreshToken(true);
  };

  // to get new login token
  getRefreshToken = hasNetworkStatus => {
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    const { history, fetchNewLoginToken, networkStatus } = this.props;
    const status = hasNetworkStatus || networkStatus.isOnline;
    if (status) {
      if (user) {
        fetchNewLoginToken(user);
      } else {
        DeleteLocalData(LOCAL_KEY_USER);
        history.push('/');
      }
    }
  };

  render() {
   const { userDetails } = this.props;
    if (userDetails.data && userDetails.data.sessionTimeOut <= new Date().toISOString()) {
      return (
        <div className="on-loading">
          <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="login-loader" /></div>
        </div>
      );
    }
    return (
      <>
        <AppRoutes />
        <GAPageView />
      </>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  networkStatus: state.networkStatus
});

const mapDispatchToProps = dispatch => ({
  fetchNewLoginToken: (data, callback) => dispatch(getNewLoginToken(data, callback)),
  logInSuccess: data => dispatch(loginSuccess(data)),
  checkNetworkStatus: type => dispatch({ type })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EannaApp));