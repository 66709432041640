import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SoilSample from 'mui-components/fields/soilsamplesimport/SoilSamples';
import PageNotFound from 'components/PageNotFound';
import SampleDetails from 'mui-components/fields/soilsamplesimport/SampleDetails';

const SoilSamples = () => (
  <Switch>
    <Route exact path="/soilsamplesimport/:date/:year" component={SampleDetails} />
    <Route exact path="/soilsamplesimport" component={SoilSample} />
    <Route component={PageNotFound} />
  </Switch>
);

export default SoilSamples;
