/**
 * Edit Field index component has tab module to switch between the field and weather
*/
import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import * as fieldActions from 'actions/Fields';
import Loader from 'components/Loader';
import Header from 'mui-components/header';
import MuiFieldWeather from 'mui-components/fields/fieldtab/weather';
import MuiFieldHistorical from 'mui-components/fields/fieldtab/historical';
import * as fieldChartActions from 'actions/FieldChart';
import { GAEvent } from 'utils/GoogleAnalytics';
import { GA_FIELD_HELP_TEXT_CLK, GA_CAT_CLICK } from 'constants/GoogleAnalytics';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import Help from 'containers/help/Help';
import CreateFieldHelp from 'containers/help/CreateField';
import { buttonFilledStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';

import { MANAGEFIELDS, FIELD_WEATHER, NEW, FIELD_SOIL_SAMPLES, FIELD_HISTORICAL, FIELD_MANURE, ADMIN, OWNER, ADVISOR } from 'constants/index';

import General from 'mui-components/fields/fieldtab/general';
import SoilSamples from 'mui-components/fields/fieldtab/soilsamples';
import ManureStorage from 'mui-components/fields/fieldtab/manurestorage';
import { Stack, Box, Tabs, Tab, Typography, Chip, Grid, Menu, MenuItem, Dialog, DialogTitle } from '@mui/material'
import { createSvgIcon } from '@mui/material/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { ADD_SOIL_SAMPLE } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index===2 ? 'customTabDiv' : ''}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);
 
class EditFieldIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      selected: null,
      selectedField: '',
      tabIndex: 0,
      updatedFields: false,
      value: 0,
      anchorEl: false,
      showDeletePrompt: false,
      showDeletePromptError: false,
      showHelp: false,
    };
    this.addNewSoilSample = this.addNewSoilSample.bind(this);
  }
 
  componentDidMount = () => {
    const { fieldDetails, location, fetchFieldList, activeFarm, history,fetchWeatherData } = this.props;
    if (!activeFarm) {
      history.push('/settings/');
    }
    if (!fieldDetails && activeFarm) {
      fetchFieldList(activeFarm.farm.id);
    }
    fetchWeatherData(activeFarm.farm.id);
 
    if (location.pathname.includes(FIELD_HISTORICAL)) {
      this.setState({
        selected: FIELD_HISTORICAL,
        value: 4
      });
    } else if (location.pathname.includes(FIELD_WEATHER)) {
      this.setState({
        selected: FIELD_WEATHER,
        value: 3
      });
    } else if (location.pathname.includes(FIELD_MANURE)) {
      this.setState({
        selected: FIELD_MANURE,
        value: 2
      });
    } else if (location.pathname.includes(FIELD_SOIL_SAMPLES)) {
      this.setState({
        selected: FIELD_SOIL_SAMPLES,
        value: 1
      });
    } else {
      this.setState({
        selected: MANAGEFIELDS,
        value: 0
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { fieldDetails, fieldDetailsState, activeFarm, location, history, match, fieldChart } = this.props;
    const { showDeletePromptError } = this.state;
    if(fieldChart.isLoading && fieldChart.weatherData !== null) {
      fieldChart.weatherData = null;
    }
    const { updatedFields } = this.state;
    if (prevProps.activeFarm !== activeFarm) {
      history.push('/managefields');
    }
    if (!updatedFields && fieldDetails && fieldDetails !== 'undefined') {
      this.intializeValue(match.params.id);
    }
	  if (location !== prevProps.location) {
      this.intializeValue(match.params.id);
    }
    if(!showDeletePromptError && fieldDetailsState.hasDeleteError) {
      this.setState({
        showDeletePromptError: true
      });
    }
    if(!showDeletePromptError && fieldDetailsState.isSuccess) {
      //history.push('/managefields');
    }
  }

  // Intialize compoenent values by passing url param Id
  intializeValue = paramId => {
    const { fieldDetails, history } = this.props;
    this.setState({ paramId, updatedFields: true });
    if (paramId !== NEW) {
      const currentField = _.filter(fieldDetails, ['fieldId', paramId]);
      if (currentField.length > 0) {
        this.setState({
          selectedField: currentField[0]
        });
      } else {
        history.push('/managefields');
      }
    } else {
      const newField = { fieldCrop: '', fieldName: '', fieldSize: '', manureApplied: false, manurePlans: [], gpsPoints: [], note: '' };
      this.setState({
        selectedField: newField
      });
    }
  };
 
  // on tab change passing page title to the header
  onTabChange = (index) => {
    
  };
 
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history, match } = this.props;  
    if(newValue === 4) {
      this.setState({ selected: FIELD_HISTORICAL, tabIndex: newValue, value: newValue });
      history.push(`/managefields/${match.params.id}/historical`);
    } else if(newValue === 3) {
      this.setState({ selected: FIELD_WEATHER, tabIndex: newValue, value: newValue });
      history.push(`/managefields/${match.params.id}/weather`);
    } else if(newValue === 2) {
      this.setState({ selected: FIELD_MANURE, tabIndex: newValue, value: newValue });
      history.push(`/managefields/${match.params.id}/manure`);
    } else if(newValue === 1) {
      this.setState({ selected: FIELD_SOIL_SAMPLES, tabIndex: newValue, value: newValue });
      history.push(`/managefields/${match.params.id}/soilsamples`);
    } else {
      this.setState({ selected: MANAGEFIELDS, tabIndex: newValue, value: newValue });
      history.push(`/managefields/${match.params.id}`);
    }
  };

  addNewField = () => {
    const { history } = this.props;
    history.push("/managefields/new");
  };

  addNewSoilSample = () => {
    const { history, match } = this.props;
    history.push(`/managefields/${match.params.id}/soilsamples/new`);
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  handleDelete = (event, fieldId) => {
    event.preventDefault();
    const { updateState } = this.props;
    this.setState({ anchorEl: false });
    updateState(true, fieldId);
  };

  // To show delete confirmation box
  showDialogDeleteField = () => {
    const { activeFarm, hasSupportAdminRole } = this.props;
    if (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data) {
      this.setState({ anchorEl: false });
      document.addEventListener('click', this.handleOutsideClick);
      this.setState({ showDeletePrompt: true });
    }
    return false;
  };

  resetInformation = (event) => {
    const { paramId } = this.state;
    this.handleClose(event);
    window.location.href = `/#/managefields/${paramId}/manure`;
    window.location.reload();
  };

  importSoilStorage = (event) => {
    this.handleClose(event);
    window.location.replace(`/#/soilsamplesimport`);
  }

  // To show help modal window
  showHelpToggle = () => {
    const { showHelp } = this.state;
    if (!showHelp) {
      GAEvent(GA_FIELD_HELP_TEXT_CLK, GA_CAT_CLICK, GA_CAT_CLICK);
    }
    this.setState({ anchorEl: false });
    document.addEventListener('click', this.handleOutsideClick);
    this.setState(prevState => ({
      showHelp: !prevState.showHelp
    }));
  };

  deleteFieldOnConfirm(action) {
    const { deleteField, activeFarm } = this.props;
    const { selectedField } = this.state;
    if (action) {
      deleteField(activeFarm.farm.id, selectedField.fieldId);
    }
    this.setState(prevState => ({ showDeletePrompt: !prevState.showDeletePrompt }));
  }

  deleteFieldErrorMessage() {
    const { fieldDetailsState } = this.props;
    fieldDetailsState.hasDeleteError = false;
    this.setState(prevState => ({ showDeletePromptError: !prevState.showDeletePromptError }));
  }

  showingAddMenu0 = () => {
    const { paramId, selected } = this.state;
    const { intl, activeFarm, hasSupportAdminRole } = this.props;
    const disableCreateFieldEditButton =
      paramId !== NEW && (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data);
    if (!selected) {
      return null;
    }
    const enableDelete = (paramId !== NEW && (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data));
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    };
    return (<Stack direction="row" justifyContent={`end`} sx={{ paddingTop: '0px', paddingLeft: '0px' }}>
      {disableCreateFieldEditButton ? 
      (<Chip
        icon={<PlusIcon />}
        label={intl.formatMessage({ id: 'FV_AF' })}
        color="primary"
        variant="outlined"
        clickable
        onClick={() => this.addNewField()}
        style={buttonStyle}
      />) :
      (<div style={buttonStyle}>&nbsp;</div>)}
      <Box component='span' className='grid-padding-dot'>
        <MoreVertIcon onClick={this.handleClick} sx={{ cursor: 'pointer' }} />
        <Menu
          id="basic-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {enableDelete &&
          <MenuItem onClick={this.showDialogDeleteField} style={menuItemStyle}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
            }}
          >
            {intl.formatMessage({ id: 'AEF_DF' })}
          </MenuItem>}
          <MenuItem onClick={this.showHelpToggle} style={menuItemStyle}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
            }}
          >
            {intl.formatMessage({ id: 'FIELD_INSTRUCTIONS' })}
          </MenuItem>
        </Menu>
      </Box>
    </Stack>)
  }

  showingAddMenu1 = () => {
    const { paramId, selected } = this.state;
    const { intl, activeFarm, hasSupportAdminRole, userDetails } = this.props;
    const disableCreateFieldEditButton =
      paramId !== NEW && (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data);
    if (!selected) {
      return null;
    }
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    };
    return (<Stack direction="row" justifyContent={`end`} sx={{ paddingTop: '0px', paddingLeft: '0px', paddingRight: '30px' }}>
      {disableCreateFieldEditButton ? 
      (<Chip
        icon={<PlusIcon />}
        label={intl.formatMessage({ id: 'ADD_FIELD_SOIL_SAMPLES' })}
        color="primary"
        variant="outlined"
        clickable
        onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ADD_SOIL_SAMPLE); this.addNewSoilSample();}} 
        style={buttonStyle}
      />) :
      (<div style={buttonStyle}>&nbsp;</div>)}
      {/*<Box component='span' className='grid-padding-dot'>
        <MoreVertIcon onClick={this.handleClick} sx={{ cursor: 'pointer' }} />
        <Menu
          id="basic-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={e => this.importSoilStorage(e)} style={menuItemStyle}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
            }}
          >
            {intl.formatMessage({ id: 'IMPORT_SOIL_SAMPLES' })}
          </MenuItem>
        </Menu>
      </Box>*/}
    </Stack>)
  }

  showingAddMenu2 = () => {
    const { selected } = this.state;
    const { intl } = this.props;
    if (!selected) {
      return null;
    }
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    };
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    return (<Stack direction="row" justifyContent={`end`} sx={{ paddingTop: '0px', paddingLeft: '0px' }}>
      <div style={buttonStyle}>&nbsp;</div>
      <Box component='span' className='grid-padding-dot'>
        <MoreVertIcon onClick={this.handleClick} sx={{ cursor: 'pointer' }} />
        <Menu
          id="basic-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={e => this.resetInformation(e)} style={menuItemStyle}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
            }}
          >
            {intl.formatMessage({ id: 'RESET_INFORMATION' })}
          </MenuItem>
        </Menu>
      </Box>
    </Stack>)
  }

  render() {
    const { paramId, selectedField, selected, value } = this.state;
    const { intl, fieldDetails, fieldDetailsState } = this.props;
    if (!selected) {
      return null;
    }
    const tabStyle = { 
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      color: '#00696D',
      textTransform: 'none',
      padding: '0px 30px'
    };
    const tabStyleDisabled = { 
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      textColor: '#e1e1e1',
      textTransform: 'none',
      padding: '0px 30px'
    };
    const dialogTitleDialogStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "16px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "center", color: "#191C1C" };
    return (
      <div className={`${'main-content'}`}>
        {(selectedField?.fieldName || paramId === NEW) && (
        <Header
          bcLink={{ to: '/managefields', text: intl.formatMessage({ id: 'HEADER_FDS' }) }}
          bcTitle={
            paramId === NEW ? intl.formatMessage({ id: 'FV_NF' }) : selectedField.displayName
          }
        />
      )}
      {!fieldDetails ? (
          <Loader styleName="field-loader" />
        ) : (
        <div className="settings-tabs">
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1, maxWidth: { xs: 320, sm: '100%' }, paddingTop: '0px' }}>
              <Grid container justifyContent="flex-end">
                <Grid item md={10} xs={12} sx={{ paddingTop: '0px' }}>
                  <Tabs variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    value={value} onChange={this.handleChange}>
                    <Tab style={tabStyle} onClick={()=>this.onTabChange(0)} label={intl.formatMessage({ id: 'FIELD_TAB_GENERAL' })} {...a11yProps(0)} />
                    <Tab style={paramId === NEW ? tabStyleDisabled : tabStyle} disabled={paramId === NEW} onClick={()=>this.onTabChange(1)} 
                      label={intl.formatMessage({ id: 'SOIL_SAMPLES' })} {...a11yProps(1)} 
                      sx={{ wordSpacing: "15%", ...(paramId === NEW && { color: '#bbbbbb !important' }) }}
                    />
                    <Tab style={paramId === NEW ? tabStyleDisabled : tabStyle} disabled={paramId === NEW} onClick={()=>this.onTabChange(2)} 
                      label={intl.formatMessage({ id: 'MANURE_DETAILS_TAB' })} {...a11yProps(2)} 
                      sx={{ wordSpacing: "15%", ...(paramId === NEW && { color: '#bbbbbb !important' }) }}
                    />
                    <Tab style={paramId === NEW ? tabStyleDisabled : tabStyle} disabled={paramId === NEW } onClick={()=>this.onTabChange(3)} 
                      label={intl.formatMessage({ id: 'FIELD_TAB_WEATHER' })} {...a11yProps(3)} 
                      sx={{ wordSpacing: "15%", ...(paramId === NEW && { color: '#bbbbbb !important' }) }}
                    />
                    <Tab style={paramId === NEW ? tabStyleDisabled : tabStyle} disabled={paramId === NEW} onClick={()=>this.onTabChange(4)} 
                      label={intl.formatMessage({ id: 'FIELD_HISTORICAL_TAB' })} {...a11yProps(4)} 
                      sx={{ wordSpacing: "15%", ...(paramId === NEW && { color: '#bbbbbb !important' }) }}
                    />
                  </Tabs>
                </Grid>
                {value === 0 && 
                (<Grid item md={2} className='grid-padding-top'>
                  {this.showingAddMenu0()}
                </Grid>)}
                {value === 1 &&  
                (<Grid item md={2} className='grid-padding-top'>
                  {this.showingAddMenu1()}
                </Grid>)}
                {value === 2 && 
                (<Grid item md={2} className='grid-padding-top'>
                  {this.showingAddMenu2()}
                </Grid>)}
              </Grid>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <General />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <SoilSamples addNewSoilSample={this.addNewSoilSample} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} sx={{ paddingTop: '0' }}>
              <ManureStorage selectedField={selectedField} paramId={paramId} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <MuiFieldWeather />
              {/*<FieldWeather />*/}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <MuiFieldHistorical />
            </CustomTabPanel>
          </Box>
        </div>
        )}
        {this.state.showDeletePrompt && (
          <ConfirmDialogBox
            heading={`${intl.formatMessage({ id: 'AEF_DF' })}?`}
            body={intl.formatMessage({ id: 'DIALOG_BODY_FIELD' })}
            confirmText="DELETE"
            cancelText="CANCEL"
            callback={action => this.deleteFieldOnConfirm(action)}
            warning
          />
        )}
        <Dialog onClose={() => {}} open={this.state.showDeletePromptError} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "397px",
            minWidth: "280px",
            maxWidth: "560px",
          }}}>
          <DialogTitle style={dialogTitleDialogStyle}>
            <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br/>
            {fieldDetailsState.errorMessage?.data}
          </DialogTitle>
          <div style={{padding: "15px 15px 25px 15px", textAlign: "center"}}>
            <span>
              <Chip
                label={intl.formatMessage({ id: 'OK' })}
                clickable
                style={buttonFilledStyle}
                sx={{ backgroundColor: 'transparent',
                  '&:hover, &:focus': {
                    backgroundColor: 'transparent',
                  }
                }}
                disableRipple
                onClick={() => this.deleteFieldErrorMessage()}
              />
            </span>
          </div>
        </Dialog>
        <Help
          showHelp={this.state.showHelp}
          showHelpClose={this.showHelpToggle}
          FeedbackBtnTxt={intl.formatMessage({ id: 'GIVE_FB' })}
        >
          <CreateFieldHelp />
        </Help>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  fieldDetails: state.fieldDetails.data,
  activeFarm: state.farmList.activeFarm,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'manureStoragePage'])[0],
  fieldChart: state.fieldChart,
  hasSupportAdminRole: state.SupportAdminRole,
  rolePermissions: state.RolesDetails,
  fieldDetailsState: state.fieldDetails,
  userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
  return {
    fetchWeatherData: (farmId) => dispatch(fieldChartActions.fieldTemperatureSum(farmId, null)),
    fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
    deleteField: (farmId, fieldId) => dispatch(fieldActions.deleteField(farmId, fieldId))
  };
}

export default reduxForm({
  form: 'EditFieldIndexForm'
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditFieldIndex)));
 