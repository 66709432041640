import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import IconLink from '@tine/lib-frontend-components/components/buttons/icon-link';
import { AddIcon } from '@tine/lib-frontend-components/elements/icons';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import ExpandingContainer from '@tine/lib-frontend-components/components/accordion/expanding-container';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import { buttonRoundStyle } from 'mui-mobile-components/fields/fertilization/services/fertilizationStyles';
import ManureListHeader from 'mui-mobile-components/storages/manurestorage/ManureListHeader';
import ManureListContent from 'mui-mobile-components/storages/manurestorage/ManureListContent';
import Loader from 'components/Loader';
import * as ManureAction from 'actions/ManureStorage';
import { OWNER, ADMIN, ADVISOR } from 'constants/index';
import { Stack, Box, Chip } from '@mui/material';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import { ADD_MANURE } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';

class ManureStorageList extends React.Component {
  constructor(props) {
    super(props);
    this.getTypeValue = this.getTypeValue.bind(this);
  }

  componentDidMount = () => {
    const { fetchManureList, activeFarm, fetchManureType } = this.props;
    if (activeFarm) {
      fetchManureList(activeFarm.farm.id);
      fetchManureType();
    }
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, fetchManureList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      fetchManureList(activeFarm.farm.id);
    }
  }

  getType = manureValue => {
    const {
      manureStorage: { manureType }
    } = this.props;
    if (manureValue.type) {
      const typeName = _.filter(manureType, ['id', manureValue.type])[0];
      return typeName.type;
    }
    return '';
  };

  getTypeValue = (typeValue) => {
    if(typeValue === "GJØDSELKUM") {
      return "Gjødselkum";
    } else if(typeValue === "GJØDSELKJELLER") {
      return "Gjødselkjeller";
    } else if(typeValue === "TALLE") {
      return "Talle";
    } else {
      return "";
    }
  };

  getStorageList = () => {
    const {
      manureStorage: { manureList },
      history,
      intl,
      pageContent: { labels },
      activeFarm,
      hasSupportAdminRole,
      userDetails
    } = this.props;
    const hasPermission = activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    if (manureList?.length > 0) {
      return manureList.map((manureItem, index) => (
        <ExpandingContainer
          key={manureItem.id}
          collapsedContent={
            <ManureListHeader labels={labels} values={manureItem} hasPermission={hasPermission} />
          }
          expanded={index === 0}
          expandedContent={
            <ManureListContent
              typeName={this.getType(manureItem)}
              labels={labels}
              values={manureItem}
              intl={intl}
              getTypeValue={this.getTypeValue}
            />
          }
          withTimeline={false}
        />
      ));
    }
    return (
      <SectionContainer
        styling={{
          paddingTop: 'none',
          paddingBottom: 'low'
        }}
      >
        {hasPermission ? (
          <div className="manageFarmsLayout">
            <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
              <div className="manageFarmsLayoutDiv">
                <div><img src="images/NoManure.png" alt="No Manure" width="120" /></div>
                <Box style={inputStyle}>
                  <Stack className="text-with-padding">
                    <div style={titleFont}>
                      {intl.formatMessage({ id: 'MANURE' })}
                    </div>
                  </Stack>
                  <Stack className="text-with-padding">
                    <div style={subTitleFont}>
                      {intl.formatMessage({ id: 'NO_MANURE_CONTENT' })}
                    </div>
                  </Stack>
                  <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                    <Stack direction="row" justifyContent="center">
                      <Chip
                        icon={<PlusIcon />}
                        label={intl.formatMessage({ id: 'CREATE_MANURE' })}
                        color="primary"
                        variant="outlined"
                        clickable
                        onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ADD_MANURE); history.push('/storage/manure-storage/create');}}
                        style={buttonRoundStyle}
                      />
                    </Stack>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        ) : (
          intl.formatMessage({ id: 'MASTER_ASSET_PERMISSION' })
        )}
      </SectionContainer>
    );
  };

  render() {
    const {
      manureStorage,
      pageContent: { labels }
    } = this.props;
    const { isLoading, isListLoading, manureList, hasListError, errorMsg } = manureStorage;
    const loading = isLoading || isListLoading;
    return (
      <div className="manure-storage-list">
        {hasListError && <ErrorText>{errorMsg.data}</ErrorText>}
        {!loading && (
          <SectionContainer
            styling={{
              paddingTop: 'low'
            }}
          >
            {manureList?.length > 0 && (
              <div className="manure-list__Head">
                <IconLink route="/storage/manure-storage/create">
                  <span className="btn-icon__text">{labels.addNew}</span>
                  <AddIcon scale={0.75} />
                </IconLink>
                <p>
                  <Text>{labels.textMessage}</Text>
                </p>
              </div>
            )}
            <SectionContainer
              styling={{
                paddingLeft: 'none',
                paddingRight: 'none',
                paddingTop: 'low',
                paddingBottom: 'lowest'
              }}
            >
              {!isLoading && !hasListError && this.getStorageList()}
            </SectionContainer>
          </SectionContainer>
        )}
        {loading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  manureStorage: state.manureStorage,
  activeFarm: state.farmList.activeFarm,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'manureStoragePage'])[0],
  hasSupportAdminRole: state.SupportAdminRole,
  userDetails: state.userDetails
});

const mapDispatchToProps = dispatch => ({
  fetchManureType: () => dispatch(ManureAction.fetchManureType()),
  fetchManureList: farmId => dispatch(ManureAction.fetchManureList(farmId))
});

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ManureStorageList))
);
