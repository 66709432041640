import * as React from 'react';
import { Link } from 'react-router-dom';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import Loader from 'components/Loader';

export default ({ isLoading, data, hasError, intl }) => (
  <div className="stats-header">
    <SectionContainer
      styling={{
        backgroundColor: 'header',
        mobileOffset: true,
        paddingTop: 'lowest',
        paddingBottom: 'lowest',
        paddingLeft: 'lowest',
        paddingRight: 'lowest'
      }}
    >
      {data && (
        <div className="items-container">
          <Link to="/managefields" className="item">
            <i className="icon-field" />
            <span>
              {intl.formatMessage({ id: 'FIELDS' })} <Emphasis>{data.noOfFields}</Emphasis>
            </span>
          </Link>
          <Link to="/settings/vehicle" className="item">
            <i className="icon-vehicle" />
            <span>
              {intl.formatMessage({ id: 'VEHICLES' })} <Emphasis>{data.noOfVehicles}</Emphasis>
            </span>
          </Link>
          <Link to="/settings/equipment" className="item">
            <i className="icon-equipment" />
            <span>
              {intl.formatMessage({ id: 'EQUIPMENT' })}{' '}
              <Emphasis>{data.noOfEquipment}</Emphasis>
            </span>
          </Link>
          <Link to="/settings/users" className="item">
            <i className="icon-profile" />
            <span>
              {intl.formatMessage({ id: 'USERS' })} <Emphasis>{data.noOfUsers}</Emphasis>
            </span>
          </Link>
        </div>
      )}
      {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
    </SectionContainer>
  </div>
);
